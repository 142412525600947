var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.SAlert,{directives:[{name:"expose",rawName:"v-expose",value:({
    id: 'expose_tax_hint:simple',
    data: { mall_code: _setup.mallCode, location: 'page' }
  }),expression:"{\n    id: 'expose_tax_hint:simple',\n    data: { mall_code: mallCode, location: 'page' }\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: 'click_tax_hint:simple',
    data: { mall_code: _setup.mallCode, location: 'page' }
  }),expression:"{\n    id: 'click_tax_hint:simple',\n    data: { mall_code: mallCode, location: 'page' }\n  }"}],class:_setup.prefixCls('br50-dollars_tax'),attrs:{"type":"info","jumpable":_setup.isAble,"max-rows":1000},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.handleClick.apply(null, arguments)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }