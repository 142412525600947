<template>
  <s-label
    type="success"
    class="single-product-voucher__label-tag"
    :style="{
      color: tag?.textColor || 'rgb(25, 128, 85)',
      backgroundColor: tag?.bgColor || 'rgb(236, 252, 243)',
      ...themVarStyle.labelStyle
    }"
  >
    <!-- 前半段 -->
    <span
      v-tap="{
        id: 'click_voucher_tag:simple',
        data: {
          goods_id: tag.goodsId
        }
      }"
      class="quick-ship-tag__title"
      @click="handleShowPopover"
    >
      <Icon
        v-if="useIcon"
        name="sui_icon_qucikship_flat_24px"
        :size="themVarStyle.IconSize"
        :color="themVarStyle.IconColor"
        class="single-product-voucher__label-tag_icon"
      />
      <img
        v-if="!useIcon && !!tag?.prefixIcon"
        class="single-product-voucher__label-tag_img"
        :src="tag?.prefixIcon"
        alt=""
      />
      <span
        class="quick-ship-tag__text_new"
        :style="themVarStyle.themTextStyle"
        v-html="tag.text"
      >
      </span>

      <SPopover
        v-model="showPopover"
        show-close-icon
        :append-to-body="false"
        trigger-type="user"
        :prop-style="{
          maxWidth: '240px',
          width: 'max-content',
          fontWeight: 400
        }"
        @close="handleClose"
      >
        <div class="voucher-popover-content">
          {{ tag.popoverTips }}
        </div>
        <img
          v-if="!useIcon && !!tag?.suffixIcon"
          slot="reference"
          class="single-product-voucher__label-tag_img"
          :src="tag?.suffixIcon"
          alt=""
          @click="handleSuffixIcon(tag)"
        />
      </SPopover>
    </span>
    <!-- 后半段 -->
    <template v-if="tag.text_des">
      <span
        :style="themVarStyle.dividerStyle"
        class="divider"
      ></span>
      <span>{{ tag.text_des }}</span>
    </template>
  </s-label>
</template>

<script setup>
import { ref } from 'vue'

import { Icon } from '@shein-aidc/icon-vue2'
import { Label as SLabel, Popover as SPopover } from '@shein/sui-mobile'

import { computed } from 'vue'
const showPopover = ref(false)

const props = defineProps({
  tag: {
    type: Object,
    default: () => ({})
  },
  useIcon: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['emitSuffixIcon'])

const themVarStyle = computed(() => {
  return {
    IconSize: props.tag?.IconSize || '12px',
    IconColor: props.tag?.IconColor || 'rgb(25, 128, 85)',
    themTextStyle: props.tag?.themTextStyle || {},
    labelStyle: props.tag?.labelStyle || {},
    dividerStyle: props.tag?.dividerStyle || {}
  }
})

const handleSuffixIcon = data => {
  emit('emitSuffixIcon', data)
}

const handleShowPopover = () => {
  showPopover.value = !showPopover.value
}
const handleClose = () => {
  showPopover.value = false
}
</script>

<style lang="less">
.single-product-voucher__label-tag {
  padding: 0.054rem 0.1067rem;
  border-radius: 2px;
  display: inline-flex !important;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  .S-popover {
    display: inline-flex;
  }
  .voucher-popover-content {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    white-space: normal;
  }
  &_img {
    width: 12px;
    height: 12px;
  }
  .quick-ship-tag__title {
    display: flex;
    align-items: center;
  }
  .quick-ship-tag__text_new {
    margin-left: 4/75rem;
  }
  .divider {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 8/75rem;
    width: 2/75rem;
    height: 18/75rem;
    background: rgba(25, 128, 85, 0.5);
  }
}
</style>
