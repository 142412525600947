import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

/**
 * @description 头部轮播标题
 * @param {mallIndex} 当前 mall 索引
 * @returns {swiperTitleConfig} 轮播标题
 * */
export default function useHeaderTitle(mallIndex) {
  const { cartsInfo } = useMapState(['cartsInfo'])

  const swiperTitleConfig = computed(() => {
    return (
      cartsInfo?.value?.good_by_mall?.[mallIndex]?.shoppingBagScrollBarTips || []
    ).map(item => {
      return {
        text: {
          label: item?.displayText || '',
          color: item?.textColor || ''
        },
        icon: {
          name: '',
          color: ''
        },
        img: {
          url: item?.imgIconUrl || '',
          style: { width: '12px' }
        }
      }
    })
  })

  return { swiperTitleConfig }
}
