var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: 'expose_goods_list:simple',
    data: {
      activity_from: 'goods_list',
      style: 'detail',
      goods_list: _setup.formatGoodsList(_vm.index, _vm.data)
    }
  }),expression:"{\n    id: 'expose_goods_list:simple',\n    data: {\n      activity_from: 'goods_list',\n      style: 'detail',\n      goods_list: formatGoodsList(index, data)\n    }\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: 'click_goods_list:simple',
    data: {
      activity_from: 'goods_list',
      style: 'detail',
      goods_list: _setup.formatGoodsList(_vm.index, _vm.data)
    }
  }),expression:"{\n    id: 'click_goods_list:simple',\n    data: {\n      activity_from: 'goods_list',\n      style: 'detail',\n      goods_list: formatGoodsList(index, data)\n    }\n  }"}],class:_setup.prefixCls('single-product_wrapper'),attrs:{"id":_setup.orderSectionId(_vm.data)}},[_c('div',{class:_setup.prefixCls('sp-section')},[_c(_setup.MainImages,{attrs:{"data":_vm.data,"is-in-drawer":_vm.isInDrawer}}),_vm._v(" "),_c(_setup.ProductInfo,{attrs:{"data":_vm.data,"quantity-editable":_vm.quantityEditable,"is-in-drawer":_vm.isInDrawer}})],1),_vm._v(" "),_c(_setup.BagsFooter,{attrs:{"data":_vm.data}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }