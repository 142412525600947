<template>
  <div
    :class="prefixCls('normal-shipping__wrapper')"
    @click="handleLayoutArea"
  >
    <!-- 单商品购物袋内容区域 -->
    <SingleProduct
      v-if="
        shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.isRenderSingleProduct
      "
      :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal?.[0]"
    />

    <!-- 多商品购物袋内容区域 -->
    <MultiProduct
      v-else
      :class="prefixCls('ns__multi-product')"
      :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal"
    />
  </div>
</template>

<script setup name="NormalShipping">
import { inject, provide } from 'vue'

import SingleProduct from '../single_product/Index.vue'
import MultiProduct from '../multi_product/Index.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

// 外页
provide('isOutsidePage', true)
const mallIndex = inject('mallIndex')
const emit = defineEmits(['emiClickLayoutArea'])

// --------- useMap_**** --------
const { shoppingBagsState } = useMapState(['shoppingBagsState'])

// --------- computed ---------

// ---------- method ----------
const handleLayoutArea = event => {
  emit('emiClickLayoutArea', { event })
}
</script>
<style lang="less">
@import '../../variables.less';

.@{prefixCls}normal-shipping__wrapper {
  .@{prefixCls}ns__multi-product {
    padding-top: 0.16rem;
  }
}
</style>
