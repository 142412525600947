var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.insuranceInfo && +_vm.insuranceInfo.displayInsurance === 1 && _vm.insuranceInfo.insurancePrice.hint)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: 'expose_shipping_insurance:simple',
    data: {
      select_type: +_setup.isChecked,
      is_forced: +_vm.insuranceInfo.is_force_tick,
      insurance_type: _vm.insuranceInfo?.insurancePrice.insurance_type,
      mall_code: _vm.mallCode,
      default_value: _vm.insuranceInfo?.insurancePrice.isDefault
    }
  }),expression:"{\n    id: 'expose_shipping_insurance:simple',\n    data: {\n      select_type: +isChecked,\n      is_forced: +insuranceInfo.is_force_tick,\n      insurance_type: insuranceInfo?.insurancePrice.insurance_type,\n      mall_code: mallCode,\n      default_value: insuranceInfo?.insurancePrice.isDefault\n    }\n  }"}],class:[_setup.prefixCls('insurance__wrapper')]},[_c('div',[_c('span',{staticClass:"shipping-title"},[_vm._v("\n      "+_vm._s(_vm.insuranceInfo.insurancePrice.hint)+"\n    ")]),_vm._v(" "),(_vm.insuranceInfo.insurancePrice?.amount_rich_text)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.insuranceInfo.insurancePrice.amount_rich_text)}}):_vm._e(),_vm._v(" "),_c(_setup.Icon,{staticStyle:{"line-height":"inherit"},attrs:{"name":"sui_icon_doubt_16px_2"},on:{"click":_setup.onInsuranceTip}})],1),_vm._v(" "),_c(_setup.SCheckboxSwitch,{attrs:{"value":_setup.isChecked,"tabindex":"0","aria-label":(_vm.insuranceInfo?.insurancePrice?.hint || '') + _vm.insuranceInfo.insurancePrice?.amountWithSymbol,"disabled":!!_vm.insuranceInfo.isForceCheck,"role":"menuitemradio"},on:{"update:value":function($event){_setup.isChecked=$event},"change":_setup.switchInsurance}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }