<script setup>
import { ref } from 'vue'
import { Alert as SAlert } from '@shein/sui-mobile'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/support_info/utils'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'

defineProps({
  tradeSafeInfoBOList: {
    type: Array,
    default: () => []
  },
  isShow2: {
    type: Boolean,
    default: true
  },
  language: {
    type: Object,
    default: () => ({})
  },
  cssRight: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['initCombinePolicyTip'])

const visibleMore = ref(false)

const handleVisibleMore = () => {
  visibleMore.value = true
}

</script>

<template>
  <div>
    <div
      v-for="item in tradeSafeInfoBOList"
      :key="item.type"
      v-expose="{
        id: 'expose_payment_security:simple',
      }"
      :class="prefixCls('module-item')"
    >
      <div class="support-info-container">
        <div class="support-info-title">
          <img
            :src="item.logoImage"
            class="img-box"
            alt=""
          />
          {{ item.title }}
        </div>

        <div class="support-info-desc">
          <template v-if="item.tip">
            {{ item.tip }}
          </template>
          <template v-else-if="item.tipList && isShow2">
            <p
              v-for="tipItem in item.tipList"
              :key="tipItem"
              class="new-support-info-desc"
            >
              <Icon
                name="sui_icon_selected_16px"
                size="16px"
                color="#198055"
                class="selectIcon"
              />
              {{ tipItem }}
            </p>
          </template>
          <template v-else-if="item.tipList && !isShow2">
            <template v-if="item.type === 'PAYMENT_SECURITY'">
              {{ template('SHEIN', language.SHEIN_KEY_PWA_23519) }}
            </template>
            <template v-else>
              <p
                v-if="visibleMore"
                class="support-info-desc fn-test"
              >
                {{ template('SHEIN', language.SHEIN_KEY_PWA_23521) }}
              </p>
              <s-alert
                v-else
                class="desc-alert-more fn-test"
                :description="template('SHEIN', language.SHEIN_KEY_PWA_23521)"
                :max-rows="2"
                :load-more-txt="language.SHEIN_KEY_PWA_23524"
                @on-load-more-click="handleVisibleMore"
              />
            </template>


            <div
              v-if="item.logoList && item.logoList.length"
              class="payment-logo-list"
            >
              <img
                v-for="img in item.logoList"
                :key="img.sort"
                :src="img.url"
                class="logo-item"
                alt=""
              />
            </div>

            <div
              v-if="item.type === 'SECURITY_PRIVACY'"
              class="learn-more"
              style="margin-left: 0"
              @click="emits('initCombinePolicyTip')"
            >
              {{ language.SHEIN_KEY_PWA_30729 }}
              <Icon
                :name="!cssRight ? 'sui_icon_more_right_16px_2' : 'sui_icon_more_left_16px_2'"
                size="16px"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
