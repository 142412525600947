var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: 'expose_shipping_add:simple',
    data: _setup.saAddItemObj
  }),expression:"{\n    id: 'expose_shipping_add:simple',\n    data: saAddItemObj\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: 'click_shipping_add:simple',
    data: _setup.saAddItemObj
  }),expression:"{\n    id: 'click_shipping_add:simple',\n    data: saAddItemObj\n  }"}],class:_setup.prefixCls('add-item'),on:{"click":function($event){$event.stopPropagation();return _setup.handleAddFree.apply(null, arguments)}}},[_c('div',{class:_setup.prefixCls('add-item__desc'),domProps:{"innerHTML":_vm._s(_setup.hints)}}),_vm._v(" "),_c('span',{class:_setup.prefixCls('add-item__btn')},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_29778)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }