var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prime-card-new",class:{
    'prime-card-new__in': _vm.isZoomIn,
    'prime-card-new__out': _vm.isZoomOut,
    'prime-card-new_selected': _vm.isSelected,
    'prime-card-new_auto-renewal': _vm.isAutoRenewal
  },style:({
    ..._vm.styleConfig[_vm.currentStyleType],
    backgroundImage: _vm.cardBackground && `url(${_vm.cardBackground})`
  })},[(!_vm.showNewStyle && !_vm.customStyle.cardBgImg)?_c('img',{staticClass:"prime-card-new__logo",attrs:{"src":"https://img.ltwebstatic.com/images3_ccc/2024/06/17/b7/1718605574332abf833f03cac69b92207b58e9b2d5.webp"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"prime-card-new__header"},[_c('div',{ref:"headerTitleRef",staticClass:"header-title",class:{ 'header-title_auto-renewal': _vm.isAutoRenewal }},[_c('div',{staticClass:"header-title__left"},[_c('div',{staticClass:"header-title__left-bg",style:({ transform: `scaleX(${_vm.GB_cssRight ? -1 : 1})` })}),_vm._v(" "),_c('div',{staticClass:"header-title__info-text",domProps:{"innerHTML":_vm._s(_vm.productName)}})]),_vm._v(" "),_c('div',{staticClass:"header-title__right-triangle",style:({
          transform: `scaleX(${_vm.GB_cssRight ? -1 : 1})`,
          clipPath: `path('M${13.571 * _vm.scale} 0L${5.41131 * _vm.scale} ${
            14.9992 * _vm.scale
          }C${4.33925 * _vm.scale} ${16.8561 * _vm.scale} ${2.358 * _vm.scale} ${
            18 * _vm.scale
          } ${0.213867 * _vm.scale} ${18 * _vm.scale}V0H${14.071 * _vm.scale}Z')`
        })})]),_vm._v(" "),(_vm.item.recent_saving_tip)?_c('div',{ref:"headerOmitAmountRef",staticClass:"header-omit-amount"},[_c('div',{ref:"headerOmitAmountTextRef",staticClass:"header-omit-amount__text"},[_vm._v("\n        "+_vm._s(_vm.item.recent_saving_tip)+"\n      ")])]):_vm._e()]),_vm._v(" "),(_vm.item.right_config_list.length > 2)?_c('div',{staticClass:"prime-card-new__least-three-content",class:{
      'prime-card-new__least-three-content_activity': _vm.activityList.length
    }},[_c('div',{ref:"mutipleLeftRightsRef",staticClass:"left-rights"},[_c('div',{staticClass:"left-rights__icon"},[_c('Icon',{attrs:{"name":_vm.getRightIcon(_vm.item.right_config_list[0]),"size":"22px","color":_vm.customStyle.rightIconColor ||
              _vm.styleConfig[_vm.currentStyleType]['--benefitColor']}}),_vm._v(" "),(_vm.item.right_config_list[0].topRightTip)?[_c('div',{staticClass:"left-rights__text"},[_vm._v("\n            "+_vm._s(_vm.item.right_config_list[0].topRightTip)+"\n          ")])]:(_vm.showThumb)?[(_vm.customStyle.rightStrongIconImg)?_c('img',{staticClass:"left-rights__praise right-icon__img-prasie",attrs:{"src":_vm.customStyle.rightStrongIconImg}}):_c('div',{staticClass:"left-rights__praise"},[_c('Icon',{attrs:{"name":"sui_icon_like_completed_16px","size":"8px","color":"#fff"}})],1)]:_vm._e()],2),_vm._v(" "),_c('div',{ref:"mutipleLeftRightsTextRef",staticClass:"left-rights__desc"},[_vm._v("\n        "+_vm._s(_vm.item.right_config_list[0].right_type_name)+"\n      ")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"right-rights"},_vm._l((_vm.item.right_config_list.slice(1)),function(right,index){return _c('div',{key:right.right_type_id,staticClass:"single-rights-wrapper",class:{
          'single-rights-wrapper_small':
            _vm.item.right_config_list.slice(1).length > 2
        }},[_c('Icon',{attrs:{"name":_vm.getRightIcon(right),"size":"11px","color":_vm.customStyle.rightIconColor ||
              _vm.styleConfig[_vm.currentStyleType]['--benefitColor']}}),_vm._v(" "),_c('div',{ref:`viceRights_${index}_Ref`,refInFor:true,staticClass:"single-rights-wrapper__desc",class:{
            'single-rights-wrapper__desc_magnify': _vm.activityList.length,
            'single-rights-wrapper__desc_magnify': _vm.isZoomIn,
            'single-rights-wrapper__desc_lessen': _vm.isZoomOut
          }},[_vm._v("\n          "+_vm._s(right.right_type_name)+"\n        ")]),_vm._v(" "),(right.topRightTip)?[_c('div',{staticClass:"right-rights__text"},[_vm._v("\n            "+_vm._s(right.topRightTip)+"\n          ")])]:_vm._e()],2)}),0)]):_c('div',{staticClass:"prime-card-new__most-two-content",class:{
      'prime-card-new__most-two-content_activity': _vm.activityList.length
    }},[_vm._l((_vm.item.right_config_list),function(right,rightIndex){return [_c('div',{key:right.right_type_id,staticClass:"single-rights-wrapper"},[_c('div',{staticClass:"single-rights-wrapper__icon"},[_c('Icon',{attrs:{"name":_vm.getRightIcon(right),"size":"22px","color":_vm.customStyle.rightIconColor ||
                _vm.styleConfig[_vm.currentStyleType]['--benefitColor']}}),_vm._v(" "),(right.topRightTip)?[_c('div',{staticClass:"single-rights-wrapper__text"},[_vm._v("\n              "+_vm._s(right.topRightTip)+"\n            ")])]:(rightIndex === 0 && _vm.showThumb)?[(_vm.customStyle.rightStrongIconImg)?_c('img',{staticClass:"single-rights-wrapper__praise right-icon__img-prasie",attrs:{"src":_vm.customStyle.rightStrongIconImg}}):_c('div',{staticClass:"single-rights-wrapper__praise"},[_c('Icon',{attrs:{"name":"sui_icon_like_completed_16px","size":"8px","color":"#fff"}})],1)]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"single-rights-wrapper__desc"},[_vm._v("\n          "+_vm._s(right.right_type_name)+"\n        ")])]),_vm._v(" "),_c('div',{key:rightIndex,staticClass:"rights-divider"})]})],2),_vm._v(" "),(_vm.activityList.length)?_c('div',{staticClass:"prime-card-new__footer swiper",style:({
      backgroundImage:
        _vm.customStyle.sellingPointBgImg &&
        `url(${_vm.customStyle.sellingPointBgImg})`
    })},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.activityList),function(activity){return _c('div',{key:activity.text,staticClass:"swiper-slide"},[_c('div',{staticClass:"footer-left"},[(activity.type === 'gift')?_c('Icon',{staticClass:"footer-left__icon",attrs:{"name":"sui_icon_unlimitedgifts_24px","size":".32rem","color":"var(--club-selling-point-color, white)"}}):[(_vm.customStyle.sellingPointColor)?_c('GiftIcon',{staticClass:"footer-left__icon"}):_c('img',{staticClass:"footer-left__icon",attrs:{"src":_vm.activityIconImg}})],_vm._v(" "),_c('div',{staticClass:"footer-left__tips"},[_vm._v("\n            "+_vm._s(activity.text)+"\n          ")])],2),_vm._v(" "),(activity.time.length)?_c('div',{staticClass:"footer-right"},_vm._l((activity.time),function(text,index){return _c('div',{key:index,staticClass:"footer-right__count-item"},[_vm._v("\n            "+_vm._s(text)+"\n          ")])}),0):_vm._e()])}),0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle-wrapper"},[_c('div',{staticClass:"middle-wrapper__part-line"})])
}]

export { render, staticRenderFns }