/**
 * 获取免邮凑单弹窗的配置参数
 * @param {String} mallCode - mallCode 参数，默认为 platform
 * @param {Object} freeMallApiData  - 免邮接口返回的数据
 * @param {Object} cartApiData - 购物车接口返回的数据
 * @returns {Object}
 */
export function getOpts4FreeShipping({ mallCode = 'platform', mallShippingInfo = {}, cartsApiData = {} } = {}) {
  if (!(mallCode && mallShippingInfo && cartsApiData)) return console.error('[getOpts4FreeShipping]: 请检查参数 mallCode、freeMallApiData cartsApiData 是否传入')

  // 当前免邮凑单活动
  const freeShippingInfo = mallShippingInfo[mallCode] ?? {}

  const getFreeShippingProgressRate = (shippingInfoAvailable = {}) => {
    const { diff_price = {}, origin_price = {} } = shippingInfoAvailable
    if (!diff_price.amount || !origin_price.amount) return 0
    if (diff_price.amount <= 0) return 100
    return 100 - (diff_price.amount / origin_price.amount) * 100
  }

  const getGoodsIdMap = () => cartsApiData?.carts?.map((item) => item.product.goods_id) ?? []
  const getCatIdMap = () => cartsApiData?.carts?.map((item) => item.product.cat_id) ?? []
  const getAddOnType = () => {
    if(freeShippingInfo.isFirstFreeShipping) return 3
    return freeShippingInfo.activity_type > 0 ? [ '', 0, 1, 4 ][freeShippingInfo.activity_type] : ''
  }

  const promotion = {
    promotion_id: freeShippingInfo.firstFreeShipping?.firstFreeShippingTip ? 998 : 999,
    addOnType: 'freeShipping',
    tip: freeShippingInfo.isFirstFreeShipping ? freeShippingInfo.firstFreeShipping?.firstFreeShippingTip : freeShippingInfo.shippingTip,
    showCountDown: freeShippingInfo.firstFreeShipping?.showCountDown, // 是否展示倒计时，目前只有首单免邮有倒计时
    countDownTimeStamp: freeShippingInfo.firstFreeShipping?.endTimestamp, // 倒计时结束时间,
    progressRate: getFreeShippingProgressRate(freeShippingInfo.shippingInfoAvailable || {}), // 进度条进度
    isShowProgress: freeShippingInfo.shippingInfoAvailable?.diff_price && freeShippingInfo.shippingInfoAvailable?.origin_price, // 是否展示进度条，目前运费活动返回的字段无法算出进度条进度，所不展示
    activityId: freeShippingInfo.shippingInfoAvailable?.activity_id || 0, // 活动id，运费活动没有该字段
    activityType: freeShippingInfo.shippingInfoAvailable?.activity_type || 0, // 活动类型，运费活动是3 免邮活动是1
    addOnGoodsInfoList: freeShippingInfo.shippingInfoAvailable?.add_on_goods_info_list || [], // 商品池
    isAmountType: !freeShippingInfo.shippingInfoAvailable?.charging_type || freeShippingInfo.shippingInfoAvailable?.charging_type == 3, // 计价方式是否是金额类型，运费活动可能不是金额类型
    diffPrice: freeShippingInfo.shippingInfoAvailable?.diff_price || {}, // 差额
    shippingMethodName: freeShippingInfo.shippingInfoAvailable?.shippingMethodName || freeShippingInfo.shippingInfoAvailable?.shipping_method_name || '', // 运费活动名称
    nowLevel: freeShippingInfo.shippingInfoAvailable?.now_level || 0, // 当前档位
    mall_code: mallCode === 'platform' ? '' : mallCode, // mall_code
  }

  return {
    promotion,
    type: promotion.addOnType,
    queryInfo: {
      sceneId: 152,
      goodsIds: getGoodsIdMap(),
      cateIds: getCatIdMap(),
      quickship_prior: 1,
      mallCode: promotion?.mall_code,
      addOnType: getAddOnType(),
    },
    saInfo: {
      activity_from: 'freeShipping',
      state: 'freeShipping',
    },
    config: {
      isCloseBusinessCart: false,
      isCloseIncentivePoint: false,
      titleUseLangKey: '',
      continueBtnUseLangKey: '',
    }
  }
}

/**
 * 获取结算页免邮凑单弹窗的配置参数
 * @param {String} mallCode - mallCode 参数，默认为 platform
 * @param {Object} freeMallApiData  - 免邮接口返回的数据
 * @param {Array} cartApiData - 购物车接口返回的数据
 * @param {Boolean} isQuickship - 运输方式返回了 quickship 提示文案
 * @param {String} transportType - 当前凑单的活动运输方式
 * @param {Boolean} isBuyNow - 是否立即购场景
 * @param {String} casualCheckoutNo - 立即购订单号
 * @returns {Object}
 */
export function getOpts4FreeShippingFromCheckout({ mallCode = 'platform', mallShippingInfo = {}, cartsApiData = {}, isQuickship = false, transportType = '', isBuyNow = false, casualCheckoutNo = '' } = {}) {
  const props = getOpts4FreeShipping({ mallCode, mallShippingInfo, cartsApiData })
  props.promotion.showCountDown = false
  props.queryInfo.quickship_prior = isQuickship ? 1 : 0
  props.saInfo.activity_from = 'checkout_shipping_add'
  props.saInfo.state = 'checkout_shipping_add'
  props.config.isCloseIncentivePoint = true
  props.config.isCloseBusinessCart = true
  props.config.continueBtnToClose = true
  props.config.continueBtnUseLangKey = 'SHEIN_KEY_PWA_30444'
  props.config.titleUseLangKey = 'SHEIN_KEY_PWA_27759'
  props.config.isBuyNow = isBuyNow
  props.config.casualCheckoutNo = casualCheckoutNo

  const currMallActivityInfo = mallShippingInfo[mallCode] ?? {}
  const currMallShippingInfo = mallShippingInfo?.mall_transport_free_list?.find(item => item.mall_code === mallCode)?.transport_free_list?.find(item => item.transport_type === transportType)
  // 活动暂停
  props.promotion.isActivityStop = (currMallActivityInfo.transportType !== transportType && currMallShippingInfo?.is_free_shipping === 1) || (currMallActivityInfo.transportType === transportType && currMallActivityInfo.isFreeShipping)
  // 活动结束
  props.promotion.isActivityEnd = currMallActivityInfo.transportType !== transportType && currMallShippingInfo?.is_free_shipping === 0

  return {
    props
  }
}


