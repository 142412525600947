import { COUNTRY_OR_AREA_CODE } from '@/public/src/pages/checkout_new/utils/constant.js'
/**
 * @description: 设置地址名称
 * @param detail: defaultAddress
 * @return String
 */
export const getAddressName = (detail) => {
  const addressBookName = {
    [COUNTRY_OR_AREA_CODE.TW]: ['lname', 'fname'],
    [COUNTRY_OR_AREA_CODE.JP]: ['lname', 'fname'],
    default: ['fname', 'middleName', 'lname', 'fatherName'],
  }
  const { countryId, shipping_country_id, subsidiary } = detail
  const currentId = countryId || shipping_country_id
  const order = addressBookName[currentId] || addressBookName.default

  return order.map(item => detail[item] || (subsidiary && subsidiary[item])).filter(Boolean).join(' ')
}
