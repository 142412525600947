<template>
  <div :class="prefixCls('company-tax')">
    <div
      v-for="item in tax"
      :key="`${item?.local_name}`"
      :class="prefixCls('company-tax-item')"
    >
      <div :class="prefixCls('company-tax-item_txt')">
        {{ item.local_name }}
        <Icon
          v-if="!!item.tip || !!item.des"
          name="sui_icon_doubt_16px_2"
          size="16px"
          color="#959595"
          @click.prevent.stop="handleChangeTipsVisible(true, item.tip || item.des)"
        />
      </div>
      <div :class="prefixCls('company-tax-item_price')">
        {{ item?.price_with_symbol || '' }}
      </div>
    </div>
    <ClientOnly>
      <!-- totalFee Dialog -->
      <s-dialog
        :visible.sync="tipsVisible"
        :show-close="false"
        append-to-body
      >
        {{ tipsContent }}
        <template slot="footer">
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click.prevent="handleChangeTipsVisible(false, '')"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
import ClientOnly from 'vue-client-only'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'

defineProps({
  tax: {
    type: Array,
    default: () => []
  }
})

const tipsVisible = ref(false)
const tipsContent = ref('')

// -------- useMap_**** --------
const { language } = useMapState(['language'])

// ---------- computed ----------

// ---------- method ----------
const handleChangeTipsVisible = (visible, txt) => {
  tipsVisible.value = visible
  tipsContent.value = txt
}
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}company-tax {
  &-item {
    display: flex;
    align-items: center;
    margin-top: 0.10666667rem;
    line-height: 17/14;
    .font-dpr(20px);
    align-items: center;
    color: #222;

    .sui-icon-common__wrap {
      margin-left: 0.10666667rem;
    }

    &_txt {
      display: flex;
    }

    &_price {
      margin-left: 8/75rem;
      line-height: 1;
    }
  }
}
</style>
