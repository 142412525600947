import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

/**
 * @description (3)巴西50美金税费弹窗数据
 * return {jumpAble} 是否允许弹窗
 * return {renderData} 弹窗渲染数据
 * */
export default function useBr50DollarsTax(mallIndex) {
  // --------- useMap_**** --------
  const { shoppingBagsState, checkout } = useMapState([
    'shoppingBagsState',
    'checkout'
  ])

  const filterData = computed(() => {
    // mallIndex存在，则逻辑在购物袋内，否则在全局；全局则需要所有的数据，即all
    const all = shoppingBagsState?.value?.bagsInfo?.all || []
    const mallAll =
      shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex]?.all || []
    const list = mallIndex !== undefined ? mallAll : all
    // 筛选去掉赠品，附属品，目的是：展示纯购买商品
    return list?.filter(item => {
      const { isGift, isAdditionGood } = useAttribute(item)
      return !isGift.value && !isAdditionGood.value
    })
  })

  const mallCode =
    shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex]?.mall_code
  const goodsIdList =
    checkout.value?.mallHintInfo?.find(item => item.mallCode === mallCode)
      ?.goodsIdList || []

  const renderData = filterData.value?.filter(item => {
    return goodsIdList.includes(item.id)
  })

  return { isAble: renderData?.length > 1, renderData }
}
