var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-user-gifts",style:(_setup.moduleStyle)},[_c('div',{staticClass:"new-user-gifts__rule",on:{"click":function($event){_setup.ruleDrawerVisible = true}}},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_29981)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"new-user-gifts__top"},[_c('div',{ref:"activityTitleRef",staticClass:"new-user-gifts__title",class:{
        'new-user-gifts__title_ellipsis': _setup.ellipsisVisible
      }},[_vm._v("\n      "+_vm._s(_vm.xtraAggregatedCouponList.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"new-user-gifts__desc"},[_c(_setup.SAdapterText,{attrs:{"min-size":"10","lines":"2","text":_vm.xtraAggregatedCouponList.desc}})],1)]),_vm._v(" "),_c('div',{staticClass:"new-user-gifts__coupons"},_vm._l((_setup.newUserCoupons),function(module,index){return _c('swiper-container',{key:index,staticClass:"new-user-gifts__list",class:`new-user-gifts__list_${module.type}`},_vm._l((module.couponList),function(coupon){return _c('swiper-slide',{key:coupon.couponCode,staticClass:"new-user-gifts__item",class:{
          'new-user-gifts__item_small': module.type === _setup.NewUserCouponType.Single &&
            module.couponList.length > 1
        }},[_c(_setup.NewUserCoupon,{attrs:{"coupon":coupon,"language":_vm.language,"isSmall":module.type === _setup.NewUserCouponType.Single &&
              module.couponList.length > 1,"infoIconVisible":""},on:{"showDetailsDrawer":function($event){return _setup.showDetailsDrawer({
            ...module,
            coupon
          })}}})],1)}),1)}),1),_vm._v(" "),_c(_setup.NewUserRuleDrawer,{attrs:{"visible":_setup.ruleDrawerVisible,"rule":_vm.xtraAggregatedCouponList.ruleDesc,"language":_vm.language},on:{"update:visible":function($event){_setup.ruleDrawerVisible=$event}}}),_vm._v(" "),_c(_setup.NewUserDetailsDrawer,_vm._b({attrs:{"visible":_setup.detailsDrawer.visible,"attributeName":_vm.xtraAggregatedCouponList.title,"language":_vm.language},on:{"update:visible":function($event){return _vm.$set(_setup.detailsDrawer, "visible", $event)}}},'NewUserDetailsDrawer',_setup.detailsDrawer,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }