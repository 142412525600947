<script setup>
import { Drawer as SDrawer, LazyMount } from '@shein/sui-mobile'
import { computed } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['onClose'])

const visible = computed({
  get() {
    return props.visible
  },
  set() {
    emits('onClose')
  }
})
</script>

<template>
  <LazyMount>
    <s-drawer
      ref="drawerItem"
      :visible.sync="visible"
      direction="btt"
      class="vis-drawer-wrap"
      :show-close="true"
      :append-to-body="true"
    >
      <template #top>
        <slot name="top"></slot>
      </template>

      <div class="vis-mcheo-inputdrawer__wrapper">
        <div class="mcheo-inputdrawer-header">
          <slot name="intro-section"></slot>

          <!-- 弹窗输入框 -->
          <div class="mshe-input-wrap">
            <slot name="input-section"></slot>
          </div>

          <slot name="input-desc"></slot>

          <div class="mcheo-inputdrawer-footer">
            <slot name="footer-region"></slot>
          </div>
        </div>
      </div>
    </s-drawer>
  </LazyMount>
</template>

<style lang="less">
  .vis-drawer-wrap {
    height: auto;
    background-color: initial;
    top: 0;
    transition: none;
  }

  .vis-mcheo-inputdrawer__wrapper {
    padding: 0 .32rem;

    .mcheo-inputdrawer-header {
      padding-top: 0.21rem;
      padding-bottom: 0.32rem;
    }

    .mshe-input-wrap {
      position: relative;
    }

    .mcheo-inputdrawer-footer {
      padding: 0.21rem 0;
    }
  }

  .vis-mcheo-inputdrawer__wrapper {
    padding: 0 .32rem;
    .mcheo-inputdrawer-header {
      padding-top: 0.21rem;
      padding-bottom: 0.32rem;
      .header-tips-price {
        font-weight: bold;
      }
    }
    .mshe-input-all {
      position: absolute;
      .right(0.32rem);
      top: 0;
      color: @sui_color_link;
      line-height: 0.96rem;
      text-transform: uppercase;
      .icon-shanchu {
        .padding-l(0.64rem);
        line-height: 0.96rem;
        color: #ccc;
      }
    }
    .mshe-input-wrap {
      position: relative;
      .mshe-input {
        border: none;
        background: #f6f6f6;
        height: 0.96rem;
        width: 100%;
        padding: 0 0.32rem;
        .padding-r(1.92rem);
        &::-webkit-input-placeholder{
          color: #ccc;
        }
      }
      .mshe-input-wallet {
        .padding-l(1.44rem);
        border: 1px solid #ccc;
        background: #fff;
      }
      .mshe-input-symbol {
        position: absolute;
        .left(0.32rem);
        top: 0;
        line-height: 0.96rem;
      }
    }
    .wallet-input-content {
      padding-bottom: 0.32rem;
      .wallet-input__total {
        .font-dpr(28px);
        font-weight: 700;
      }
      .wallet-input__tips {
        font-size: 14px;
        font-weight: 400;
        color: @sui_color_gray_dark2;
      }
    }
    .point-description-content {
      margin-bottom: 0.32rem;
      &__use-way {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.32rem;
        background: #F4F9FF;
        color: var(--sui-color-gray-dark-1, #000);
        font-size: 12px;
      }
      &__point-number {
        margin-top: 0.11rem;
        height: 0.51rem;
        color: var(--sui-color-gray-dark-1, #222);
        font-size: 14px;
        font-weight: 600;
        span {
          font-size: 16px;
        }
        .max-use-point {
          font-weight: 400;
        }
      }
    }
    .point-use-rule {
      margin-top: 0.32rem;
      color: var(--sui-color-brand, #000);
      font-size: 12px;
      &__view-more {
        color: var(--sui-color-link, #2D68A8);
        font-size: 12px;
      }
    }
    .mcheo-inputdrawer-footer {
      padding: 0.21rem 0;
    }
  }
</style>
