<template>
  <div :class="prefixCls('sp-product__info')">
    <div>
      <!-- warning警示信息[p65化学危险品标识] -->
      <P65Warning
        v-if="isShowP65Warning"
        :goodsSn="renderData.goodsSn"
      />

      <!-- title -->
      <div :class="prefixCls('sp-product__title')">
        <CommonImage
          v-if="!!renderData.titleImg"
          :class="prefixCls('sp-product__title-icon')"
          :cls="'fsp-element'"
          :imgUrl="renderData.titleImg"
          :imgDesignWidth="72"
          :isFirstPage="true"
          :isDefaultGrayGb="false"
        />
        <div
          :class="prefixCls('sp-product__title-name')"
          v-html="renderData.goodsName"
        ></div>
      </div>
      <!-- 商品 尺寸 -->
      <ProductSize
        :colorImage="renderData.colorImage"
        :goodsAttr="renderData.goodsAttr"
      />

      <!-- 用户行为标签 -->
      <div
        v-if="!!renderData.actTag?.tagName"
        v-expose="{
          id: 'expose_shoppingbag_goodslist:simple',
          data: {
            show_behavior_label: renderData.actTag?.appTraceInfo
          }
        }"
        :class="prefixCls('sp-product__behavior-tag')"
      >
        <img
          :class="prefixCls('sp-product__behavior-tag_icon')"
          :src="renderData.actTag.icon"
          alt=""
        />
        <div :class="prefixCls('sp-product__behavior-tag_txt')">
          {{ renderData.actTag.tagName }}
        </div>
      </div>

      <!-- label标签 -->
      <div
        v-if="!!renderData.productTags?.length"
        :class="prefixCls('sp-product__label-tag')"
      >
        <template v-for="(tag, idx) in renderData.productTags">
          <VoucherTag
            v-if="tag.type === 'categoryVoucher'"
            :key="idx"
            :tag="tag"
          />
          <LabelTag
            v-else
            :key="idx"
            v-expose="flashTagExposedData(tag)"
            :tag="tag"
          />
        </template>
      </div>
    </div>

    <!-- 产品价格区域 -->
    <ProductPriceSection
      :data="data"
      :quantity-editable="quantityEditable"
    />
  </div>
</template>

<script setup name="SingleProductInfo">
import { computed } from 'vue'

import ProductSize from './Size.vue'
import VoucherTag from './VoucherTag.vue'
import ProductPriceSection from './ProductPriceSection.vue'

import P65Warning from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/P65Warning.vue'
import LabelTag from '@/public/src/pages/checkout_new/components/QuickShipTag.vue'
import CommonImage from 'public/src/pages/checkout_new/components/CommonImage.vue'

import useP65warningState from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useP65warningState.js'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { RealQuickShipStatus } from '@/public/src/pages/checkout_new/utils/constant'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  // 数量是否可编辑
  quantityEditable: {
    type: Boolean,
    default: true
  },
  // 是否在弹窗内使用
  isInDrawer: {
    type: Boolean,
    default: false
  }
})
// --------- useMap_**** --------
const { checkout, language } = useMapState([
  'checkout',
  'language'
])
const { isShowP65Warning } = useP65warningState(props.data?.product?.goods_sn)
const { isGift } = useAttribute(props?.data)

// ---------- computed ----------

/**
 * @description 渲染数据
 * @returns {goodsName} 商品标题
 * @returns {titleImg} 商品标题 icon
 * @returns {colorImage} 尺寸图片
 * @returns {goodsAttr} 尺寸文本
 * @returns {actTags} 用户行为标签
 * @returns {productTags} label标签
 * */
const renderData = computed(() => {
  const {
    data: { product, aggregateProductBusiness, id, real_quick_ship }
  } = props
  const isQs = real_quick_ship === RealQuickShipStatus.AllQuick
  let qsProductTags = []
  if (isQs) {
    qsProductTags =
      checkout.value?.cartList
        ?.filter(item => item.cartId === id)
        ?.map(item => {
          return {
            textColor: item?.quickShipLabel?.tag_text_color || '',
            bgColor: item?.quickShipLabel?.tag_bg_color || '',
            prefixIcon: item?.quickShipLabel?.icon || '',
            text: item?.quickShipLabel?.tag_val_name_lang || '',
            text_des: item?.quickShipLabel?.tag_extra_val_name_lang || '',
            labelStyle: {
              'font-style': 'italic',
              'font-weight': 590,
              'font-size': '10px',
              'box-shadow': 'inset 0 0 0 .5px rgba(25,128,85,.2)'
            }
          }
        }) || []
  }

  const normalProductTags =
    aggregateProductBusiness?.productTags?.map(item => {
      let res = {
        textColor: item?.view?.textColor || '',
        bgColor: item?.view?.bgColor || '',
        prefixIcon: item?.view?.prefixIcon || '',
        suffixIcon: item?.view?.suffixIcon || '',
        text: item?.view?.text || '',
        labelStyle: {
          'font-size': '10px',
          padding: `.054rem .1067rem`
        },
        text_des: props.isInDrawer ? '' : item?.view?.suffixText || '',
        flashType: item?.data?.promotion?.flashType || ''
      }
      // voucher
      if (item.type === 'categoryVoucher') {
        res.themTextStyle = {
          'margin-right': '0.05333333rem',
          'font-weight': 600
        }
        res.dividerStyle = {
          background: '#fe3b30',
          height: '0.18rem',
          opacity: '0.4'
        }
        res.type = 'categoryVoucher'
        res.text_des = item?.data.categoryVoucher?.applyText || ''
        ;(res.popoverTips = item?.data.categoryVoucher?.tips || ''),
        (res.goodsId = product.goods_id)
      }
      return res
    }) || []

  // 个性化标签内容 - 比如“赠品标签”，其他新增的可以放于此
  let individuationTag = []

  // 赠品标签
  if (isGift.value) {
    const giftTag = {
      textColor: '#C44A01',
      bgColor: '#FFF6F3',
      prefixIcon: '',
      text: language.value?.SHEIN_KEY_PWA_18337 || '',
      text_des: '',
      labelStyle: {
        'font-size': '10px'
      }
    }
    individuationTag.push(giftTag)
  }
  // qs的tag要从checkout的cartList中匹配获取
  const filterTags = normalProductTags.filter(item => item.text !== 'QuickShip')
  // qs商品的tag除了qsTag之外，还要展示商品自身的Tag，所以需要数组合并
  const allQsProductTags = [...filterTags, ...qsProductTags]
  // 渲染tag数据
  const productTags = isQs ? allQsProductTags : normalProductTags
  return {
    goodsName: product?.goods_name || '',
    titleImg: aggregateProductBusiness?.productImgLabel?.titleImg || '',
    goodsSn: product?.goods_sn || '',
    colorImage: product?.color_image || '',
    goodsAttr: aggregateProductBusiness?.goodsAttr || '',
    actTag: product?.actTag || {},
    productTags: [...productTags, ...individuationTag]
  }
})

const flashTagExposedData = tag => {
  if (tag?.flashType) {
    return {
      id: 'expose_flash_sale:simple',
      data: {
        is_flashsale: tag?.flashType
      }
    }
  }
}
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp-product {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 2.666rem;
    flex: 1;
    min-width: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    height: 0.373rem;
    margin-bottom: 0.08rem;

    &-icon {
      width: 1.28rem;
      height: 0.32rem;
      margin-right: 0.107rem;
      img {
        height: 100%;
        width: 100%;
      }
    }

    &-name {
      color: #666;
      .font-dpr(24px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 0.373rem;
      line-height: 0.39rem;
    }
  }

  &__behavior-tag {
    overflow: hidden;
    font-size: 12px;
    color: @sui_color_micro_emphasis;
    display: inline-flex;
    align-items: center;
    margin-top: 0.16rem;

    &_icon {
      width: 13px;
      height: 13px;
    }

    &_txt {
      margin-left: 4/75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
      color: #a86104;
    }
  }

  &__label-tag {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .checkout-component__label-tag,
    .single-product-voucher__label-tag {
      margin-top: 0.16rem;
      margin-right: 0.16rem;
    }
  }
}
</style>
