var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"checkoutCarouselRef",class:_vm.prefixCls('header__carousel-container')},[_vm._l(([0, 1]),function(_,index){return [_c('div',{key:index,class:[
        _vm.prefixCls('header__cc-item'),
        {
          [_vm.prefixCls('header__cc-item_set')]:
            _vm.noAnimationIndex === index
        }
      ]},[_vm._t(`item${index}`)],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }