// 公共类名前缀
export const prefixCls = cls => {
  const prefix = 'checkout-sbs__'
  return `${prefix}${cls}`
}

export const allQuickShippingLabel = {
  color: '#198055',
  'background-color': '#F3FAF6',
  'font-size': '10px',
  'icon-size': '16px',
  'border-radius': '2px',
  border: '0.5px solid rgba(25, 128, 85, 0.20)',
  padding: '1px 3px 1px 0'
}


export const orderSectionId = (data) => {
  return `_current_dom_together_buy_mall${data?.mall_code || ''}_quick${data?.real_quick_ship || ''}`
}
