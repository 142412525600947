var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BCouponList,{ref:"BCouponListRef",attrs:{"visible":_setup.popupState.couponDrawerVisible,"is-auto-fetch-api":false,"is-free-price":_setup.isFreePrice,"isCouponAutoUse":_setup.isCouponAutoUse,"applyCouponsSuccess":_setup.applyCouponsSuccess,"checkoutNo":_setup.checkoutParams?.checkout_no,"errinfo":_setup.errinfo,"couponTotalPrice":_setup.couponTotalPrice,"selectedPaymentInfo":_setup.selectedPaymentInfo,"selectedShipMethod":_setup.checkoutParams.shipping_methods,"saveCardProductCode":_setup.checkoutParams.save_card_product_code,"primeProductCode":_setup.checkoutParams.prime_product_code},on:{"go-back":_setup.goBack,"confirm-use-coupon-handle":_setup.confirmUseCouponHandle,"handle-new-apply-btn":_setup.handleNewApplyBtn,"handle-best-apply-btn":_setup.handleBestApplyBtn,"handle-click-close":_setup.handleClose,"handle-gift--coupon-operation":_setup.handleGiftCouponOperation},scopedSlots:_vm._u([{key:"title-right-side",fn:function(){return [_c(_setup.tAndc)]},proxy:true},{key:"new-coupon-item",fn:function({
      data,
      couponConfig,
      index,
      checkoutCouponIndex,
      couponType,
    }){return [_c(_setup.NewCouponItem,{attrs:{"data":{
        ...data,
        checkoutCouponIndex
      },"expanded":_setup.expandedCoupon,"coupon-config":couponConfig,"language":_setup.language,"isForBff":true},on:{"button-click":(emitParams)=>{
        _setup.newHandleApply({...emitParams, couponType: couponType, index, isUseableCoupon: true})
      }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }