<template>
  <div
    v-if="selectedList.length > 1"
    class="xtra-radio-selected"
  >
    <!-- 内页展示的两个产品包的选项，上下排列 -->
    <div
      class="select-xtra-mode"
    >
      <div
        v-for="(item, index) in selectedList"
        :key="index"
        :class="[
          'select-xtra-mode__item',
          'select-xtra-mode__item-fixation-height',
          currentCode == item.productCode ? 'select-xtra-mode__selected-item' : '',
          // notSupport ? 'select-xtra-mode__item-disabled': ''
        ]"
        :style="labelStyle(item)"
        @click.stop="handleXtraItemClick(item)"
      >
        <div class="mode-item-left">
          <SAdapterText
            class="mode-item-left__explain"
            min-size="10"
            :text="item.title"
          />
          <SAdapterText
            class="mode-item-left__subhead"
            min-size="10"
            :text="item.subTitle"
          />
        </div>
        <div class="mode-item-right">
          <div class="mode-item-right__price-wrap">
            <div :class="['mode-item-right__arrive-price', { 'special-price-highlight': item.isShowSpecialPriceHighColor }]">
              {{ item.specialPrice }}
            </div>
            <del
              v-if="item.styleConfig.showLinePrice || item.styleConfig.showLinePrice == null" 
              class="mode-item-right__origin-price" 
            >{{ item.originPrice }}</del>
          </div>
          <s-radio
            :value="currentCode"
            :label="item.productCode"
            :margin-r="0 + 'rem'"
          />
        </div>
        <div
          v-if="currentCode == item.productCode && showLabel(item)"
          class="select-xtra-mode__item-tips"
          @click="clickDiscountTips($event, item.productCode)"
        >
          <div :class="['triangle-border', GB_cssRight ? 'rtl': '']">
            <div class="triangle"></div>
            <div class="triangle-line"></div>
          </div>
          {{ item.labelText }}
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { computed, onMounted, nextTick, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { transformProudctPackageStyle } from './shared/utils.js'

const SiteUID = ref(gbCommonInfo?.SiteUID || '')

const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)

const emit = defineEmits(['clickDiscountTips', 'changeBagEvent', 'updateProductCode'])


const props = defineProps({
  notSupport: {
    type: Boolean,
    default: false,
  },
  selectedCode: {
    type: String,
    default: ''
  },
  productPackageList: {
    type: Array,
    default: () => []
  },
  language: {
    type: Object,
    default: () => {}
  },
})

const currentCode = ref('')

// 需要做合规处理的国家
const isSpecialCountry = computed(() => SiteUID.value == 'pwfr' || ['mpl', 'pwde'].includes(SiteUID.value))

const labelText = (text, config) => {
  if (!!config?.action && !text.includes('>')) {
    return GB_cssRight.value ? '< ' + text : text + ' >' 
  }
  return text
}

const selectedList = computed(() => {
  const list = props.productPackageList.map(v => {
    const product = v.saveCardProductInfo
    const popup = v.saveCardPopup
    const autoRenewal = product?.auto_renewal

    const text = labelText(product.reducePriceLabel?.text, product.reducePriceLabel)

    return {
      title: product.saveCardPlanTip,
      subTitle: product.saveCardPlanSubTip,
      productCode: product.saveCardProductCode,
      originPrice: product.priceLocalWithSymbol,
      specialPrice: product.specialPriceWithSymbol,
      styleConfig: popup.style_info,
      labelText: text,
      isShowSpecialPriceHighColor: isSpecialCountry.value ? false : +product.priceLocal > +product.specialPrice, // 是否到手价需要高亮
      isAutoRenewProduct: product.isAutoRenewProduct,
      reducePriceLabel: product.reducePriceLabel,
      isShowDiscount: (product.style_info?.showLinePrice || product.style_info?.showLinePrice == null) && +product.priceLocal > +product.specialPrice, 
      autoRenewalBubble: autoRenewal?.pick_popup_show ? {
        bubbleText: template(+autoRenewal?.filteredPaymentList?.length, autoRenewal?.pick_popup_tip) || '',
        logoList: autoRenewal?.filteredPaymentList?.map(v => v.icon) || [],
        isShowAutoRenewdDubble: autoRenewal?.pick_popup_show,
      } : {
        isShowAutoRenewdDubble: false,
      }
    }
  })
  // 自动续费产品包排在前面
  return list.sort((a, b) => b.isAutoRenewProduct - a.isAutoRenewProduct)
})


const itemStyleConfig = (styleInfo) => {
  return transformProudctPackageStyle(styleInfo)
}

const virCouponTipsShow = (item) => {
  return item.reducePriceLabel?.action == 'couponList'
}

const showLabel = (item) => {
  return item.labelText && (item.styleConfig.showLabel || item.styleConfig.showLabel == null)
}

const labelStyle = (item) => {
  // 会费券 or 自动续费包不读取样式配置
  if (virCouponTipsShow(item) || item.isAutoRenewProduct == 1) {
    return {
      '--price-color': itemStyleConfig(item?.styleConfig)?.priceColor,
      '--line-price-color': itemStyleConfig(item?.styleConfig)?.linePriceColor,
    }
  }

  return {
    '--price-color': itemStyleConfig(item?.styleConfig)?.priceColor,
    '--line-price-color': itemStyleConfig(item?.styleConfig)?.linePriceColor,
    '--label-bg-color': itemStyleConfig(item?.styleConfig)?.labelBgColor,
    '--label-border-color': itemStyleConfig(item?.styleConfig)?.labelBorderColor,
    '--label-color': itemStyleConfig(item?.styleConfig)?.labelColor,
  }
}

/**
 * @description: 初始化swiper，如果不初始化，增加初始化属性，会导致滑动计算的时候，出现不符合预期的问题
 * @return {*}
 */
const initSwiper = () => {
  if (props.isHasAutoRenewedBag) {
    const xtraMutipleRadioSelectSwiper = document.querySelector('.j-mutiple-selected-radio')
    if (xtraMutipleRadioSelectSwiper) {
      Object.assign(xtraMutipleRadioSelectSwiper, {
        direction: 'horizontal',
        slidesPerView: 'auto',
      })
      xtraMutipleRadioSelectSwiper.initialize()
    }
  }
}

// 切包事件
const handleXtraItemClick = (item) => {
  currentCode.value = item.productCode
  emit('updateProductCode', item.productCode)
}

const clickDiscountTips = (e, productCode) => {
  e.stopPropagation()  
  emit('clickDiscountTips', e, productCode)
}

watch(() => props.selectedCode, (val) => {  
  currentCode.value = val
})

onMounted(() => {
  nextTick(() => {
    initSwiper()
  })
})

</script>

<style lang="less" scoped>
.xtra-radio-selected {
  line-height: normal;
  .special-price {
    margin-right: 4/75rem;
    color: @sui_color_gray_dark1;
    .font-dpr(32px);
    font-weight: 700;
  }
  .special-price-highlight {
    color: var(--price-color, #F82854);
  }
  .del-price {
    margin-right: 8/75rem;
    color: var(--line-price-color, #959595);
    .font-dpr(28px);
  }
  .discount-label {
    display: inline-block;
    padding: 4/75rem 12/75rem;
    border-radius: 6px 0px;
    max-width: 100%;
    color: var(--label-color, #FFF);
    .font-dpr(20px);
    font-weight: 600;
    border: 1px solid;
    border-color: var(--label-border-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--label-bg-color, linear-gradient(270deg, #F82854 0%, #DE00F1 100%));
  }
  .active-label {
    background: #FEE8ED;
    border: 1px solid #F74D81;
    color: #EB3662;
  }
  .right-wrapper-disabled {
    opacity: 0.3;
  }
  .mutiple-selected-wrapper {
    margin-top: 16/75rem;
    margin-right: -24/75rem;
    padding-left: 4/75rem;
    overflow-x: clip;
    z-index: 0;
    .item-selected {
      display: flex;
      align-items: flex-start;
      max-width: 574/75rem;
      /* stylelint-disable-next-line declaration-no-important */
      width: auto !important; // swiper需要定义一个宽度，覆盖原来swiper单项的宽度
      margin-right: 24/75rem;
      position: relative;
      &__right-wrapper-disabled {
        opacity: 0.3;
      }
      &__right-price-wrapper {
        margin-bottom: 6/75rem;
      }
    }
    .discount-label {
      max-width: 494/75rem;
    }
  }
  .single-selected-wrapper {
    display: flex;
    &__right {
      display: flex;
      align-items: center;
    }
  }
  .saver-new-style {
    margin-top: 16/75rem;
  }
  .select-xtra-mode {
    padding: 40/75rem 24/75rem 12/75rem;
    line-height: normal;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 16/75rem 24/75rem;
      border-radius: 8/75rem;
      border: 2/75rem solid var(---sui_color_gray_light3, #CCC);
      margin-bottom: 16/75rem;
      background: #FFF;
      width: 100%;
      box-sizing: border-box;
    }
    & > :last-child {
      margin-bottom: 0;
    }
    &__selected-item {
      border: 2/75rem solid #F82854;
      background: #FFEEF3;
    }
    &__item-disabled {
      opacity: 0.3;
    }
    &__item-fixation-height {
      height: 106/75rem;
    }
    &__item-tips {
      position: absolute;
      right: 0;
      top: -38/75rem;
      border-radius: 8/75rem;
      border: 2/75rem solid #F7B432;
      padding: 8/75rem 12/75rem;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      background: var(--label-bg-color, #FFF1BE);
      border-color: var(--label-border-color, #F7B432);
      color: var(--label-color, #421609);

      .triangle-border {
        position: absolute;
        width: 100%;
        height: 120%;
        top: 0;
        left: 0;
        z-index: 0;
        
        &.rtl {
          transform: rotateY(180deg);
        }
      }
      .triangle-line {
        position: absolute;
        top: 2/75rem;
        width: 100%;
        height: 100%;
        background: var(--label-border-color, #F7B432);
        clip-path: polygon(calc(100% - 26px) 83%, calc(100% - 14px) 83%, calc(100% - 20px) calc(100% + 1px), calc(100% - 20px) calc(100% + 1px));
        z-index: -1;
      }
      .triangle {
        width: 0;
        height: 0;
        width: 100%;
        height: 100%;
        background: var(--label-bg-color, #FFF1BE);
        clip-path: polygon(calc(100% - 25px) 83%, calc(100% - 15px) 83%, calc(100% - 20px) 100%, calc(100% - 20px) 100%);
      }
    }
  }
  .mode-item-left {
    // max-width: 452/75rem;
    text-align: left;
    margin-right: 26/75rem;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__explain {
      color: #111;
      font-size: 16px;
      font-weight: 600;
    }
    &__subhead {
      margin-top: 4/75rem;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 10px;
      font-weight: 400;
    }
  }
  .mode-item-right {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    
    &__price-wrap {
      text-align: left;
      margin-right: 20/75rem;
    }
    &__arrive-price {
      font-size: 18px;
      font-weight: 700;
      color: var(--price-color, #F82854);
    }
    &__origin-price {
      margin-top: 4/75rem;
      color: var(--line-price-color, #959595);
      font-size: 12px;
    }
  }
}
.j-mutiple-selected-radio::part(container){
  overflow: visible !important;
}
</style>
