<template>
  <div class="checkout-address-home">
    <Icon
      name="sui_icon_location_fill_15px"
      class="checkout-address-home__icon"
    />
    <div class="checkout-address-home__wrapper">
      <AddressHomeDetail
        :addressInfo="getDefaultAddress"
        @click="emits('clickAddressList')"
      />

      <div
        v-if="addressTitleTips"
        v-expose="{
          id: 'expose_constomsinfo_guide:simple',
          once: true
        }"
        tabindex="0"
        class="checkout-address-home__tips"
      >
        <p
          class="checkout-address-home__tips_text"
          @click="e => emits('clickAddressTips', e)"
          v-html="addressTitleTips"
        ></p>
      </div>
    </div>
  </div>
</template>

<script setup name="CheckoutAddressHome">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'

import AddressHomeDetail from './AddressHomeDetail.vue'

import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store.js'

/*------ useMap** ------*/
const { addressBarState } = useMapState(['addressBarState'])
const { getDefaultAddress } = useMapGetters(['getDefaultAddress'])
const { isClient } = useIsClient()

/*------ computed ------*/
const addressTitleTips = computed(() => {
  let alink = '#'

  if (isClient.value) {
    const osType = !!window?.navigator?.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'android'
    alink = window?.gbCommonInfo?.EZ_WAY_APP[osType] || ''
  }

  const nameVerifiedTip = template(alink, getDefaultAddress.value?.nameVerifiedTip)
  return nameVerifiedTip || addressBarState.value?.nationalIdTip || addressBarState.value?.addressTip
})

/*------ emits ------*/
const emits = defineEmits(['clickAddressList', 'clickAddressTips'])
</script>

<style lang="less">
.checkout-address-home {
  position: relative;
  padding: 20/75rem 24/75rem;
  background: #fff;
  display: flex;
  &::before {
    position: absolute;
    .left(-10px);
    bottom: 0;
    content: '';
    height: 3px;
    width: calc(100% + 10px);
    // background-size: 100%;
    // background-image: url(/pwa_dist/images/border-bg-b2776f3817.png);
    background-image: linear-gradient(
      to right,
      #8dadd0 0%,
      #8dadd0 39%,
      transparent 39%,
      transparent 50%,
      #fdb9a5 50%,
      #fdb9a5 89%,
      transparent 89%,
      transparent 100%
    );
    background-size: 62px 8px; /* 虚线重复模式的尺寸，62px是一个完整的循环长度[蓝色31，红色31，间隔8] */
    transform: skewX(-45deg);
  }
  &:active {
    background: @sui_color_gray_weak2;
  }
  &__icon {
    .margin-r(16/75rem);
  }
  &__wrapper {
    width: 94%;
  }
  &__tips {
    padding: 12/75rem 0 0;
    background: #fff;
    .font-dpr(24px);
    a {
      color: #1860a7;
      text-decoration: none;
    }
    &_text {
      background: #f6f6f6;
      padding: 20/75rem;
      color: #666;
    }
  }
}
</style>
