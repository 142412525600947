import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

/**
 * @description 是否渲染P65Warning
 * return {true} 渲染
 * return {false} 不渲染
 * */

export default function useP65warningState(goodsSn) {
  // --------- useMap_**** --------
  const { checkout } = useMapState(['checkout', 'abtInfo'])

  // 商品sku集合
  const listFn = computed(() => {
    const list = checkout.value?.p65_flag || []
    return list.filter(item => item.flag === 1)?.map(item => item.goods_sn)
  })

  const isShow = computed(() => {
    return listFn.value?.includes(goodsSn)
  })

  return { isShowP65Warning: isShow }
}
