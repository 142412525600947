<template>
  <div
    :class="prefixCls('single-wrap')"
  >
    <div
      :class="prefixCls('single__title')"
      v-html="timeStepTxt"
    ></div>
    <div
      v-if="couponItemInfo"
    >
      <coupon-item
        :item="couponItemInfo"
        :language="language"
        :show-coupon-code="false"
        :is-only-time="true"
        :is-order-return="true"
        direction-type="row"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import CouponItem from '@/public/src/pages/components/coupon/mall/index.vue'
import { prefixCls } from './utils'
import schttp from 'public/src/services/schttp'
import { CountDown, template } from '@shein/common-function'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'

const countTimerInst = new CountDown()
const isShowCountdown = ref(false)

const couponItemInfo = ref(null)

const { isClient } = useIsClient()


const props = defineProps({
  isCouponEnd: {
    type: Boolean,
    default: false,
  },
  orderReturnCoupons: {
    type: Array,
    default: () => [],
  },
  language: {
    type: Object,
    default: () => ({})
  },
})

const emits = defineEmits(['update:isCouponEnd'])

const timeStepTxt = computed(() => {
  if (!isShowCountdown.value) return props.language.SHEIN_KEY_PWA_24355
  const { H, M, S } = countTimerInst.timeObj || {}
  let spanTxt = txt => `<span class="fill">${txt}</span>`
  const timeLeftStr =  [H, M, S].map(val => spanTxt(val)).join('<em>:</em>')

  return template(
    timeLeftStr,
    props.language.SHEIN_KEY_PWA_24356
  )
})

const couponInfo = computed(() => {
  return props.orderReturnCoupons?.[0] || {}
})

const handleTimer = () => {
  const endDate = couponInfo.value?.end_time * 1000
  const stepTime = endDate - new Date().getTime()

  if(stepTime <= 0) {
    emits('update:isCouponEnd', true)
    return
  }
  if (stepTime < 12 * 60 * 60 * 1000) {
    countTimerInst.clear()
    // 初始化倒计时
    countTimerInst.start({
      seconds: Math.ceil(stepTime / 1000),
      endFunc: () => {
        emits('update:isCouponEnd', true)
      }
    })
    isShowCountdown.value = true
    return
  }
  isShowCountdown.value = false
}

// 获取优惠券详情
const getCouponDetails = async (coupon) => {
  if(!coupon) return false
  const res = await schttp({
    method: 'POST',
    url: '/api/checkout/couponDetail/get',
    params: {
      coupon
    }
  })
  if (+res?.code === 0) {
    couponItemInfo.value = res?.info?.coupon || null
  }
}

watch(() => couponInfo.value?.coupon_code, (couponCode) => {
  if(!couponCode) return

  handleTimer()
  if(isClient.value) {
    getCouponDetails(couponCode)
  }
})

onMounted(() => {
  handleTimer()
  getCouponDetails(couponInfo.value?.coupon_code)
})
</script>
<style scoped lang="less">
@import "./variables.less";

.@{prefixCls}single {
  &&-wrap {
    padding:.32rem;
    background: #fff;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    line-height: .4533rem;
    margin-bottom: .2667rem;
    span {
      color: #fff;
      background-color: #000000;
      padding: .0533rem;
      font-size: 12px;
    }

    em {
      font-weight: 400;
      color: #222;
    }
  }
}
</style>
