<template>
  <div class="checkout-coupon-gift">
    <div
      class="pick-gift__wrap"
    >
      <div class="pick-gift__left">
        <h6 class="pick-gift__left__title">
          {{ language.SHEIN_KEY_PWA_15881 }}
        </h6>
        <p class="pick-gift__left__desc">
          {{ pickGiftType === 2 ? language.SHEIN_KEY_PWA_18336 : language.SHEIN_KEY_PWA_17978 }}
        </p>
      </div>
      <div
        class="pick-gift__right"
        @click.stop="goGiftPickPage"
      >
        <span
          v-if="pickGiftType === 2"
          class="tip-intro"
        >
          {{ language.SHEIN_KEY_PWA_15831 }}<i class="iconfont icon-Rectanglex pick-gift__right__icon"></i>
        </span>
        <s-button
          v-else
          :type="['H48PX', 'primary']"
        >
          {{ language.SHEIN_KEY_PWA_15830 }}
        </s-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Button as SButton } from '@shein/sui-mobile'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

const { language } = useMapState(
  [
    'virtualAssetsState.couponGiftDrawer',
    'language',
  ])

const { updateVirtualAssetsPopupState } = useMapMutations(['updateVirtualAssetsPopupState'])

const props = defineProps({
  hasCouponGift: {
    type: Boolean,
    default: false
  },
})

const pickGiftType = computed(() => {
  return props.hasCouponGift ? 2 : 1
})

const goGiftPickPage = () => {
  updateVirtualAssetsPopupState({
    couponGiftDrawer: {
      visible: true
    }
  })
}
</script>

<style lang="less">
.checkout-coupon-gift {
  width: 100%;
}
.pick-gift {
  &__wrap {
    background: @sui_color_promo_bg;
    margin-top: 16/75rem;
    padding: 20/75rem;
    .flexbox();
    .align-center();
    .space-between();
  }

  &__left{
    .margin-r(24/75rem);

    &__title {
      font-size: 12px;
      font-weight: bold;
      color: #222222;
      text-transform: capitalize;
      padding-bottom: 4/75rem;
    }

    &__desc {
      font-size: 12px;
      color: #222;
      font-weight: normal;
    }
  }


  &__right{
    white-space: nowrap;

    .tip-intro {
      font-size: 12px;
      color: #222;
      font-weight: bold;
      cursor: pointer;
    }

    .pick-gift__right__icon {
      .flip();
      display: inline-block;
    }
  }
}
</style>
