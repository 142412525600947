<template>
  <input-number
    v-model="localValue"
    class="checkout-component__input-number"
    pattern="[0-9]*"
    :min="min"
    :max="max"
    :disable-inc="disableInc"
    :disable-dec="disableDec"
    :disable="disable"
    @focus="focus"
    @blur="blur"
    @change="change"
    @increase="increase"
    @decrease="decrease"
    @reachToMax="reachToMax"
    @reachToMin="reachToMin"
  />
</template>
<script setup name="CommonInputNumber">
import { computed } from 'vue'
import { InputNumber } from '@shein/sui-mobile'

const props = defineProps({
  quantity: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 99
  },
  disableInc: {
    type: Boolean,
    default: false
  },
  disableDec: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'focus',
  'blur',
  'change',
  'increase',
  'decrease',
  'reachToMax',
  'reachToMin'
])

// ————————————————— watch ——————————————————
// ———————————————— computed ————————————————
const localValue = computed(() => {
  return props.quantity
})
// ————————————————— method —————————————————
const focus = e => {
  emit('focus', e)
}
const blur = e => {
  emit('blur', e)
}
const change = (currentValue, oldValue, type) => {
  emit('change', currentValue, oldValue, type)
}
const increase = (currentValue, oldValue) => {
  emit('increase', currentValue, oldValue)
}
const decrease = (currentValue, oldValue) => {
  emit('decrease', currentValue, oldValue)
}
const reachToMax = e => {
  emit('reachToMax', e)
}
const reachToMin = e => {
  emit('reachToMin', e)
}
</script>

<style lang="less">
.checkout-component__input-number {
  .S-input-number__decrease {
    width: 44/75rem;
    box-sizing: border-box;
  }

  .S-input-number__increase {
    width: 44/75rem;
    box-sizing: border-box;
  }

  .S-input-number__input {
    width: 60/75rem;
    box-sizing: border-box;
  }
}
</style>
