import { onMounted } from 'vue'
import { useMapActions } from '@/public/src/pages/checkout_new/hooks/store'

const getQSParam = (carts, mallCode) => {
  const isMultipleMall = mallCode.indexOf(',') > -1
  const qsProductLength = carts?.filter(product => product.real_quick_ship == 1).length
  // 都为非 QS 商品，或存在多 mall
  if (!qsProductLength || isMultipleMall) return 0
  // 都为 QS 商品
  if (qsProductLength && qsProductLength === carts.length) return 1
  // QS 和 非QS 都有
  return -1
}
  
const getAllMallCode = (carts) => {
  const code = new Set()
  carts?.forEach(product => code.add(product.mall_code))
  return [...code].join(',')
}
  
// 各种判断格式化函数
const formatFn = ({ abtInfo, carts, exposedGoodsId }) => {
  const { PickupRec, buyNewCard, detailgoodsCard, recmultiCard, listquickshipLanguage, listquickship, listquickshipKey } = abtInfo
  const buyNewCardAbt = buyNewCard?.param?.buyNewCardstatus || 0
  const abtControl = Number(abtInfo?.AddToOrderGoods?.param?.isAddToOrderGoods || 1)
  const newProductCard = abtControl == 1 
    ? true 
    : buyNewCardAbt == 1 
      ? recmultiCard?.param?.recmultiCard || ''
      : false
  const detailgoodsCardAbt = detailgoodsCard?.param?.detailGoods || ''
  const mallCode = getAllMallCode(carts)
  const quickship = getQSParam(carts, mallCode)
  const listquickshipLanguageByCart = listquickshipLanguage?.param?.listquickshipLanguage === 'cartLanguage'
  const result = {
    type: abtControl,
    jsonRuleId: JSON.stringify(PickupRec?.param),
    newProductCard: newProductCard,
    detailgoodsCardAbt,
    listquickshipLanguageByCart,
    contextParams: {
      goods_id: carts?.map(cart => cart.product?.goods_id).join(','),
      mall_code: mallCode,
      quickship,
      exposed_goods_id: exposedGoodsId || ''
    },
    abtInfo: {
      listQuickShip: listquickship?.param?.listquickship,
      listquickKey: listquickshipKey?.param?.listquickKey
    }
  }
  return result
}

// 随手购
const useTogether = () => {
  const { getTogetherBuy } = useMapActions(['getTogetherBuy'])

  onMounted(() => {
    getTogetherBuy()
  })
}


export {
  formatFn,
  useTogether
}
