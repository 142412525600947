<template>
  <div 
    class="new-user-coupon"
    :class="{
      'new-user-coupon_small': isSmall,
    }"
    :style="{
      ...couponConfig[couponType],
      ...config?.[couponType]
    }"
  >
    <div
      class="new-user-coupon__wrapper"
      :class="{
        'new-user-coupon__wrapper_green': couponType === XtraCouponType.Shipping,
        'new-user-coupon__wrapper_ar': GB_cssRight
      }"
    >
      <div class="new-user-coupon__left">
        <!-- 券面额 -->
        <div
          ref="discountRef"
          class="new-user-coupon__discount"
          :class="{
            'new-user-coupon__discount_ellipsis': ellipsisVisible
          }"
        >
          {{ discount }}
        </div>
        <!-- 券门槛 -->
        <div class="new-user-coupon__rule">
          <div class="new-user-coupon__rule-text">
            {{ rule }}
          </div>
          <InfoIcon
            v-if="infoIconVisible"
            class="new-user-coupon__rule-icon"
            :color="couponConfig[couponType]['--newUserThemeColor']"
            @click.native="$emit('showDetailsDrawer')" 
          />
        </div>
        <!-- 券有效期 -->
        <div 
          v-if="validDaysVisible"
          class="new-user-coupon__valid"
        >
          {{ coupon?.validDayTip }}
        </div>
      </div>
      <div 
        v-if="coupon?.privilegeCycleTip" 
        class="new-user-coupon__right"
      >
        {{ coupon?.privilegeCycleTip }}
      </div>
    </div>
    <div 
      v-if="coupon.sameCouponNum > 1"
      class="new-user-coupon__quantity"
    >
      x{{ coupon.sameCouponNum }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, nextTick } from 'vue'

import InfoIcon from './InfoIcon'

import { XtraCouponType, couponConfig } from './config.js'
// import useXtraCoupon from '../hooks/useCoupon.js'

const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps({
  coupon: {
    type: Object,
    default: () => {}
  },
  config: {
    type: Object,
    default: () => {}
  },
  isSmall: {
    type: Boolean,
    default: false
  },
  infoIconVisible: {
    type: Boolean,
    default: false
  },
  validDaysVisible: {
    type: Boolean,
    default: false
  },
  cycleDetailsVisible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const discountRef = ref(null)
const ellipsisVisible = ref(false)

const discount = computed(() => {
  
  return GB_cssRight ?
    props.coupon.otherCouponRule[0].couponFaceValueTip + ' ' + props.coupon.otherCouponRule[0].couponFaceValue :
    props.coupon.otherCouponRule[0].couponFaceValue + ' ' + props.coupon.otherCouponRule[0].couponFaceValueTip
})

const rule = computed(() => {
  return props.coupon.otherCouponRule[0].freeCouponThresholdTip
})

const couponType = computed(() => {
  const { applyForType } = props.coupon
  return applyForType == 'DISCOUNT' ?
    XtraCouponType.Product : 
    XtraCouponType.Shipping
})

watch(
  () => discount,
  () => {
    nextTick(() => {
      if (!discountRef.value) return
      const { clientHeight } = discountRef.value || {}
      ellipsisVisible.value = clientHeight > 29
    })
  },
  {
    immediate: true
  }
)
</script>

<style lang="less" scoped>
.new-user-coupon {
  width: 636/75rem;
  height: 144/75rem;
  position: relative;

  &_small {
    width: 312/75rem;
    .new-user-coupon__wrapper {
      mask-position: 216/75rem -12/75rem;
      &::before,
      &::after {
        left: 212/75rem;
      }

      &_ar {
        mask-position: 74/75rem -12/75rem;
      }
    }
    .new-user-coupon__rule-text {
      max-width: 230/75rem;
    }

    .new-user-coupon__right {
      display: none;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    background: linear-gradient(102deg, #FFD4E9 0%, #FFF8FB 32%, #FFE2EE 87.81%);
    border: 2/75rem solid var(--newUserBorderColor, rgba(255, 255, 255, 0.80));
    border-radius: 16/75rem;
    mask-position: 436/75rem -12/75rem;
    mask-image: radial-gradient(circle at 12/75rem 12/75rem, transparent 11/75rem, #fff 12/75rem);
    color: var(--newUserThemeColor);
    line-height: 1.2;
    padding: 0 26/75rem 0 24/75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 434/75rem;
      width: 28/75rem;
      height: 28/75rem;
      background-color: var(--newUserBorderColor, rgba(255, 255, 255, 0.80));
      transform: translateY(-50%);
      border-radius: 100%;
    }

    &::after {
      top: auto;
      bottom: 0;
      transform: translateY(50%);
    }

    &_green {
      background: linear-gradient(102deg, #E3FFEF 0%, #FFF 41.6%, #E4FFF4 89.46%);
    }

    &_ar {
      mask-position: 176/75rem -12/75rem;
    }

  }

  &__left {
    max-width: 412/75rem;
  }

  &__discount {
    max-width: 422/75rem;
    .font-dpr(48px);
    font-weight: 700;
    &_ellipsis {
      .font-dpr(38px);
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__rule {
    display: flex;
    font-family: "SF Pro";
    .font-dpr(20px);
    &-text {
      max-width: 380/75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-icon {
      margin-left: 8/75rem;
      padding-top: 2/75rem;
    }
  }

  &__valid {
    display: flex;
    font-family: "SF Pro";
    .font-dpr(20px);
    font-weight: 274;
    margin-top: 6/75rem;
  }

  &__right {
    max-width: 150/75rem;
    text-align: right;
  }

  &__quantity {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    background-color: var(--newUserThemeColor);
    .font-dpr(28px);
    padding: 8/75rem;
    aspect-ratio: 1;
    line-height: 1;
    transform: translate(25%, -25%);
  }
}
</style>
