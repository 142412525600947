<template>
  <div
    :class="[
      prefixCls('ht__operation'),
    ]"
  >
    <slot></slot>
    <ClientOnly>
      <AnimationCarousel
        ref="AnimationCarouselRef"
        :class="[
          prefixCls('ht__operation-animation'),
          isShowTwoRowsTitle
            ? prefixCls('ht__operation-title_vice')
            : prefixCls('ht__operation-title_single')
        ]"
        @callBackAfterAnimation="callBackAfterAnimation"
      >
        <template
          v-for="(_, index) in [0, 1]"
          :slot="`item${index}`"
        >
          <h2
            :key="index"
            :class="prefixCls('ht__operation-animation_item')"
          >
            <Icon
              v-for="(icon, iconIndex) in allIcons"
              v-show="TitleConfig[modelData.titles[index]?.titleType]?.icon?.name === icon.name"
              :key="iconIndex"
              :name="icon.name"
              :color="icon.color"
              :size="isShowTwoRowsTitle ? '12px' : icon.size"
            />
            <div
              :class="[
                prefixCls('ht__oai-txt'),
                isShowTwoRowsTitle
                  ? prefixCls('ht__oai-txt_vice')
                  : prefixCls('ht__oai-txt_single')
              ]"
            >
              <span
                v-if="useGetTitleConfig(index,modelData).before"
                :class="[
                  prefixCls('ht__oai-txt_before'),
                  isShowTwoRowsTitle && prefixCls('ht__oai-txt_normal')
                ]"
                v-html="useGetTitleConfig(index,modelData).before"
              ></span>
              <AnimationCarousel
                v-if="useGetTitleConfig(index,modelData).special"
                :ref="`textCarouselRef${index}`"
                :class="prefixCls('ht__oai-txt_special')"
              >
                <template
                  v-for="(__, textIndex) in [0, 1]"
                  #[`item${textIndex}`]
                >
                  <span
                    :key="textIndex"
                    v-html="useGetTitleConfig(index, modelData).special"
                  ></span>
                </template>
              </AnimationCarousel>
              <span
                v-if="useGetTitleConfig(index,modelData).after"
                :class="[
                  prefixCls('ht__oai-txt_after'),
                  isShowTwoRowsTitle && prefixCls('ht__oai-txt_normal')
                ]"
                v-html="useGetTitleConfig(index,modelData).after"
              ></span>
            </div>
          </h2>
        </template>
      </AnimationCarousel>
    </ClientOnly>
  </div>
</template>

<script setup name="AutoScrollUpTitle">
import { onMounted, onUnmounted, computed, ref, nextTick, reactive, toRefs } from 'vue'
import ClientOnly from 'vue-client-only'
import { Icon } from '@shein-aidc/icon-vue2'
import { throttle } from '@shein/common-function'

import AnimationCarousel from '@/public/src/pages/checkout_new/pages/header/components/Carousel.vue'

import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import {
    TitleConfig,
    HeaderTitleType,
    abtMap,
    allIcons
} from '@/public/src/pages/checkout_new/pages/header/utils/constant'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useTitleConfig from '../hooks/useGetTitleConfig'

const autoPlayQueue = []

// ---------- props ----------
const props = defineProps({
  isShowTwoRowsTitle: {
    type: Boolean,
    default: false
  }
})

// ---------- state ----------
const AnimationCarouselRef = ref(null)
const textCarouselRef0 = ref(null)
const textCarouselRef1 = ref(null)
const timer = ref(null)
const autoPlayIndex = ref(0)
const switching = ref(false)
const headerHeight = ref(0)
const clientHeight = ref(0)
const modelData = reactive({
  titles: props.isShowTwoRowsTitle ? [{ moduleType: '', titleType: '', value: [] }, {}] : [{ moduleType: HeaderTitleType.Normal, titleType: HeaderTitleType.Normal, value: [] }, {}],
})

// ---------- useMap*** ----------
const { useGetTitleConfig, getMiddleLayerType, handleScrollTitleReport } = useTitleConfig()
const { abtInfo } = useMapState(['abtInfo'])

// ---------- abt ----------
const abtQueue = abtInfo?.value?.UserBehaviorTips?.param?.NewCheckoutHeadline?.split(',') ?? []

// ---------- computed ----------
const currentIndex = computed(() => {
  return AnimationCarouselRef.value?.currentIndex ?? 0
})

const nextIndex = computed(() => {
  return AnimationCarouselRef.value?.nextIndex ?? 0
})

// ---------- method ----------
/**
 * @description: 获取Carousel组件引入
 */
const getTextCarouselRef = (idx) => {
  const textCarouselRefArr = [textCarouselRef0, textCarouselRef1]
  if(textCarouselRefArr[idx]){
    return textCarouselRefArr[idx]
  }
  return
}

/**
 * @description: 动画播结束放后回调函数
 */
const callBackAfterAnimation = () => {
  switching.value = false
  const textCarouselRef = getTextCarouselRef(nextIndex.value)
  if(textCarouselRef.value){
    textCarouselRef.value[0]?.reset()

  }
}

/**
 * @description: 标题切换后触发相关的动画
 * @param {string} moduleType 此时曝光的模块类型
 * @param {string} title 需要切换的标题类型
 * @param {string} value 当前对应的值
 */
const playTitleAnimation = (moduleType, title, value ) => {
  switching.value = true
  const config = [...toRefs(modelData.titles)]
  config[nextIndex.value] = {
    moduleType,
    titleType: title,
    ...(value ? { value: [value] } : {})
  }
  modelData.titles = config
  AnimationCarouselRef.value?.playAnimation()
  // 埋点上报
  handleScrollTitleReport(title)
}

/**
 * @description: 初始化播放
 * **/
const initAutoPlay = () => {
  if(!props.isShowTwoRowsTitle){
    abtQueue.forEach(item => {
      const titleType = abtMap[item]
      if (!titleType) return
      if (titleType === HeaderTitleType.FreeReturn) {
        return
      }
      autoPlayQueue.push(titleType)
    })
  }
  autoPlayIndex.value = 0
  autoPlayQueue.push(HeaderTitleType.Shipping)
}

// 初次渲染埋点上报
const handleHeadlineReport = () => {
  const titleType = Object.values(HeaderTitleType)
  const hasLabel = titleType.some(item => {
    switch(item) {
      case HeaderTitleType.FreeReturn:
      case HeaderTitleType.SecureShipping:
        return autoPlayQueue.includes(item)
      default:
        return handlePreliminaryCheck(item).passed
    }
  })
  daEventCenter.triggerNotice({
    id: 'expose_scenesabt:simple',
    data: {
      scenes: 'headline_tips',
      type: hasLabel ? 3 : 1
    }
  })
}

/**
 * @description: 计算页面视窗、顶部标题栏的高度
 * @return {*}
 */
const handleInitHeight = () => {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  const footerHeight = document.querySelector('.checkout-footer__container')?.getBoundingClientRect().height

  headerHeight.value = document.querySelector('.checkout-header__header-wrapper')?.getBoundingClientRect().height
  clientHeight.value =  windowHeight - footerHeight
}

/**
 * @description: 1500s内，没有做任何操作，判断当前模块是不是在整个视窗（除footer的高度）
 * @param {string} module 已曝光模块
 * @return {boolean} 是否满足曝光条件 true/false
*/
const handleFirstCheck = (module) => {
  if (!headerHeight.value || !clientHeight.value) {
    handleInitHeight()
  }

  const condition = (top, bottom) => ( top <= clientHeight.value && bottom >= headerHeight.value)
  const exposed = handleModuleExposeCheck(module, condition)
  return exposed
}

/**
 * @description: 检查某个模块是否曝光
 * @param {string} module 模块
 * @param {funtion} condition 曝光条件，是一个函数
 * @return {boolean} 是否满足曝光条件 true/false
 */
const handleModuleExposeCheck = (module, condition) => {
  if (!module || !condition) return false
  const elements = [...document.getElementsByClassName(`checkout-scroll__${module.toLocaleLowerCase()}`)]
  const exposed = elements.some(element => {
    const elementRect = element.getBoundingClientRect()
    const elementTop = elementRect.top
    const elementBottom = elementRect.bottom
    return condition(elementTop, elementBottom)
  })
  return exposed
}

/**
 * @description: 处理满足检测需求的模块，此时的视窗需要乘以0.4。
 *               场景有两种：1、是滚动。2、1500s内，做了一些操作，触发该逻辑
 * @return {*}
 */
const handleExposedModule = () => {
  const modules = [HeaderTitleType.Shipping, HeaderTitleType.Payment, HeaderTitleType.Saved]
  const { moduleType } = modelData.titles[currentIndex.value]
  const exposedModule = handleDoOperateCheck(modules)

  // 如果曝光的模块已经是当前模块，则不需要进行切换
  if (moduleType === exposedModule) return
  if (exposedModule) {
    // 一旦滚动到其他模块，轮播停止
    clearTimeout(timer.value)
    handleHeaderTitleChange(exposedModule)
  }
}

/**
 * @description: 做了一些操作后的检测，需要到达视窗高度的40%
 * @param {array} modules 模块
 * @return {string} 满足曝光条件的模块
 */
const handleDoOperateCheck = (modules) => {
  const boundary = (window.innerHeight || document.documentElement.clientHeight) * 0.4
  const condition = (top, bottom) => top <= boundary && boundary <= bottom
  const exposedModule = modules.find(module => (
    handleModuleExposeCheck(module, condition)
  ))
  return exposedModule
}

/**
 * @description: 标题切换
 * @param {string} module 已曝光的模块类型
 * @return {*}
 */
const handleHeaderTitleChange = (moduleType) => {
  const { title, value } = getDisplayedTitle(moduleType)
  const { titleType } = modelData.titles[currentIndex.value]
  // 曝光模块更新，标题不变。
  // 如shipping展示了兜底标题（支付安全保障），当payment模块曝光后，标题不需要切换，仅数据更新
  if (titleType === title) {
    const config = [...toRefs(modelData.titles)]
    config[currentIndex.value] = {
      ...toRefs(modelData.titles[currentIndex.value]),
      moduleType: moduleType,
    }
    modelData.titles = config
    return
  }

  playTitleAnimation(moduleType, title, value)
}

/**
 * @description: 当用户在1500秒内做了滚动等操作，视窗范围计算的就是0.4，否则就是整个视窗判断
 * @return {*}
 */
const setInitialTimer = () => {
  clearInterval(timer.value)
  timer.value = setTimeout(() => {
    const moduleType = autoPlayQueue[autoPlayIndex.value]
    const exposedModule = HeaderTitleType.Shipping
    if(handleFirstCheck(exposedModule)){
      handleHeaderTitleChange(moduleType)

      if (autoPlayIndex.value + 1 <= autoPlayQueue.length - 1) {
        autoPlayIndex.value++
        setInitialTimer()
      }
    } else {
      handleExposedModule()
    }
  }, 1500)
}

/**
 * @description: 标题切换前的检测（检查能否展示模块对应的标题）
 * @param {string} type 曝光类型
 * @return {*}  是否满足标题展示条件，若不满足，则需要展示兜底方案
 */
const handlePreliminaryCheck = (type) => {
  switch(type) {
    case HeaderTitleType.Shipping:
      return {
        passed: Boolean(getMiddleLayerType(type)),
        value: 0
      }
    case HeaderTitleType.Saved:
      return {
        passed: Boolean(getMiddleLayerType(type)),
        value: 0
      }
    default:
      return { passed: true }
  }
}

/**
 * @description: 获取可以用于切换的标题
 * @param {string} moduleType 已曝光的模块类型
 * @return {string} 切换的标题类型
 */
const getDisplayedTitle = (moduleType) => {
  const preCheck = handlePreliminaryCheck(moduleType)
  if (preCheck.passed) {
    return {
      title: moduleType,
      value: preCheck.value
    }
  } else {
    // 异常情况，当前模块不满足条件，则展示‘支付安全保障’文案
    return {
      title: HeaderTitleType.Payment
    }
  }
}

/**
 * @description: 滚动函数节流
 */
const handlePageScroll = throttle({
  func: () => {
    if(!AnimationCarouselRef.value?.finished || switching.value) return
    handleExposedModule()
  },
  wait: 800,
  options: {
    leading: true,
    trailing: true
  }
})

// ---------- 生命周期 ----------
onMounted(() => {
  initAutoPlay()
  // 埋点上报
  handleHeadlineReport()
  handleScrollTitleReport(HeaderTitleType.Normal)

  nextTick(() => {
    handleInitHeight()
    setInitialTimer()
  })
  window.addEventListener('scroll', handlePageScroll)
})

onUnmounted(() => {
  clearInterval(timer.value)
  window.removeEventListener('scroll', handlePageScroll)
})

</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}ht {
  &__operation {
    &-animation {
      &-title_vice {
        width: 279px;
        height: 26/75rem;
        .font-dpr(22px);
      }
      &-title_single {
        width: 100;
        height: 100%;
      }
      &_item {
        display: flex;
        justify-items: center;
        align-items: center;
        color: inherit;
      }
    }
    &-title_single{
      height: 40/75rem;
      .font-dpr(28px);
    }
  }
  &__oai {
    &-txt {
      display: flex;
      align-items: center;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      margin-left: 4/75rem;
      line-height: 1.2;
      &_vice {
        height: 26/75rem;
        .font-dpr(22px);
      }
      &_single {
        height: 40/75rem;
        & span{

          .font-dpr(28px);
        }

      }
      &_normal {
        font-weight: 400;
      }
    }
  }
}

.change-title {
  &__item {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  &__text {
    display: flex;
    align-items: center;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin-left: 4/75rem;
    line-height: 1.2;

    &_special {
      height: 100%;
    }
  }

  &__text-normal {
    font-weight: 400;
  }

  &__single-text {
    height: 40/75rem;
    .font-dpr(32px);
  }

  &__vice-text {
    height: 26/75rem;
    .font-dpr(22px);
  }

  &__text-small {
    .font-dpr(28px);
    height: 34/75rem;
  }
}
</style>
