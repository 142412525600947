<script setup name="ShippingMethodWrapper">
import { CombinationMode } from '@/public/src/pages/checkout_new/utils/constant'
import ShippingMethodMain from './index.vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { computed } from 'vue'

const props = defineProps({
  layout: {
    type: String,
    default: CombinationMode.Separation
  },
  mallIndex: {
    type: Number,
    default: 0
  }
})

const { shipping_methods_mall, cartsInfo, layoutMode } = useMapState([
  'checkout.shipping_methods_mall',
  'cartsInfo',
  'layoutMode',
])

const goods = computed(() => {
  return layoutMode.value === CombinationMode.Separation ? shipping_methods_mall.value?.[0] || {} : cartsInfo.value?.good_by_mall[props.mallIndex] || {}
})

</script>

<template>
  <ShippingMethodMain
    :mallCode="goods.mall_code"
    :mall-data="shipping_methods_mall?.find(item => +item.mall_code === +goods.mall_code) || {}"
  />
</template>
