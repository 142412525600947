import ShippingContent from '@/public/src/pages/common/shipping/content'

export function getShippingContentInfo (freeMallInfo) {
  const result = {}
  let { platform, mall_list, mall_transport_free_list } = freeMallInfo || {}
  
  if(platform){
    result.platform = new ShippingContent([platform])
  }
  
  if(mall_list?.length){
    mall_list.forEach(item => {
      let shipInfo = item.free_list?.length ? item.free_list : item.freight_tips || []
      let shippingType = item.free_list?.length ? 'commonShip' : 'freightShip'
      if(shipInfo.length) {
        result[item.mall_code] = new ShippingContent(shipInfo, shippingType)
      }
    })
  }
  
  if (mall_transport_free_list?.length) {
    result.mall_transport_free_list = mall_transport_free_list
  }
  
  if(typeof window != 'undefined') {
    sessionStorage.setItem('lastTimeMallShippingInfo', JSON.stringify(result))
  }
  
  return result
}
