<template>
  <div 
    v-if="addressInfo && Object.keys(addressInfo).length"
    v-enterkey
    :class="[{
      'checkout-address-homedetail': true,
      'checkout-address-homedetail_default': props.showDisplayBefore,
    }]"
    role="button"
    @click="e => emits('click', e)"
  >
    <div 
      class="checkout-address-homedetail__content"
      tabindex="0"
    >
      <div
        class="checkout-address-homedetail__consignee"
      >
        <div class="checkout-address-homedetail__consignee_name">
          {{ getAddressName(addressInfo) }}
        </div>
        <div class="checkout-address-homedetail__consignee_phone">
          {{ addressInfo.tel }}
        </div>
      </div>
      <!-- 先这样写 shipping 场景如果存在 JP 场景, 需要加 props 控制 -->
      <template v-if="addressInfo.countryId == COUNTRY_OR_AREA_CODE.JP">
        <p>〒 {{ addressInfo.postcode }}</p>
        <p :data-country="addressInfo.countryId">
          {{ addressInfo.countryName }}
          {{ addressInfo.state }}
          {{ addressInfo.city }}
          {{ addressInfo.district != 0? addressInfo.district: '' }}
          {{ addressInfo.address1 }}
          {{ addressInfo.address2 }}
        </p>
      </template>
      <template v-else>
        <p>
          {{ addressInfo.address1 }}
          {{ addressInfo.address2 }}
          <template v-if="addressInfo.countryId == COUNTRY_OR_AREA_CODE.TW && addressInfo.type == 3">
            ({{ language.SHEIN_KEY_PWA_17662 }})
          </template>
        </p>
        <p :data-country="addressInfo.countryId">
          {{ addressInfo.district != 0 ? addressInfo.district: '' }}
          {{ [addressInfo.city].filter(Boolean).join('/') }}
          {{ [addressInfo.state].filter(Boolean).join('/') }}
          {{ addressInfo.countryName }}
          {{ addressInfo.postcode }}
        </p>
      </template>
      <div 
        v-if="addressInfo?.sensitiveRule?.sensitiveTip && props.showSensitiveTip"
        class="checkout-address-homedetail__sensitiveTip"
      >
        {{ addressInfo?.sensitiveRule?.sensitiveTip }}
      </div>
    </div>
    <Icon
      v-if="props.showRightIcon"
      :name="!!GB_cssRight ? 'sui_icon_more_left_16px' : 'sui_icon_more_right_16px'"
    />
  </div>
</template>

<script setup name="CheckoutAddressHomeDetail">
import { Icon } from '@shein-aidc/icon-vue2'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store.js'
import { COUNTRY_OR_AREA_CODE } from '@/public/src/pages/checkout_new/utils/constant.js'
import { getAddressName }from '../utils/index.js'

/*------ props ------*/
const props = defineProps({
  addressInfo: { type: Object, default: () => {} },
  showSensitiveTip: { type: Boolean, default: true },
  showRightIcon: { type: Boolean, default: true },
  showDisplayBefore: { type: Boolean, default: false }
})

/*------ emits ------*/
const emits = defineEmits(['click'])

/*------ useMap*** ------*/
const { language, GB_cssRight } = useMapState(['language', 'GB_cssRight'])
</script>

<style lang="less">
.checkout-address-homedetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &_default {
    &:before {
      display: none;
    }
  }
  &__content {
    padding: 0;
    .text-overflow();
    p {
      line-height: 34/@w2-375rem;
      font-size: 28/@w2-375rem;
      margin-bottom: 8/@w2-375rem;
      color: #222;
      .text-overflow();
      .padding-r(24/@w2-375rem);
    } 
  }
  &__sensitiveTip{
    padding-top: .106rem;
    color: @sui_color_unusual;
    font-size: 12px;
    white-space: normal;
    word-break: break-all;
  }
  &__consignee {
    display: flex;
    line-height: 38/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/75rem;
    &_name {
      .margin-r(16/@w2-375rem);
      font-weight: 600;
      .text-overflow();
    }
    &_phone {
      font-size: 24/@w2-375rem;
      color: #767676;
      white-space: nowrap;
      overflow: unset;
      text-overflow: unset;
    }
  }
}
</style>

