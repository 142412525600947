import { ref } from 'vue'
import { useBsPayInstance } from '@/public/src/pages/checkout_new/hooks/useBsPayInstance'

let paymentBackTipsRef = ref(null)

export const usePaymentBackTips = () => {

  const showPaymentBackTips = () => {
    paymentBackTipsRef.value?.showBackTips()
  }

  const fetchPaymentBackTipsData = (order) => {
    paymentBackTipsRef.value?.getData(order)
  }

  const handleBackTipsMounted = () => {
    // useBsPayInstance内没有响应式数据，所以这里可以按需引入
    const { paymentInstance } = useBsPayInstance()
    paymentInstance?.bindComponentMethods({
      showCodBackTips: {
        showMethod: showPaymentBackTips,
        fetchData: fetchPaymentBackTipsData,
      }
    })
  }


  return {
    paymentBackTipsRef,
    handleBackTipsMounted,
  }
}
