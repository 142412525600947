<template>
  <!-- 折扣角标 -->
  <div
    v-if="discountInfo.visible"
    :class="[prefixCls('common_discount-sub')]"
    :style="
      isMultiple
        ? {
          color: discountInfo.color,
          border: `1px solid ${discountInfo.color}`
        }
        : { background: discountInfo.color }
    "
    aria-hidden="true"
  >
    -{{ discountInfo.discount }}%
  </div>
</template>

<script setup name="discountInfo">
import { computed } from 'vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  isMultiple: {
    type: Boolean,
    default: false
  }
})

// --------- computed ---------
/**
 * @description 折扣信息
 * @returns1 {visible} 是否展示
 * @returns2 {discount} 折扣
 * @returns3 {color} 颜色
 * */
const discountInfo = computed(() => {
  const discount = +props.data?.product?.unit_discount || 0
  return {
    visible: discount > 0,
    discount,
    color:
      props.data?.aggregateProductBusiness?.priceData?.unitPrice?.color || ''
  }
})
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}common_discount-sub {
  color: #ffffff;
}
</style>
