<template>
  <div :class="prefixCls('pqs_quick-label')">
    <!-- quickShip 标志 -->
    <ShippingLabel />
    <!-- qs需要运费 -->
    <div
      v-if="checkout?.quick_shipping.switch_qs"
      v-expose="exposeQsFreight"
      :class="prefixCls('pqs-freight')"
      @click.stop.prevent="
        changeFreightType(
          shoppingBagsState?.qsFreightStatus === QsFreightType.true
        )
      "
    >
      <div style="width: 0.76rem; height: 0.5333rem">
        <ClientOnly>
          <s-radio
            :value="shoppingBagsState?.qsFreightStatus === QsFreightType.true"
            :reverse-select="true"
            :label="true"
            :inline="false"
            :margin-r="16 / 75 + 'rem'"
          />
        </ClientOnly>
      </div>
      <div :class="prefixCls('pqs-freight_content')">
        <div :class="prefixCls('pqs-freight_content-name')">
          {{ checkout?.quick_shipping.switch_qs?.switch_qs_name }}
        </div>
        <div
          v-tap="{
            id: 'click_quick_shipping:simple',
            data: { quickship_tp: '2' }
          }"
          :class="prefixCls('pqs-freight_content-time')"
          @click.stop="handleShowDelivery(true)"
          v-html="checkout?.quick_shipping?.quick_shipping_time_desc"
        ></div>
      </div>
    </div>

    <!-- qs不需要运费提示 -->
    <QuickShipLabel />

    <ClientOnly>
      <DeliveryDialog
        v-if="isShowDelivery"
        :isShowDelivery="isShowDelivery"
        @emitCloseDeliveryTips="handleShowDelivery(false)"
      />
    </ClientOnly>
  </div>
</template>

<script setup name="PartQuickShippingLabel">
import { ref, computed } from 'vue'
import ClientOnly from 'vue-client-only'

import ShippingLabel from '../../components/ShippingLabel.vue'
import DeliveryDialog from '@/public/src/pages/checkout_new/pages/shopping_bags/components/product_shipping/PartQuickShipping/components/DeliveryDialog.vue'
import QuickShipLabel from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/QuickShipLabel.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import {
  useMapState,
  useMapMutations,
  useMapActions
} from '@/public/src/pages/checkout_new/hooks/store'
import { QsFreightType } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils/constant'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const isShowDelivery = ref(false)

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  }
})

// --------- useMap_**** --------
const { shoppingBagsState, checkout, selectedShippingList } = useMapState([
  'shoppingBagsState',
  'checkout',
  'shippingMethodState.selectedShippingList',
])
const { updateCheckoutData } = useMapActions(['updateCheckoutData'])
const { updateQsFreightStatus, resetQsFreightStatus, assignState } =
  useMapMutations([
    'updateQsFreightStatus',
    'resetQsFreightStatus',
    'assignState'
  ])

// ----------- method ------------
const changeFreightType = visible => {
  updateQsFreightStatus(visible ? QsFreightType.false : QsFreightType.true)

  assignState({
    isClickedQsFreight: true
  })

  const newShippingMethods = selectedShippingList.value.map(item => {
    if (+item.mall_code === +props.mallCode) {
      return {
        ...item,
        // 选中入参传1，未选中传2
        switch_qs_flag: visible ? QsFreightType.false : QsFreightType.true
      }
    }
    return item
  })

  updateCheckoutData({
    opts: {
      shipping_methods: newShippingMethods
    },
    cb: () => {
      resetQsFreightStatus({ checkout: checkout.value })
    }
  })

  daEventCenter.triggerNotice({
    id: 'click_qs_freight:simple',
    data: {
      qs_freight_status: !visible ? 1 : 0,
      mall_code: props.mallCode,
      shipping_method:
        selectedShippingList.value?.find(_ => +_.mall_code === +props.mallCode)
          ?.transport_type || ''
    }
  })
}

const handleShowDelivery = value => {
  isShowDelivery.value = value
}

const exposeQsFreight = computed(() => {
  return {
    id: 'expose_qs_freight:simple',
    data: {
      default_qs_freight_status:
        shoppingBagsState.value?.qsFreightStatus === QsFreightType.true ? 1 : 0,
      shipping_method:
        selectedShippingList.value?.find(_ => +_.mall_code === +props.mallCode)
          ?.transport_type || '',
      mall_code: props.mallCode
    }
  }
})

</script>

<style lang="less">
@import '../../../../variables.less';

.@{prefixCls}pqs_quick-label {
  margin-bottom: 0.16rem;

  .checkout-sbs__common__shipping-label {
    padding: 0;
  }

  .@{prefixCls}pqs {
    &-freight {
      white-space: initial;
      .font-dpr(24px);
      color: @sui_color_gray_dark1;
      display: flex;
      align-items: flex-start;

      > sui-icon-common__wrap {
        color: @sui_color_gray_light1;
        margin-left: 0.10666667rem;
      }

      &_content {
        font-size: 12px;
        display: inline-block;

        &-name {
          color: @sui_color_main;
          font-weight: 700;
          display: flex;
          align-items: center;
          .font-dpr(24px);

          .sui-icon-common__wrap {
            .margin-l(8/75rem);
            color: #bbb !important;
            font-weight: normal;
          }
        }

        &-time {
          margin-top: 0.10666667rem;
          white-space: initial;
          .font-dpr(24px);
          color: @sui_color_gray_dark1;
        }
      }
    }
  }
}
</style>
