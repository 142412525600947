<template>
  <div>
    <div
      :class="prefixCls('large-ship')"
      @click.stop="onLargeShipClick"
    >
      <GoodsInfo
        v-if="largeShipInfo.outsideImg"
        :largeShipGoods="largeShipInfo.outsideImg"
        :goodsCount="largeShipInfo.outsideImgNum || ''"
      />
      <div :class="prefixCls('large-ship__tip-wrap')">
        <div
          class="detail"
          v-html="largeShipInfo.outsideDesc"
        >
        </div>
        <Icon
          :name="!GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px'"
        />
      </div>
    </div>
  </div>
</template>

<script setup name="LargeShipTips">
import { prefixCls } from '../../../utils/index'
import GoodsInfo from './GoodsInfo'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const { GB_cssRight } = useMapState(['GB_cssRight'])
const { updateShipPopupState } = useMapMutations(['updateShipPopupState'])

const props = defineProps({
  largeShipInfo: {
    type: Object,
    default: () => ({})
  }
})

const onLargeShipClick = () => {
  daEventCenter.triggerNotice({
    id: 'expose_sealand_popup:simple',
  })

  updateShipPopupState({
    largeShipInfo: {
      visible: true,
      seaLandInfo: props.largeShipInfo
    }
  })
}
</script>

<style lang="less">
@import "../../../variables.less";

.@{prefixCls}large-ship {
  border-radius: 2px;
  border: 0.5px solid #FFE2D3;
  background: #FFF6F3;

  display: flex;
  padding: 12/75rem 16/75rem;
  align-items: center;


  .@{prefixCls}large-ship__tip-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: @sui_color_highlight;
    .font-dpr(24px);

    .detail {
      overflow : hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
