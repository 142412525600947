import { onMounted, onBeforeUnmount } from 'vue'
import { useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient'

export function useVirtualAssets() {
  const { isClient } = useIsClient()
  const EventMap = {
    OPEN_POINT_DRAWER: 'virtual-assets:open-point-drawer'
  }
  const { point, virtualAssetsState } = useMapState([
    'checkout.point',
    'virtualAssetsState'
  ])
  const { updateVirtualAssetsPopupState } = useMapMutations([
    'updateVirtualAssetsPopupState'
  ])
  
  onMounted(() => {
    if(!isClient.value) return
    
    window.appEventCenter
      .$on(EventMap.OPEN_POINT_DRAWER, ({ value, visible }) => {
        let newVal
        
        if(value) {
          newVal = +value > point.value.maxAvailablePoint ? point.value.maxAvailablePoint : +value
        } else {
          newVal = virtualAssetsState.value.pointDrawer.value
        }
        
        updateVirtualAssetsPopupState({
          pointDrawer: {
            visible,
            value: newVal,
          }
        })
      })
  })
  
  onBeforeUnmount(() => {
    if(!isClient.value) return
    
    window.appEventCenter
      .$off(EventMap.OPEN_POINT_DRAWER)
  })
  
  return {
    EventMap,
    off() {
      if(!isClient.value) return
      
      window.appEventCenter
        .$off(EventMap.OPEN_POINT_DRAWER)
    }
  }
}
