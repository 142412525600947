<template>
  <CommonImage
    :class="[prefixCls('sp__main-image'), 'high-priority']"
    :cls="'fsp-element'"
    :imgUrl="data?.product?.goods_img"
    :imgDesignWidth="80"
    :isFirstPage="true"
  >
    <template #others>
      <!-- 敏感品 icon -->
      <Sensitive :id="data.id" />

      <!--Sold Out-->
      <div
        v-if="isSoldOut"
        :class="prefixCls('sp__sold-out')"
      >
        <!--居中容器-->
        <div :class="prefixCls('sp__sold-out-s')">
          <sui_icon_sold_out_36px
            size="24px"
            color="#FFFFFF"
          />
          <div :class="prefixCls('sp__sold-out-sd')">
            {{ language.SHEIN_KEY_PWA_14987 }}
          </div>
        </div>
      </div>

      <!-- 腰带 -->
      <Belt
        v-if="beltInfo.isShow"
        :data="data"
        :renderWidth="renderWidth"
      />

      <!-- 折扣角标 -->
      <Discount
        :data="data"
        :class="[prefixCls('sp__discount-sub')]"
      />
    </template>
  </CommonImage>
</template>

<script setup name="SingleProductMainImage">
import { computed } from 'vue'
import { sui_icon_sold_out_36px } from '@shein-aidc/icon-vue2'

import CommonImage from 'public/src/pages/checkout_new/components/CommonImage.vue'
import Belt from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/Belt.vue'
import Discount from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/discount.vue'

import useBeltInfo from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useBeltInfo.js'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { isEqual } from 'public/src/pages/checkout_new/utils'
import Sensitive from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/Sensitive.vue'

const renderWidth = 2 // 2rem UI的尺寸

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  // 是否在弹窗内使用
  isInDrawer: {
    type: Boolean,
    default: false
  }
})

// --------- useMap_**** --------
const { beltInfo } = useBeltInfo(props)
const { language, checkout } = useMapState(['language', 'checkout'])

// ---------- computed ----------

/**
 * @description 是否无库存
 * @returns1 true
 * @returns2 false
 * */
const isSoldOut = computed(() => {
  const id = checkout?.outStockCarts?.id
  return isEqual(id, props.data.id)
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp__main-image {
  position: relative;
  margin-right: 0.32rem;
  &.high-priority {
    width: 2rem;
    height: 2.666rem;
  }

  .@{prefixCls}sp__sold-out {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .@{prefixCls}sp__sold-out-s {
      line-height: 20.5px;

      .@{prefixCls}sp__sold-out-sd {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .@{prefixCls}sp__discount-sub {
    font-size: 12px;
    min-width: 2.75em;
    line-height: 1.35;
    text-align: center;
    position: absolute;
    top: 4px;
    left: 0;
  }
}
</style>
