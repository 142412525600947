export function getProductStyle(
  productCode,
  styleList = [],
  globalStyle
) {
  return styleList.find(m => m[0].includes(productCode))?.[1] || globalStyle
}

export const convertColor = colorEl => {
  if (!colorEl) return ''
  
  const { colors, direction, gradient, locations } = colorEl
  
  if (gradient) {
    return `linear-gradient(${
        direction === 0 ? 'to right' : 'to bottom'
      },${colors
        .map((color, index) => `${color} ${locations[index] * 100}%`)
        .join(',')})`
  }
  
  return colors[0]
}
