<template>
  <div
    v-expose="!isShopTransit && {
      id: 'expose_no_address_module:simple',
      once: true,
    }"
    :class="[
      'checkout-no-address',
      isRealShopTransit ? 'checkout-no-address_shop' : 'checkout-no-address_home',
      {
        'checkout-no-address_base': !props.isFixedWork,
        'checkout-no-address_fixed': props.isFixedWork,
      }]"
    @click="handleAddAddressClick"
  >
    <div
      v-expose="{
        id: 'expose_constomsinfo_guide:simple',
        once: true
      }"
      class="checkout-no-address__content"
    >
      <Icon
        name="sui_icon_add_16px"
        :size="props.isFixedWork ? '12px' : '16px'"
      />
      <span class="checkout-no-address__content_text">{{ language.SHEIN_KEY_PWA_31043 }}</span>
    </div>
  </div>
</template>

<script setup name="CheckoutNoAddressAdd">
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'


import { OPERATE_TYPE, TRANSPORT_TYPE } from './../utils/constant'
import useWakeAddressWrite from './../hooks/useWakeAddressWrite.js'
import { useMapState, useMapGetters, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store.js'

const { handleAddressDrawer } = useWakeAddressWrite()

/*------ useMap*** ------*/
const { checkout, language, addressBarState } = useMapState(['checkout', 'language', 'addressBarState'])
const { noAddressScene, isShopTransit, isRealShopTransit } = useMapGetters(['noAddressScene', 'isShopTransit', 'isRealShopTransit'])
const { updateAddressBarState } = useMapMutations(['updateAddressBarState'])

/*------ props ------*/
const props = defineProps({
  isFixedWork: Boolean
})

/**
 * @description: 无地址添加地址
 * @return {*}
 */
const handleAddAddressClick = () => {
  daEventCenter.triggerNotice({
    id: 'click_no_address_module:simple',
    data: {
      entry_source: 'no_address_module'
    }
  })

  if (noAddressScene.value) {
    if (addressBarState.value.isHasBeenNoAddressDrawerShow) {
      return
    }
    updateAddressBarState({ isHasBeenNoAddressDrawerShow: true })
  }
  handleAddressDrawer({
    status: true,
    operateType: OPERATE_TYPE.ADD,
    addressType: isShopTransit.value ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME,
    addressData: isShopTransit.value ? checkout.value?.address || {} : {},
  })
}

</script>

<style lang="less" scoped>
.checkout-no-address {
  &:active {
    background: @sui_color_gray_weak2;
  }
  &_shop::before {
    background-size: 100%;
    background-image: url(/pwa_dist/images/store-border-bg-9e28e072d4.png);
  }
  &_home::before {
    background-size: 100%;
    background-image: url(/pwa_dist/images/border-bg-b2776f3817.png);
  }
  &::before {
    position: absolute;
    .left(0);
    bottom: 0;
    content: '';
    height: 3px;
    width: 100%;
  }

  &_base {
    position: relative;
    padding: 24/75rem;
    background: #fff;

    .checkout-no-address__content {
      padding: 32/75rem 0;

      &_text {
        line-height: 1;
        font-size: 14px;
      }
    }
  }

  &_fixed {
    padding: 20/75rem 24/75rem;
    background: #FFF;

    &::before {
      width: 0;
      height: 0;
    }

    .checkout-no-address__content {
      padding: 12/75rem 0;

      &_text {
        line-height: 1.4;
        font-size: 12px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8/75rem;
    border: 2/75rem dashed var(---sui_color_gray_dark3, #767676);
    background: #FFF;

    &_text {
      height: 32/75rem;
      margin-left: 8/75rem;
      color: var(---sui_color_brand, #000);
      font-weight: 700;
    }
  }
}
</style>
