var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{directives:[{name:"enterkey",rawName:"v-enterkey"},{name:"expose",rawName:"v-expose",value:({
    id: 'expose_points:simple',
    data: {
      is_choosed: _setup.isShowPrice ? 1 : 0,
      is_folded: _vm.isFolded
    }
  }),expression:"{\n    id: 'expose_points:simple',\n    data: {\n      is_choosed: isShowPrice ? 1 : 0,\n      is_folded: isFolded\n    }\n  }"}],staticClass:"checkout-virtual-assets__points-entry",attrs:{"tabindex":"0"},on:{"click":_setup.handleOpenPointDrawer}},[_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"input-icon-cell",class:{'font': false},staticStyle:{"white-space":"nowrap","vertical-align":"middle"}},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_16455)+":\n    ")]),_vm._v(" "),_c('div',{class:[_setup.prefixCls('dynamic-comp-item__coupon-right'), 'input-icon-cell']},[(_setup.point.used_point > 0)?_c('span',[_c('span',{staticClass:"use-red"},[_vm._v("\n          -"+_vm._s(_setup.point.pointPrice.amountWithSymbol)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"use-points"},[_vm._v("\n          -"+_vm._s(_setup.template(_setup.point.used_point, _setup.language.SHEIN_KEY_PWA_22192))+"\n        ")])]):_c('span',{staticClass:"use-total"},[_vm._v("\n        "+_vm._s(_setup.language.SHEIN_KEY_PWA_14955 + ":" + _setup.point.total_point)+"\n      ")]),_vm._v(" "),_c(_setup.Icon,{staticClass:"icon-arrow",attrs:{"name":!_setup.GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px',"color":"#959595"}})],1)]),_vm._v(" "),(_setup.freeShippingTipsList.length)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: 'expose_popup_notfreeshipping:simple',
    }),expression:"{\n      id: 'expose_popup_notfreeshipping:simple',\n    }"}],staticClass:"price-coupon-diff"},_vm._l((_setup.freeShippingTipsList),function(item){return _c('div',{key:item.mall_code,staticClass:"price-diff-tip"},[(_setup.isMultiMall)?_c('strong',[_vm._v(_vm._s(item.mall_name)+": ")]):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(item.not_free_shipping_tip)}})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }