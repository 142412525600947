var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-user-coupon",class:{
    'new-user-coupon_small': _vm.isSmall,
  },style:({
    ..._setup.couponConfig[_setup.couponType],
    ..._vm.config?.[_setup.couponType]
  })},[_c('div',{staticClass:"new-user-coupon__wrapper",class:{
      'new-user-coupon__wrapper_green': _setup.couponType === _setup.XtraCouponType.Shipping,
      'new-user-coupon__wrapper_ar': _setup.GB_cssRight
    }},[_c('div',{staticClass:"new-user-coupon__left"},[_c('div',{ref:"discountRef",staticClass:"new-user-coupon__discount",class:{
          'new-user-coupon__discount_ellipsis': _setup.ellipsisVisible
        }},[_vm._v("\n        "+_vm._s(_setup.discount)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"new-user-coupon__rule"},[_c('div',{staticClass:"new-user-coupon__rule-text"},[_vm._v("\n          "+_vm._s(_setup.rule)+"\n        ")]),_vm._v(" "),(_vm.infoIconVisible)?_c(_setup.InfoIcon,{staticClass:"new-user-coupon__rule-icon",attrs:{"color":_setup.couponConfig[_setup.couponType]['--newUserThemeColor']},nativeOn:{"click":function($event){return _vm.$emit('showDetailsDrawer')}}}):_vm._e()],1),_vm._v(" "),(_vm.validDaysVisible)?_c('div',{staticClass:"new-user-coupon__valid"},[_vm._v("\n        "+_vm._s(_vm.coupon?.validDayTip)+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.coupon?.privilegeCycleTip)?_c('div',{staticClass:"new-user-coupon__right"},[_vm._v("\n      "+_vm._s(_vm.coupon?.privilegeCycleTip)+"\n    ")]):_vm._e()]),_vm._v(" "),(_vm.coupon.sameCouponNum > 1)?_c('div',{staticClass:"new-user-coupon__quantity"},[_vm._v("\n    x"+_vm._s(_vm.coupon.sameCouponNum)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }