<template>
  <div v-if="tax.tip">
    <div :class="prefixCls('total__total-fee')">
      <span v-html="tax.tip"></span>
      <Icon
        name="sui_icon_doubt_16px_2"
        @click.stop="handleTotalFeeMaskTips(true)"
      />
    </div>
    <ClientOnly>
      <!-- totalFee Dialog -->
      <s-dialog
        :visible.sync="totalFeeVisible"
        :show-close="false"
        append-to-body
      >
        {{ language.SHEIN_KEY_PWA_18954 }}
        <template slot="footer">
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click.stop="handleTotalFeeMaskTips(false)"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ClientOnly from 'vue-client-only'
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'

import { Icon } from '@shein-aidc/icon-vue2'

defineProps({
  tax: {
    type: Object,
    default: () => ({})
  }
})
const totalFeeVisible = ref(false)

// -------- useMap_**** --------
const { language } = useMapState(['language'])

// ---------- computed ----------

// ---------- method ----------

const handleTotalFeeMaskTips = visible => {
  totalFeeVisible.value = visible
}
</script>
<style lang="less">
@import '../../../variables.less';

.@{prefixCls}total__total-fee {
  margin-top: 0.05333333rem;
  color: #767676;
  .font-dpr(20px);
}
</style>
