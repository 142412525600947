import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

/**
 * @description 免邮提示
 * */
export default function useFreeShipping() {
  // -------- useMap_**** --------
  const { abtState, checkout } = useMapState(['abtState', 'checkout'])

  const isCarousel = computed(() => {
    const isFreeShipping = abtState.value.placeOrderFreeShippingstatus
    const isQuickShipping = abtState.value.PlaceOrderQuickshipWordStatus
    return isFreeShipping && isQuickShipping
  })
  const visibleFsTips = computed(() => {
    return isCarousel.value
      ? !!checkout.value.placeOrderButtonRollTips?.length
      : !!checkout.value.freeShippingPlaceOrderTip
  })
  const freeShippingTips = computed(() => {
    return isCarousel.value
      ? checkout.value.placeOrderButtonRollTips
      : checkout.value.freeShippingPlaceOrderTip
  })

  const carouselData = computed(() => {
    return isCarousel.value
      ? freeShippingTips.value?.map(item => {
        return {
          text: {
            label: item.desc,
            color: '#fff'
          },
          icon: {
            name: '',
            color: ''
          },
          img: {
            url: item.icon_url || '',
            style: { width: '12px' }
          }
        }
      })
      : []
  })

  return {
    // 是否展示“免邮提示”
    visibleFsTips,
    // 是否轮播
    isCarousel,
    // 轮播内容
    carouselData,
    // 免邮提示内容
    freeShippingTips
  }
}
