import { remToPx } from '@/public/src/pages/checkout_new/utils'

// 公共类名前缀
export const prefixCls = cls => {
  const prefix = 'checkout-footer__'
  return `${prefix}${cls}`
}

export const getBtnContainerWidth = clientWidth => {
  // 总价容器宽度
  const totalPriceContainerWidth =
    clientWidth ||
    document?.getElementById(prefixCls('total-wrapper_id')).clientWidth
  // 总价容器marginTight
  const totalPriceContainerMarginRight = remToPx(20 / 75)
  // footer容器两边padding
  const footerContainerPaddingOfBothSides = remToPx((24 / 75) * 2)
  // footer容器宽度
  const footerContainerWidth = '10rem'
  const width = `calc(${footerContainerWidth} - ${
    totalPriceContainerWidth +
    totalPriceContainerMarginRight +
    footerContainerPaddingOfBothSides
  }px)`

  return { width, isContinue: totalPriceContainerWidth !== 0 }
}
