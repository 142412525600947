var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prime-list"},[_c('swiper-container',{ref:"swiperRef",staticClass:"prime-list__container",class:{
      'prime-list__container_single': !_vm.isMultStyle
    },attrs:{"init":"false"}},_vm._l((_vm.primeProductList),function(item,index){return _c('swiper-slide',{key:item.product_code,staticClass:"prime-list__item swiper-item",class:{
        'prime-list__item_scale': _vm.isReachedEnd,
        'prime-list__item_orange': _vm.showNewStyle,
        'prime-list__item_renewal':
          item.auto_renewal?.is_auto_renew_product === '1'
      },style:({
        '--club-product-name-color':
          _vm.customProductStyleList[index]?.productNameColor,
        '--club-right-color': _vm.customProductStyleList[index]?.rightColor,
        '--club-right-icon-color':
          _vm.customProductStyleList[index]?.rightIconColor,
        '--club-selling-point-color':
          _vm.customProductStyleList[index]?.sellingPointColor,
        '--club-count-down-color':
          _vm.customProductStyleList[index]?.countDownColor,
        '--club-count-down-bg-color':
          _vm.customProductStyleList[index]?.countDownBgColor,
        '--club-checkbox-color': _vm.customProductStyleList[index]?.checkboxColor,
        '--club-selected-card-border-color':
          _vm.customProductStyleList[index]?.cardCheckedBorderColor,
        '--club-price-color': _vm.customProductStyleList[index]?.priceColor,
        '--club-line-price-color':
          _vm.customProductStyleList[index]?.linePriceColor,
        '--club-product-name-bg-color':
          _vm.customProductStyleList[index]?.productNameBgColor,
        '--club-discount-label-bg-color':
          _vm.customProductStyleList[index]?.discountLabelBgColor,
        '--club-product-name-corner-bg-color':
          _vm.customProductStyleList[index]?.productNameCornerBgColor,
        '--club-history-saved-label-bg-color':
          _vm.customProductStyleList[index]?.historySavedLabelBgColor,
        '--club-discount-label-color':
          _vm.customProductStyleList[index]?.discountLabelColor,
        '--club-right-label-bg-color':
          _vm.customProductStyleList[index]?.rightLabelBgColor,
        '--club-right-label-text-color':
          _vm.customProductStyleList[index]?.rightLabelTextColor
      }),on:{"click":function($event){return _vm.onPrimeCardClick(index)}}},[_c('RightPackageCard',{attrs:{"item":item,"is-zoom-in":_vm.activeIndex === index,"is-zoom-out":_vm.lastActiveIndex === index,"is-selected":_vm.usedPrimeIndex === index,"is-auto-renewal":item.auto_renewal?.is_auto_renew_product === '1',"customStyle":_vm.customProductStyleList[index],"scale":_vm.scale,"freeGiftActivity":_vm.freeGiftActivity}}),_vm._v(" "),_c('div',{staticClass:"swiper-item__price",class:{
          'no-support': _vm.notSupport,
          'swiper-item__price-scale': _vm.activeIndex === index
        }},[_c('s-radio',{staticClass:"swiper-item__radio",attrs:{"value":_vm.usedPrimeIndex,"label":index,"theme":"icon","disabled":_vm.notSupport}}),_vm._v(" "),(
            _vm.activeIndex === index &&
              !_vm.closedPayBubbleIndexList.includes(index) &&
              item.auto_renewal?.pick_popup_show &&
              item.auto_renewal?.filteredPaymentList?.length
          )?_c('div',{staticClass:"swiper-item__bubble bubble-wrapper"},[_c('Icon',{staticClass:"bubble-wrapper__close-btn",attrs:{"name":"sui_icon_close_12px_2","size":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.onBubbleClose(index)}}}),_vm._v(" "),_c('RenewalPayMethod',{attrs:{"logoList":item.auto_renewal.filteredPaymentList.map(m => m.icon),"text":_vm.template(
                item.auto_renewal.filteredPaymentList.length,
                item.auto_renewal.pick_popup_tip
              ),"onClick":() => _vm.onPrimeCardClick(index)}})],1):_vm._e(),_vm._v(" "),_c('strong',{staticClass:"swiper-item__price-discount"},[_vm._v("\n          "+_vm._s(item.product_price_info.special_price_with_symbol)+"\n        ")]),_vm._v(" "),(+item.product_price_info.is_display_origin_price)?_c('del',{staticClass:"swiper-item__price-local"},[_vm._v("\n          "+_vm._s(item.product_price_info.price_local_with_symbol)+"\n        ")]):_vm._e(),_vm._v(" "),(item.reducePriceLabel)?_c('div',{class:[
            'swiper-item__limited',
            _vm.customProductStyleList[index]?.discountLableBgColor
              ? undefined
              : {
                'swiper-item__limitedToCoupon':
                  item.reducePriceLabel.action === 'couponList',
                'swiper-item__limited_only-renewal':
                  item.auto_renewal?.is_auto_renew_product === '1'
              }
          ],on:{"click":function($event){$event.stopPropagation();return _vm.onOfferLabelClick(item, 'page')}}},[_c('div',{staticClass:"prime-textWhiteSpace"},[(!_vm.GB_cssRight)?_c('span',[_vm._v("\n              ‪"),(item.auto_renewal?.is_auto_renew_product === '1')?_c('Icon',{attrs:{"name":"sui_icon_random_discount_16px","size":"12px","color":"#fff"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.reducePriceLabel.text))])],1):_c('span',[_c('span',[_vm._v(_vm._s(item.reducePriceLabel.text))]),_vm._v(" "),(item.auto_renewal?.is_auto_renew_product === '1')?_c('Icon',{attrs:{"name":"sui_icon_random_discount_16px","size":"12px","color":"#fff"}}):_vm._e()],1)]),_vm._v(" "),(_vm.GB_cssRight)?_c('span',{staticClass:"prime-textWhiteSpace_pice"},[_vm._v(_vm._s(item.discountValue)+"\n          ")]):_vm._e(),_vm._v(" "),(item.reducePriceLabel.action)?_c('span',{staticClass:"mr-5"},[_vm._v("\n            >\n          ")]):_vm._e()]):_vm._e()],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }