<template>
  <div 
    :class="prefixCls('no-points')"
  >
    <FlexNav>
      <template #mainRest>
        <!-- 积分颗粒展示 -->
        <template v-if="!isShowPointsDetails">
          <template v-for="(item, index) in pointsDetailsNew">
            <div
              v-if="item.type !== 'single'"
              :key="index"
              :class="prefixCls('no-points__npc-main_item')"
            >
              <img
                v-if="item.point_icon"
                :src="item.point_icon"
              />
              <span>{{ item.tail_point_tip }}</span>
            </div>
          </template>
        </template>
      </template>
      <template #icon>
        <Icon
          v-if="!!pointsDetailsNew.length"
          name="sui_icon_more_down_16px_2"
          size="16px"
          :class="[
            prefixCls('no-points__npc-icon'),
            isShowPointsDetails && prefixCls('no-points__npc-rotate')
          ]"
          @click="handleClickIcon"
        />
      </template>
    </FlexNav>
    <!-- 积分明细详情 -->
    <div
      v-if="isShowPointsDetails"
      :class="prefixCls('np-details')"
    >
      <div
        v-for="(item, index) in pointsDetailsNew"
        :key="index"
        :class="prefixCls('np-details_item')"
      >
        <div :class="prefixCls('np-details_left')">
          <img
            v-if="item.point_icon"
            :src="item.point_icon"
          />
          <span>{{ item.point_tip }} : </span>
        </div>
        <div :class="prefixCls('np-details_point')">
          {{ item.tail_point_tip }}
        </div>
      </div>
    </div>

    <div 
      v-if="pointsCoBranded" 
      :class="prefixCls('joint-card')" 
      :style="{
        '--joint-card-bg': checkout?.reward_point_info?.other_reward_point_info?.no_task_co_branded_card_bg_color
      }"
      v-html="pointsCoBranded"
    ></div>
  </div>
</template>

<script setup name="NoPoints">
import { computed, ref } from 'vue'
import FlexNav from './FlexNav.vue'

import { Icon } from '@shein-aidc/icon-vue2'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/reward_points/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
const isShowPointsDetails = ref(false)

// -------- useMapState --------

const { checkout } = useMapState(['checkout'])

// ---------- computed ----------
// 积分明细颗粒数据

const pointsDetailsNew = computed(() => {
  const { regular_reward_point_info = {} } = checkout.value?.reward_point_info || {}
  return regular_reward_point_info?.sub_reward_point_info || []
})

const pointsCoBranded = computed(() => {
  const other_reward_point_info =  checkout.value?.reward_point_info?.other_reward_point_info ?? {}
  const text = other_reward_point_info?.co_branded_card_point_tip && other_reward_point_info?.co_branded_card_point_tip.replace('</', '&nbsp;</') || ''
  return text
})


// ----------- method -----------
const handleClickIcon = () => {
  isShowPointsDetails.value = !isShowPointsDetails.value
}
</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}no-points {
  width: 100%;

  .@{prefixCls}np {
    &-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-details {
      border-radius: 4/37.5rem;
      background: var(---sui_color_gray_weak2, #f6f6f6);
      padding: 10/37.5rem 8/37.5rem;
      margin-top: 8/37.5rem;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 12px;
      font-weight: 400;

      &_item {
        display: flex;
        justify-content: space-between;
        line-height: normal;
      }

      &_.left {
        display: flex;
        align-items: center;
      }

      img {
        max-height: 15/37.5rem;
        margin-right: 6/37.5rem;
      }

      &_item + &_item {
        margin-top: 5/37.5rem;
      }
    }
  }

  &__npc {
    &-main {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 14px;
      font-weight: 700;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.6;
      flex: 1;

      span {
        margin-right: 4/37.5rem;
      }

      &_star {
        margin-right: 4/37.5rem;
        height: 20/37.5rem;
      }

      &_total {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        margin-right: 4/37.5rem;
        max-width: 7.8rem;
        line-height: 1.6;
      }

      &_item {
        border-radius: 100px;
        border: 0.5px solid var(---sui_color_gray_weak1, #e5e5e5);
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2/37.5rem 4/37.5rem;
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-shrink: 0;
        margin-left: 2/37.5rem;

        img {
          max-height: 13/37.5rem;
        }

        span {
          margin-left: 4/37.5rem;
        }
      }
    }

    &-icon {
      transform: rotate(0deg);
    }

    &-rotate {
      transform: rotate(180deg);
    }
  }

  .@{prefixCls}joint-card{
    border-radius: 2/37.5rem;
    background: var(--joint-card-bg, #FFF6F3);
    display: flex;
    padding: 4/37.5rem;
    align-items: center;
    margin-top: 8/37.5rem;
    width: fit-content;
    font-size: 12px;
    font-style: normal;
  }
}
</style>
