<script setup>
import PointDrawer from './drawer/PointDrawer.vue'
import WalletDrawer from './drawer/WalletDrawer.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapActions, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { Toast } from '@shein/sui-mobile'

const { language } = useMapState(['language'])
const { updateCheckoutData } = useMapActions(['updateCheckoutData'])

const onApplyPoint = async ({ value, isClickApplyBtn, cb }) => {
  try {
    const res = await updateCheckoutData({
      opts: {
        points: +value,
      },
      scene: 'point',
      conf: {
        loading: true
      }
    })
    const code = +res.code
    const realUsedValue = +res.info?.point.used_point
    typeof cb === 'function' && cb(true, res, realUsedValue)

    daEventCenter.triggerNotice({
      id: 'click_points_apply:simple',
      data: {
        result: code === 0 ? '1' : '0'
      }
    })

    if(code === 0) {
      if(realUsedValue > 0 && isClickApplyBtn) {
        Toast(language.value.SHEIN_KEY_PWA_16456)
      }
    }
  } catch (e) {
    cb(false, e, 0)
  }
}

const onApplyWallet = async ({ value, isClickApplyBtn, cb }) => {
  try {
    const res = await updateCheckoutData({
      opts: {
        use_wallet: isClickApplyBtn && +value > 0 ? 1 : 0,
        use_wallet_amount: +value
      },
      conf: {
        loading: true
      }
    })
    const code = +res.code
    const realUsedValue = +res.info?.wallet_balance?.walletPrice.amount
    typeof cb === 'function' && cb(true, res, realUsedValue)

    if(isClickApplyBtn) {
      daEventCenter.triggerNotice({
        id: 'click_popup_edit_wallet_apply:simple',
        data: {
          result: realUsedValue > 0 ? '1' : '0'
        }
      })
    }

    if(code === 0) {
      if(realUsedValue > 0 && isClickApplyBtn) {
        Toast(language.value.SHEIN_KEY_PWA_16490)
      }
    }
  } catch (e) {
    typeof cb === 'function' && cb(false, e, 0)
  }
}
</script>

<template>
  <div>
    <PointDrawer
      @onSubmit="onApplyPoint"
    />

    <WalletDrawer
      @onSubmit="onApplyWallet"
    />
  </div>
</template>
