var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:(!_setup.isShopTransit && {
    id: 'expose_no_address_module:simple',
    once: true,
  }),expression:"!isShopTransit && {\n    id: 'expose_no_address_module:simple',\n    once: true,\n  }"}],class:[
    'checkout-no-address',
    _setup.isRealShopTransit ? 'checkout-no-address_shop' : 'checkout-no-address_home',
    {
      'checkout-no-address_base': !_setup.props.isFixedWork,
      'checkout-no-address_fixed': _setup.props.isFixedWork,
    }],on:{"click":_setup.handleAddAddressClick}},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: 'expose_constomsinfo_guide:simple',
      once: true
    }),expression:"{\n      id: 'expose_constomsinfo_guide:simple',\n      once: true\n    }"}],staticClass:"checkout-no-address__content"},[_c(_setup.Icon,{attrs:{"name":"sui_icon_add_16px","size":_setup.props.isFixedWork ? '12px' : '16px'}}),_vm._v(" "),_c('span',{staticClass:"checkout-no-address__content_text"},[_vm._v(_vm._s(_setup.language.SHEIN_KEY_PWA_31043))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }