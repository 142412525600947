<template>
  <div 
    :class="['autoPaymenodsGuide_container', `${options.guideType}_container`]"
  >
    <div
      v-expose="{
        id: '2-42-10',
        data: {
          renew_type: props.autoType == 'prime' ? '2' : '1'
        }
      }"
      :class="['bubble-wrapper', `${options.guideType}_wrapper-active`]"
      :style="options.guideType == 'normal' && {bottom: currentXtraCouponFooterHeight}"
      @click.stop="handleBubbleClick"
    >
      <Icon
        v-if="options.iconShow"
        class="bubble-wrapper__close-btn"
        name="sui_icon_close_12px_2"
        size="10px"
        @click.stop="handleBubbleCloseBtnClick"
      />
      <RenewalPayMethod
        :logoList="logoList"
        :text="bubbleText"
        :onClick="handleBubbleClick"
      />
    </div>
  </div>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import { defineProps, ref } from 'vue'
import RenewalPayMethod from '@/public/src/pages/common/product-package/shared/RenewalPayMethod.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-42' })

const props = defineProps({
  logoList: {
    type: Array,
    default: () => []
  },
  bubbleText: {
    type: String,
    default: ''
  },
  productCode: {
    type: String,
    default: ''
  },
  options: {
    type: Object,
    default: () => ({
      guideType: 'bubble', // 气泡展示还是正常显示 bubble/normal
      iconShow: true
    })
  },
})

const emits = defineEmits(['bubbleClose', 'handlePayMethodsListDrawerShow'])
const currentXtraCouponFooterHeight = ref(0)

const handleBubbleCloseBtnClick = () => {
  emits('bubbleClose', false)
}

const handleBubbleClick = () => {
  emits('handlePayMethodsListDrawerShow', props.productCode, props.options.guideType == 'normal')
  daEventCenter.triggerNotice({
    daId: '2-42-11',
  })
}

</script>
<style lang="less" scoped>
  .autoPaymenodsGuide_container{
    z-index: -1;
  }
  .bubble-wrapper {
    z-index: 1;
    padding: 16/75rem;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
    color: #FFF;
    font-size: 10px;
    min-width: 5.65rem;
    &__close-btn {
      position: absolute;
      top: 0;
      left: 0;;
    }
  }
  .bubble_wrapper-active{
    position: relative;
    top: -0.5rem;
    left: -0.05rem;
    border-radius: 4/75rem;

    &::before {
      position: absolute;
      bottom: -8/75rem;
      left: 14/75rem;
      content: '';
      width: 0;
      height: 0;
      border-left: 10/75rem solid transparent;
      border-right: 10/75rem solid transparent;
      border-top: 10/75rem solid rgba(0, 0, 0, 0.80);
    }
  }
  .item-selected-with-tab {
    .bubble_wrapper-active {
      top: -1.1rem;
    }
  }
  .normal_wrapper-active{
    width: 100%;
  }

  .normal_container {
    width: 100%;
    z-index: 1;
    top: -0.853rem;
  }
  
</style>
