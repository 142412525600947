// 判断当前是有网还是无网
import { useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

export const useJudgeNextWork = () => {
  const { assignState } = useMapMutations([ 'assignState' ])

  /**
   * @description: 处理上下线的状态
   * @param {Boolean} status 状态 下线是true，上线是false
   * @return {*}
   */  
  const handleNetworkStatus = (status) => {
    assignState({
      isOfflineStatus: status
    })
  }

  const handleNetworkEventAdd = () => {
    // 下线
    window.addEventListener('offline', () => handleNetworkStatus(true))
    // 上线
    window.addEventListener('online', () => handleNetworkStatus(false))
  }

  const handleNetworkEventRemove = () => {
    // 下线
    window.removeEventListener('offline', () => handleNetworkStatus(true))
    // 上线
    window.removeEventListener('online', () => handleNetworkStatus(false))
  }
  return {
    handleNetworkEventAdd,
    handleNetworkEventRemove
  }
}
