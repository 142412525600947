var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: 'expose_wallet:simple',
    data: {
      is_choosed: +_setup.wallet_balance?.walletPrice?.amount > 0 ? 1 : 0,
      is_folded: _vm.isFolded,
    }
  }),expression:"{\n    id: 'expose_wallet:simple',\n    data: {\n      is_choosed: +wallet_balance?.walletPrice?.amount > 0 ? 1 : 0,\n      is_folded: isFolded,\n    }\n  }"},{name:"enterkey",rawName:"v-enterkey"}],staticClass:"checkout-virtual-assets__wallet-content",attrs:{"tabindex":"0"},on:{"click":_setup.handleOpenDrawerIntercept}},[_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"input-icon-cell",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_15109)+":\n    ")]),_vm._v(" "),_c('div',{class:[_setup.prefixCls('dynamic-comp-item__coupon-right'), 'input-icon-cell']},[(+_vm.riskType !== 2)?[(+_setup.wallet_balance?.walletPrice?.amount > 0)?_c('span',{staticClass:"use-red"},[_vm._v("\n          -"+_vm._s(_setup.wallet_balance?.walletPrice.amountWithSymbol)+"\n        ")]):(+_setup.wallet_balance?.subtractPrice?.amount > 0)?_c('span',{staticClass:"use-total wallet-tips"},[_vm._v("\n          "+_vm._s(_setup.language.SHEIN_KEY_PWA_24801)+"\n          "),(+_setup.wallet_balance.walletPrice.amount <= 0 && !_setup.isDrawerClicked)?_c('em',{staticClass:"wallet-tips-dot"}):_vm._e()]):_vm._e()]:_vm._e(),_vm._v(" "),_c(_setup.Icon,{staticClass:"icon-arrow",attrs:{"name":!_setup.GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px',"color":"#959595"}})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }