<template>
  <div class="coupon-small-wrap">
    <!-- 金👑 -->
    <template v-if="isShowCrown">
      <div
        v-if="GB_cssRight"
        class="gold-crown ar"
        :style="{
          background: `url(${PUBLIC_CDN}/pwa_dist/images/coupon/crown_ar-fd8729cd8f.png) no-repeat center center`,
          backgroundSize: '100%'
        }"
      ></div>
      <div
        v-else
        class="gold-crown"
        :style="{
          background: `url(${PUBLIC_CDN}/pwa_dist/images/coupon/crown-90911eb62d.png) no-repeat center center`,
          backgroundSize: '100%',
        }"
      ></div>
    </template>
    <div
      class="coupon-small"
      :class="[
        couponRightType === 'crown' ? 'coupon-small__crown' : couponRightType,
        inCheckout ? 'coupon-small__checkout' : '',
        SiteUID == 'pwus' && appLanguage == 'es' ? 'spanish-coupon-small' : ''
      ]"
      :style="{
        backgroundColor: couponRightType == 'crown' ? '#FBF1FF' : '#ffeef3',
        backgroundImage: couponRightType == 'crown' ? `url(${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_starBg_pwa-54ec2e00c1.png)` : ``,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderColor: couponRightType == 'crown' ? '#f1daff' : '#fdd2db',
      }"
    >
      <!-- 顶部梯形title -->
      <div
        class="title-wrap"
        :class="[couponType, couponRightType]"
      >
        <div
          class="left"
          :class="{'is-ar': GB_cssRight}"
        >
          <CouponRoundedTriangle
            position="left"
            :fillColor="leftTitleFillColor"
          />
        </div>
        <div class="title-wrap-text">
          <!-- 这里创建一个占位的文字，用来给svg, 支撑盒子宽度 -->
          <div
            v-if="couponRightType == 'crown'"
            ref="svgPos"
            class="svg-position"
          >
            {{ item.title }}
          </div>
          <template v-if="couponRightType == 'crown'">
            <svg
              ref="svgTitle"
              class="svg-title"
              width="100px"
              height="18"
              viewBox="0 0 100 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="color_text"
                  x1="3.68354"
                  y1="6.49999"
                  x2="93.3165"
                  y2="6.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.397975"
                    stop-color="#EE04C2"
                  />
                  <stop
                    offset="1"
                    stop-color="#700FED"
                  />
                </linearGradient>
              </defs>
              <text
                x="50"
                y="12"
                text-anchor="middle"
                font-size="12px"
                font-weight="bolder"
                letter-spacing="-0.01rem"
                fill="url(#color_text)"
              >
                {{ item.title }}
              </text>
            </svg>
          </template>
          <template v-else> 
            {{ item.title }}
          </template>
        </div>
        <div
          class="right"
          :class="{'is-ar': GB_cssRight}"
        >
          <CouponRoundedTriangle
            position="right"
            :fillColor="rightTitleFillColor"
          />
        </div>
      </div>
      <!-- 折扣金额 -->
      <div
        v-if="couponRightType == 'crown'"
        ref="discountInfo"
        class="discount-info"
      >
        <div
          ref="discountText"
          class="discount-wrap"
          :class="(SiteUID == 'pwus' && appLanguage == 'es') ? 'spanish-discount-wrap' : ''"
        >
          <span
            class="discount-wrap__stroke"
            :text="couponDiscountInfo"
          > {{ couponDiscountInfo }} </span>
          <span
            class="discount-wrap__text"
            :text="couponDiscountInfo"
          > {{ couponDiscountInfo }} </span>
        </div>
      </div>
      <div
        v-else
        ref="discountInfo"
        class="discount-info"
      >
        <p
          ref="discountText"
          class="discount-info__text"
          :class="(SiteUID == 'pwus' && appLanguage == 'es') ? 'spanish-discount-info__text' : ''"
        >
          {{ couponDiscountInfo }}
        </p>
      </div>
      <!-- 使用门槛 -->
      <div
        class="use-rules"
        :class="[couponRightType]"
      >
        <p
          ref="userRule" 
        >
          {{ item.otherCouponRule[0].freeCouponThresholdTip }}
        </p>
      </div>
      <div class="circle-wrap-left"></div>
      <div class="circle-wrap-right"></div>
    </div>
  </div>
</template>

<script>
const { GB_cssRight, PUBLIC_CDN, SiteUID, appLanguage } = gbCommonInfo
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { htmlDecode, template } from '@shein/common-function'
import CouponRoundedTriangle from './CouponRoundedTriangle'

export default {
  name: 'XtraSmallCoupon',
  components: {
    CouponRoundedTriangle,
  },
  props: {
    item: {
      // 优惠券 数据
      type: Object,
      default: () => {},
    },
    inCheckout: {
      type: Boolean,
      default: false
    },
    expectiveNumber: {
      type: String,
      default: '' // 空不展示
    },
    language: {
      // 多语言
      type: Object,
      default: () => {},
    },
    couponRightType: { // 券权益 // 必用券/ 普通券
      type: String,
      default: 'crown' // crown normal  券带👑 =必用券
    },
    isUsed: {
      // 券状态 unuse, used, expired
      type: String,
      default: 'unuse'
    },
    scene: {
      // 组件使用模块
      type: String,
      default: ''
    },
  },

  data() {
    return {
      GB_cssRight,
      PUBLIC_CDN,
      SiteUID,
      appLanguage,
      couponType: 'discount',
    }
  },

  computed: {
    isShowCrown() {
      return this.couponRightType == 'crown'
    },
    _item(){
      return this.souceFormat(this.item)
    },
    // 优惠信息
    couponDiscountInfo() {
      return GB_cssRight ? this.item.otherCouponRule[0].couponFaceValueTip + ' ' + this.item.otherCouponRule[0].couponFaceValue :
        this.item.otherCouponRule[0].couponFaceValue + ' ' + this.item.otherCouponRule[0].couponFaceValueTip
    },
    leftTitleFillColor() {
      let couponTitleColor = {
        crown: this.GB_cssRight ? '#f0d9ff' : '#ffd6f9',
        freeExpress: '#d6e7e0',
        discount: '#fdd2db'
      }
      return this.couponRightType == 'crown' ? '#ffd6f9' : couponTitleColor[this.couponType]
    },
    rightTitleFillColor() {
      let couponTitleColor = {
        crown: this.GB_cssRight ? '#ffd6f9' : '#f0d9ff',
        freeExpress: '#d6e7e0',
        discount: '#fdd2db'
      }
      return this.couponRightType == 'crown' ? '#f0d9ff' : couponTitleColor[this.couponType]
    }
  },

  mounted() {
    this.setSvgTitleWidth()
    this.setCouponStyle()
    this.setFontSize()
  },

  methods: {
    souceFormat,
    htmlDecode, 
    template,
    setFontSize(){
      const dom = this.$refs.discountText
      const { paddingLeft } = window.getComputedStyle(dom)
      // paddingLeft 是一个包含 px 的字符串，转化为数字
      const padding = parseFloat(paddingLeft) || 0
      if (dom.scrollWidth > dom.offsetWidth) {
        // 设置新的字体大小
        if((dom.offsetWidth - padding * 2) / dom.scrollWidth * 20 <= 12){
          dom.style.fontSize = '12px'
          dom.style.textOverflow = 'ellipsis'
          dom.style.overflowX = 'initial'
          dom.style.overflow = 'hidden'
        }else {
          dom.style.fontSize = `${(dom.offsetWidth - padding * 2) / dom.scrollWidth * 20}px`
        }
      }
    },
    setCouponStyle(){
      if (this.item.applyForType == 'DISCOUNT') {
        this.couponType = 'discount'
      } else {
        this.couponType = 'freeExpress'
      }

      let rules = this.$refs.userRule
      let discountInfo = this.$refs.discountInfo
      if (parseFloat(window.getComputedStyle(rules).height) > 20) {
        discountInfo.style.marginTop = '0.5rem'
        rules.parentElement.style.bottom = '0.2rem'
      }
    },
    setSvgTitleWidth() {
      // 动态改变svg title的宽度
      this.$nextTick(() => {
        const svgTitle = this.$refs.svgTitle
        const svgPos = this.$refs.svgPos
        if (svgTitle) {
          svgTitle.setAttribute('width', svgPos.offsetWidth)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.overText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.over2Text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title-wrap-text {
  height: 19px;
}
.coupon-small-wrap {
  width: 4.506666666666667rem;
  position: relative;
  .gold-crown {
    width: 1.0133333333333334rem;
    height: 0.96rem;
    position: absolute;
    top: -0.41rem;
    right: -0.3466666666666667rem;
    z-index: 1;
  }
}
.discount-wrap {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  &__stroke {
    padding: 0 10px;
    -webkit-text-stroke: 5px #F80BCB;
    &::before {
      content: attr(text);
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-text-stroke: 5px #700FED;
      -webkit-mask: linear-gradient(to right,transparent,black); 
    }
  }
  &__text {
    color: #FFDE9E;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    &::before {
      content: attr(text);
      position: absolute;
      color: #fffce4;
      -webkit-mask: linear-gradient(to right, transparent, #ffd89e);
    }
  }
}

.coupon-tip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.375rem;
  line-height: 0.26rem;
  background: rgba(255, 226, 167, 0.70);
  color: #F83A3A;
  text-align: center;
  text-shadow: 0px 0.9px 5.4px rgba(227, 167, 148, 0.30);
  font-size: 8/37.5rem;
  font-weight: 510;
  line-height: 2;
}

.coupon-small {
  width: 4.506666666666667rem;
  height: 2.3466666666666667rem;
  border: 2px solid #ffd6f9;
  background: #fbf1ff;
  position: relative;
  border-radius: 0.21333333333333335rem;
  mask-image:  radial-gradient( circle at 6px 49%, transparent 6px, #fff 6px);
  mask-position: -6px;
}

.spanish-coupon-small {
  height: 2.72rem;
}

.title-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -0.04666666666666667rem;
  height: 19px;
  line-height: 19px;
  background: #fdd2db;
  color: #f82854;
  text-align: center;
  font-weight: bold;
  .left,
  .right {
    width: 0.24rem;
    height: 0.48rem;
    position: absolute;
    top: 0px;
  }
  .left {
    left: -0.2rem;
  }
  .right {
    right: -0.2rem;
  }
  .left.is-ar {
    left: -0.2rem;
    transform: scale(-1, 1);
  }
  .right.is-ar {
    right: -0.2rem;
    transform: scale(-1, 1);
  }

  &.crown,
  &.crown.freeExpress {
    background: linear-gradient(to right, #ffd6f9, #f0d9ff);
  }
  &.freeExpress {
    background-color: #d6e7e0;
    color: #198055;
  }
  &.discount {
    background-color: #fdd2db;
    color: #f82854;
  }
  .title-wrap-text {
    min-width: 2.533333333333333rem;
    max-width: 3.52rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    padding: 0 2px;
  }
}

.title-wrap.coupon-expire {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 4px);
  border-radius: .08rem .08rem 0rem 0rem;
  background: linear-gradient(90deg, #FFD6F9 0%, #F0D9FF 100%);
}
.coupon-expire__text {
  margin-left: .0533rem;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  background-image: linear-gradient(96deg, #F82854 0.82%, #F80BCB 51.08%, #7729F6 100.59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discount-info {
  &__text {
    height: 0.9rem;
    line-height: 0.9rem;
    font-family: 'SF UI Display';
    font-weight: 800;
  }
  width: 100%;
  text-align: center;
  white-space: nowrap;
  margin-top: 0.6rem;
  color: #f82854;
  font-weight: 800;
  font-size: 0.5866666666666667rem;
}

.use-rules {
  p {
    box-sizing: border-box;
    padding: 0 10px;
    .over2Text();
    opacity: 0.7;
  }
  .special {
    margin-bottom: .18rem;
  }
  position: absolute;
  bottom: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  color: #f82854;
  font-size: 12px;
}

.coupon-small__crown {
  .svg-position {
    color: transparent;
  }
  .svg-title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin-top: 2px;
  }
  .discount-wrap {
    height: 0.9rem;
    line-height: 0.9rem;
  }
  .spanish-discount-wrap {
    height: 1.1rem;
    line-height: 1.1rem;
  }
  .discount-info__text {
    height: 0.9rem;
    line-height: 0.9rem;
    font-family: 'SF UI Display';
    font-weight: 800;
  }

  .use-rules {
    position: absolute;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    color: #a20fe3;
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .over2Text();
      opacity: 0.7;
    }
  }
  .circle-wrap-right {
    background: #f1daff;
  }
  .circle-wrap-left {
    background: #f1daff;
  }
}

.spanish-discount-info__text {
  height: 1.2rem;
  line-height: 1.2rem;
}

.circle-wrap-left {
  position: absolute;
  top: calc(50% - 9px);
  left: -10px;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fdd2db;
}

.circle-wrap-right {
  position: absolute;
  top: calc(50% - 9px);
  right: -10px;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fdd2db;
}

.with-use-button {
  // 有use按钮就需要确定高度和缺口位置
  height: 3.28rem;
  position: relative;
  mask-image: radial-gradient(circle at 6px 65%, transparent 6px, #fff 6px);
  mask-position: -6px;
  .circle-wrap-right {
    // 含有button的缺口样式
    top: calc(65% - 7px);
  }
  .circle-wrap-left {
    // 含有button的缺口样式
    top: calc(65% - 7px);
  }
  .payed-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.1rem;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  .topline {
    position: absolute;
    top: 0;
    width: 90%;
    height: 2px;
    background-image: linear-gradient(to right, #fdd2db 0%, #fdd2db 50%, transparent 50%);
    background-size: 6px 2px;
    background-repeat: repeat-x;
    // border-top: 2px dashed #fdd2db;
  }
  .topline.crown {
    background-image: linear-gradient(to right, #ffd7fa 0%, #ffd7fa 50%, transparent 50%);
    background-size: 6px 2px;
    background-repeat: repeat-x;
  }
  .use-btn {
    width: 4.08rem;
    height: 0.6666666666666666rem;
    background: #f82854;
    border-radius: 0.3333333333333333rem;
    color: #fff;
    text-align: center;
    line-height: 0.6666666666666666rem;
    font-size: 14px;
    font-weight: 800;
  }
  .use-btn__crown {
    background: linear-gradient(to right, #f82854, #f80bcb, #7729f6);
  }
  .use-btn__expired {
    background: #d5d5d5;
    color: rgba(255, 255, 255, 0.65);
  }
  .use-btn__used, .use-btn.use-btn__upcoming {
    background: rgba(248, 40, 84, 0.50);
    color: rgba(255,255,255, .6);
  }
  .use-btn__used.use-btn__crown {
    background: linear-gradient(90deg, #f592c5 3.8%, #f38bee 49.39%, #c58cf9 96.2%);
    color: rgba(255, 255, 255, 0.6);
  }
  .use-btn__crown.use-btn__upcoming {
    background: linear-gradient(90deg, #F592C5 3.8%, #F38BEE 49.39%, #C58CF9 96.2%);
    color: rgba(255,255,255, .6);
  }

  .use-rules {
    bottom: 1.3rem;
  }
  .use-rules.crown {
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .over2Text();
      opacity: 0.7;
    }
    position: absolute;
    bottom: 1.3rem;
    left: 50%;
    transform: translateX(-50%);
    color: #a20fe3;
  }
}
.coupon-small__checkout {
  width: 4.8rem;
  height: 1.8133333333333332rem;
  mask-image: radial-gradient(circle at 6px 50%, transparent 6px, #fff 6px);
  mask-position: -6px;
  .circle-wrap-left {
    width: 12px;
    height: 12px;
  }
  .circle-wrap-right {
    width: 12px;
    height: 12px;
  }
  .discount-info {
    margin-top: 0.3rem;
    white-space: nowrap;
  }
  .use-rules.crown {
    bottom: 0.2rem;
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .overText();
      opacity: 0.7;
    }
  }
  .discount-info__expect {
    margin-top: 0.05rem;
    white-space: nowrap;
  }
  .use-rules__expect.crown {
    bottom: 0.4rem;
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .overText();
      opacity: 0.7;
    }
  }
  .expective {
    margin-top: 0.33rem;
    p {
      padding: 0 10px;
      width: 4.8rem;
      .overText();
      font-size: 12px;
      color: #fb1b4c;
      text-align: center;
    }
  }
}
.discountText {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
</style>
