
import bsUtils from 'public/src/pages/common/handlePay/bsLib.js'
import { CHECKOUT_TYPE, PRODUCT_TYPE, PAYMENT_SCENE_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { computed, nextTick } from 'vue'
import { useStore, useCheckoutState, useMapGetters, useMapState, useMapMutations } from './store'
import { useEventBus } from './useEventBus'
import { useBsPayInstance } from './useBsPayInstance'
import { replacePaySuccessReferer, debuggerLog } from '@/public/src/pages/checkout_new/utils/index.js'
import { mergeQueryString, getQueryString } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

let storeUnSubscribe

export const bsPayEventBus = {
  // 通知创建订单
  createOrder: useEventBus('create-order'),
}

function findPayment(data, predicate = () => true) {
  const queue = [...data] // 使用队列来处理广度优先搜索
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) return node // 找到匹配的节点，返回该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return null // 没有找到匹配的节点，返回 null
}  

function findPaymentAll(data, predicate = () => true) {
  const queue = [...data] // 使用队列来处理广度优先搜索
  const result = []
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) result.push(node) // 找到匹配的节点，push该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return result // 没有找到匹配的节点，返回 null
}
const getSessionPayMethodList = (payments) => {
  // const selectedPayment = findPayment(props.paymentInfo?.payments || [], v => v.code === props.paymentInfo?.paymentSuggestion) || {}

  const channelPayments = findPaymentAll(payments, v => v.inlineChannel && +v.enabled === 1) || []
  if (!channelPayments.find(v => v.code === 'klarna-sliceitdirect')) {
    const klarnaSliceitdirect = findPayment(payments, v => v.code === 'klarna-sliceitdirect' && +v.enabled === 1) || {}
    if (klarnaSliceitdirect) channelPayments.push(klarnaSliceitdirect)
  }

  return channelPayments.map(v => v.code) || []
}

export const usePayment = () => {
  const store = useStore()
  const { checkout } = useCheckoutState(['checkout'])
  const { paymentState, language } = useMapState(['paymentState', 'language'])
  const { changePaymentLoadingState, updateCommonDialogState } = useMapMutations(['changePaymentLoadingState', 'updateCommonDialogState'])

  const { selectedPaymentInfo, isShowChannelBtn } = useMapGetters(['selectedPaymentInfo', 'isShowChannelBtn'])
  const { paymentInstance } = useBsPayInstance()

  const paymentOrderInfo = computed(() => {
    const { address, orderCurrency = {} } = checkout.value
    return {
      countryCode: address?.countryCode || '',
      country_id: address.countryId || '',
      currencyCode: orderCurrency?.code || '',
    }
  })

  const isHadDefaultPay = computed(() => checkout.value.payment_info?.paymentSuggestion)

  // ====== updatePayment start ======
  const handleUpdatePayment = () => {
    const { orderCurrency, total_price_info, address, channelSession, payment_info } = checkout.value
    const payMethodList = getSessionPayMethodList(payment_info?.payments || [])
    nextTick(() => {
      paymentInstance?.updatePayment?.({
        countryCode: address?.countryCode || '',
        orderAmount: total_price_info?.grandTotalPrice?.amount || '',
        orderCurrency: orderCurrency?.code || gbCommonInfo.currency || '',
        payMethodList,
        channelSession
      })
    })
  }
  // ====== updatePayment end ======

  // 如重复订阅需销毁
  if (storeUnSubscribe) storeUnSubscribe?.()
  storeUnSubscribe = store.subscribe((mutation, state) => {
    debuggerLog('mutation=|||====', mutation, state)
    if (mutation.type === 'checkout/checkoutComplete') {
      debuggerLog('checkoutComplete=====>>>>', state, checkout.value, mutation.payload)
      if (!mutation.payload?.status || mutation.payload?.status === 'success') {
        handleUpdatePayment()
      }
    }
  })

  const handleSelectedPayment = () => {
    debuggerLog('selectedPaymentInfo.value===>>>>==', selectedPaymentInfo.value)
    nextTick(() => {
      paymentInstance?.selectPayment?.(selectedPaymentInfo.value)
    })
  }

  const handleCreatePayment = ({ extraCb, extraPayInfo }) => {
    const { host, langPath } = window.gbCommonInfo || {}
    const order = paymentState.value.createdOrderInfo || {}
    const billno = order.relation_billno || order.billno
    const cancelUrl = `${host}${langPath}/user/orders/detail/${billno}`
    const successUrl = `${host}${langPath}/pay/result/success?billno=${billno}`
    const failureUrl = `${host}${langPath}/pay/result/fail?billno=${billno}`
    const mockSkipJump = getQueryString({ key: 'skipJump' })

    const preData = {
      cancelUrl,
      failureUrl: `${host}${langPath}/pay/result/fail?billno=${billno}`,
      sortedPriceInfo: checkout.value.sorted_price || [],
      vipOrderSource: !!checkout.value?.auto_renewal ? 1 : 0,
    }

    const handlePaySuccess = (res, extraData) => {
      extraCb?.onComplete?.(res)
      changePaymentLoadingState({
        newCheckoutLoadingType: 'pay-success'
      })
      replacePaySuccessReferer()
      debuggerLog('handlePaySuccess', res)
      setTimeout(() => {
        changePaymentLoadingState({
          newCheckoutLoadingType: ''
        })
        const mergeSuccessUrl = mergeQueryString({
          url: successUrl,
          mergeObj: Object.keys(extraData?.mergeQuery || {}).length ? extraData.mergeQuery : bsUtils.checkLtspcPaymentMethod(order.payment_method) ? {
            gatewayPayNo: res?.info?.gatewayPayNo || '',
          } : {}
        })
        debuggerLog('handlePaySuccess==mergeSuccessUrl===', mergeSuccessUrl)
        if (mockSkipJump == 1) return
        location.href = mergeSuccessUrl
      }, 1500)
    }

    const handlePayError = (res, extraData) => {
      extraCb?.onComplete?.(res)
      if (extraCb?.onError && typeof extraCb?.onError === 'function') {
        extraCb.onError(res)
        return
      }
      debuggerLog('handlePayError===', res)
      if (extraData?.paymentScene === PAYMENT_SCENE_TYPE.FRONT_TOKEN) {
        const subBillnos = order.sub_billnos?.map?.(item => item.sub_billno || '') || []
        updateCommonDialogState({
          commonErrDialog: {
            show: true,
            tip: res?.info?.show_error_msg || language.value?.SHEIN_KEY_PWA_30525,
            confirmText: language.value?.SHEIN_KEY_PWA_18132,
            onConfirm: () => {
              daEventCenter.triggerNotice({
                id: 'click_popup_changepayment:simple.beacon',
                data: { 
                  billno: order.billno, errorCode: res?.info?.error_code, uorder_id: order.relation_billno, order_id_list: subBillnos.join(',') || ''
                },
              })
              const mergeOrderDetailUrl = getOrderDetailLink({
                extraParams: {
                  showpaymethods: 1,
                }
              })
              if (mockSkipJump == 1) return
              window.location.href = mergeOrderDetailUrl
            },
            onCloseDialog: (evt = '') => {
              if (evt === 'close-from-mask') {
                daEventCenter.triggerNotice({
                  id: 'click_payfailresaonclose:simple.beacon',
                  data: { 
                    billno: order.billno, errorCode: res?.info?.error_code, uorder_id: order.relation_billno, order_id_list: subBillnos.join(',') || ''
                  },
                })
                if (mockSkipJump == 1) return
                const mergeOrderDetailUrl = getOrderDetailLink()
                window.location.href = mergeOrderDetailUrl
              }
            }
          }
        })
        daEventCenter.triggerNotice({
          id: 'expose_popup_payfailresaon:simple',
          data: {
            billno,
            error_code: res.info?.error_code || res?.status || '',
            isDetailPage: location.pathname.includes('/detail/')
          }
        })
      } else {
        if (mockSkipJump == 1) return
        const failUrl = mergeQueryString({
          url: failureUrl,
          mergeObj: {
            code: res?.info?.error_code || '',
            paymentCode: res?.info?.paymentCode || '',
            ...(extraData?.mergeQuery || {})
          }
        })
        replacePaySuccessReferer()
        location.href = failUrl
      }
    }

    const handlePayFail = res => {
      extraCb?.onComplete?.(res)
      if (extraCb?.onFail && typeof extraCb?.onFail === 'function') {
        extraCb.onFail(res)
        return
      }
      debuggerLog('handlePayFail', res)
      if (mockSkipJump == 1) return
      location.href = cancelUrl
    }

    const handlePayCancel = () => {
      extraCb?.onComplete?.()
      debuggerLog('handlePayCancel===')
      if (mockSkipJump == 1) return
      location.href = cancelUrl
    }

    debuggerLog('createOrder success', paymentState.value, order, extraPayInfo)
    if (!extraCb?.successCb) {
      const extraParams = {
        ...preData,
        ...(extraPayInfo || {})
      }
      // 历史逻辑兼容，跳转优化->点浏览器返回时回到订单详情页
      const { isApplePay, isGooglePay, isPrePay, isPreTokenPay } = paymentInstance.getPaymentProcessInfo({
        paymentInfo: selectedPaymentInfo.value,
        extraParams
      })
      if (!isApplePay && !isGooglePay && !isPrePay && !isPreTokenPay && !isShowChannelBtn.value) {
        history.replaceState(history.state ?? null, null, cancelUrl)
      }
      paymentInstance.createPayment({
        // 新逻辑兼容处理
        orderInfo: order,
        paymentInfo: selectedPaymentInfo.value,
        extraParams,
        payCheckoutType: CHECKOUT_TYPE.NORMAL,
        payProductType: PRODUCT_TYPE.NORMAL,
        onRequestCompleted: () => {
          debuggerLog('onRequestCompleted==支付接口调用完成===')
          // 历史逻辑兼容，跳转优化->点浏览器返回时回到订单详情页
          if (!isPrePay && !isPreTokenPay) {
            history.replaceState(history.state ?? null, null, cancelUrl)
          }
        },
        onSuccess: handlePaySuccess,
        onError: handlePayError,
        onFail: handlePayFail,
        onCancel: handlePayCancel,
        initHandler: () => {
          // updateState('newCheckoutLoadingType', 'order-to-pay')
        },
        paymentLoadingAction: ({ show }) => {
          changePaymentLoadingState({
            newCheckoutLoadingType: show ? 'order-to-pay' : ''
          })
        },
      })
    }
  }

  const getOrderDetailLink = ({ billno, extraParams = {} } = {}) => {
    const { host, langPath } = window.gbCommonInfo || {}
    const order = paymentState.value.createdOrderInfo || {}
    const link = `${host}${langPath}/user/orders/detail/${billno || order.relation_billno || order.billno}`
    return mergeQueryString({
      url: link,
      mergeObj: extraParams || {}
    })
  }

  const getPaySuccessLink = ({ billno, extraParams = {} } = {}) => {
    const { host, langPath } = window.gbCommonInfo || {}
    const order = paymentState.value.createdOrderInfo || {}
    const link = `${host}${langPath}/pay/result/success?billno=${billno || order.relation_billno || order.billno}`
    return mergeQueryString({
      url: link,
      mergeObj: extraParams || {}
    })
  }

  return {
    paymentOrderInfo,
    handleSelectedPayment,
    handleCreatePayment,
    getOrderDetailLink,
    getPaySuccessLink,
    isHadDefaultPay,
  }
}
