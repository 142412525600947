import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { loadTdLibJs } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'
import axios from 'axios'
import ltspcPayments from 'public/src/pages/checkout_new/utils/ltspc_payments.js'
import { SIMetric, SILogger } from 'public/src/pages/common/monitor/index.js'
import errorReport from 'public/src/pages/common/handlePay/log'


const { SiteUID, lang, currency, host, langPath, PLATFORM, ENCRYPT_KEY_URL, NODE_SERVER_ENV } = typeof window !== 'undefined' ? gbCommonInfo : {}
// const gbCommonInfo = typeof window !== 'undefined' ? window.gbCommonInfo : {}

async function _getPublicKey({ countryCode } = {}) {
  const res = await schttp({
    method: 'get',
    url: '/api/ocp/pubKey/get',
    params: {
      countryCode
    }
  }).catch(() => {})
  return res || {}
}

async function updateLtspcPciEnv (params = { currency: '' }) {
  const ugid = UserInfoManager?.getSync?.({ key: 'ugid' })

  const [
    { AuthToken: TokenAes } = {},
    { updateCs: ltspcCsrf } = {}
  ] = await Promise.all([
    UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo'  }),
    schttp({
      url: '/ltspc/updateCs',
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
  ])

  return schttp({
    url: '/ltspc/transformer/init',
    method: 'POST',
    data: {
      SiteUID,
      lang,
      AppCurrency: params?.currency || currency,
      TokenAes,
      _csrf: ltspcCsrf,
      originSite: host + langPath,
      siteFrom: PLATFORM === 'PWA' ? 'pwa' : 'pc',
      ugid
    }
  })
}

function initRiskSdk () {
  if (typeof window == 'undefined') return
  function initCybersource () {
    if (window.GB_cybersource_uuid) return
    window.TPM?.run({
      marketing: 'Cybersource',
      method: '_loadCybersource'
    })
  }

  function forterLoad() {
    // 获取forterSiteId和forterDeviceId
    window?.TPM?.run({
      marketing: 'Forter',
      method: '_loadFort'
    })
    document?.addEventListener('ftr:tokenReady', function (evt) {
      window.forterDeviceId = evt.detail || window.forterDeviceId || ''
    })
  }

  function initRiskifiedDeviceId() {
    let riskifiedInfo = window.TPM?.runImmediate({
      marketing: 'Riskified',
      method: '_getRiskSessionId'
    })
    if (riskifiedInfo) {
      window.riskifiedDeviceId = riskifiedInfo?.datas || ''
    }
  }

  loadTdLibJs()
  initCybersource()
  forterLoad()
  initRiskifiedDeviceId()
}

function getRiskInfo () {
  return {
    forterDeviceFingerprintID: window.forterDeviceId || '',
    forterSiteId: window.forterSiteId || '',
    riskifiedDeviceFingerprintID: window.isRiskifiedLoaded ? window.riskifiedDeviceId || '' : '',
    deviceFingerId: window.GB_cybersource_uuid || '',
    blackbox: window._fmOpt?.__blackbox || ''
  }
}

async function getPubKey ({ countryCode = '' } = {}) {
  const countryData = UserInfoManager.getSync({ key: 'Country', actionType: 'getCountryCode' })
  const { JSEncrypt = function () {} } = await import('jsencrypt').catch(() => {})
  let encryptValid = false
  let publicKeyObj = {}
  let encryptObj = null
  // console.log('getPubKey')
  encryptValid = false

  const localcountry = countryCode || countryData?.countryCode || ''
  const url = ENCRYPT_KEY_URL + '/pubkey/'

  try {
    const res = NODE_SERVER_ENV === 'localhost' ? await _getPublicKey({ countryCode: localcountry }) :  await axios.get(url, { params: { localcountry } })
    const data = NODE_SERVER_ENV === 'localhost' ? res : res?.data
    if (data) {
      publicKeyObj = data
      encryptObj = JSEncrypt && new JSEncrypt() || {}
      encryptObj?.setPublicKey?.(publicKeyObj?.key || '')
      encryptValid = true
    }
  } catch (error) {
    console.error(error)
  }
  return {
    encryptValid,
    publicKeyObj,
    encryptObj
  }
}

async function getAdyenKey () {
  const data = await schttp({
    url: '/ltspc/api/pay/adyenKey/get'
  })
  return data?.info?.key || ''
}

/**
 * 统一支付接口
 *
 * @param {*} { data = {}, params = {} }
 * @return {*} 
 */
async function unifiedPayApi({ data = {}, params = {} }) {
  return schttp({
    url: '/api/pay/unifiedPay/create',
    method: 'POST',
    params,
    data,
  })
}
/**
 * ltspc 统一支付接口
 *
 * @param {*} { data = {}, params = {} }
 * @return {*} 
 */
async function ltspcUnifiedPayApi({ data = {}, params = {} }) {
  return schttp({
    url: '/ltspc/pay/unPay/unifiedPay',
    method: 'POST',
    params,
    data,
  })
}

function sendKafkaApi(data) {
  return schttp({
    url: '/api/pay/sendKafka/create',
    method: 'POST',
    data
  })
}

function checkLtspcPaymentMethod(paymentCode = '') {
  const allPciPayments = Object.values(ltspcPayments.payCodes)
  return allPciPayments.includes(paymentCode)
}

async function getUpdateCs() {
  return schttp({
    url: '/ltspc/updateCs'
  }).then(res => {
    if (res && res.updateCs) {
      // ltspcCsrf = res.updateCs
      return res.updateCs
    }
    return ''
  }).catch(() => '')
}

function initPciParams({ siteUID, currency, lang, host, langPath, checkoutScene, useInline, inlineUrl }) {
  return {
    SiteUID: siteUID || gbCommonInfo.SiteUID,
    AppCurrency: currency || gbCommonInfo.currency,
    Token: '',
    lang: lang || gbCommonInfo.lang,
    siteFrom: 'pwa',
    originSite: (host + langPath) || (gbCommonInfo.host + gbCommonInfo.langPath),
    fromCheckout: location.href.indexOf('checkout') > -1,
    _csrf: '',
    ugid: UserInfoManager?.getSync?.({ key: 'ugid' }),
    checkoutScene: checkoutScene || '',
    useInline: useInline == 1 ? 1 : 0,
    inlineUrl,
  }
}

async function bsLtspcInit ({ payCode, siteUID, currency, lang: bsLang, host, langPath, checkoutScene: scene, useInline: isInline, inlineUrl: returnUrl } = {}) {
  console.time('window.ltspcInit time')
  const allPciPayments = Object.values(ltspcPayments.payCodes)
  let res = { url: '' }
  try {
    if (!allPciPayments.includes(payCode)) { return res }
    const pciFormParams = initPciParams({ siteUID, currency, lang: bsLang, host, langPath, checkoutScene: scene, useInline: isInline, inlineUrl: returnUrl })

    const [ltspcCsrf, tokenInfo] = await Promise.all([getUpdateCs(), UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo'  })])
    // 这个方法会先判断本地是否有缓存，有的话直接返回，没有再异步获取
    const TokenAes = tokenInfo?.AuthToken
    // eslint-disable-next-line  require-atomic-updates
    pciFormParams._csrf = ltspcCsrf
    const {
      SiteUID,
      AppCurrency,
      lang,
      siteFrom,
      originSite,
      _csrf,
      ugid,
      useInline,
      inlineUrl,
      checkoutScene,
    } = pciFormParams
    const data = {
      SiteUID,
      //appLanguage,
      lang,
      AppCurrency,
      payCode,
      TokenAes,
      _csrf,
      originSite,
      siteFrom,
      ugid,
      useInline,
      inlineUrl,
      checkoutScene,
    }
    console.log('zjzj ltspcInit data', JSON.stringify(data))
    const result = await schttp({
      url: '/ltspc/transformer/checkout_init',
      method: 'POST',
      data
    })

    console.log('zjzj ltspcInit result', JSON.stringify(result))

    //如果失败，则走默认的jumpPCI
    if (TokenAes && result?.code == 0 && result?.info?.url) {
      res.url = result.info.url
    } else {
      // apiReport.report({
      //   apiPath: 'ltspc-init-fail',
      //   info: result,
      //   paymentMethod: payCode
      // })
    }
    console.log('zjzj ltspcInit res', res)
  } catch (e) {
    console.error('window.ltspcInit Error', e)
    res = { url: '' }
  } finally {
    console.timeEnd('window.ltspcInit time')
    return res
  }
}

function monitorManager () {
  return {
    SILogger,
    SIMetric
  }
}

export default {
  updateLtspcPciEnv,
  initRiskSdk,
  getRiskInfo,
  getPubKey,
  getAdyenKey,
  unifiedPayApi,
  ltspcUnifiedPayApi,
  sendKafkaApi,
  checkLtspcPaymentMethod,
  bsLtspcInit,
  monitorManager,
  sendPaymentError: errorReport.sendPaymentError.bind(errorReport),
}
