<template>
  <div :class="prefixCls('price-container')">
    <div :class="prefixCls('price-section')">
      <div :class="[prefixCls('ps-main')]">
        <!-- 有付费会员标签，不展示其他标签 -->
        <img
          v-if="!!renderData.primeGoodImgUrl"
          :class="prefixCls('ps__member-tag')"
          :src="renderData.primeGoodImgUrl"
          alt=""
        />
        <template v-else>
          <i
            v-if="!!otherIconInfo.icon"
            class="suiiconfont"
            :class="[otherIconInfo.icon, otherIconInfo.color]"
          ></i>
          <Icon
            v-else-if="isBrandFlash"
            name="sui_icon_brandsale_15px"
            size="16px"
            color="#FFCD94"
          />
        </template>
        <em
          v-if="isVip"
          class="vip-exclusive-tag_color-black"
          :class="[
            GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag'
          ]"
        ></em>

        <!-- 价格 -->
        <div :class="[prefixCls('ps-detail')]">
          <span
            :class="prefixCls('ps-unit')"
            :style="{ color: price.color }"
          >
            {{ price.txt }}
          </span>
          <span
            v-if="delPrice.txt"
            :style="{ color: delPrice.color }"
            :class="prefixCls('ps-delete')"
          >
            <del :style="{ textDecoration: delPrice.textDecoration }">
              {{ delPrice.txt }}
            </del>
            {{ delPrice.description }}
          </span>
          <s-popover
            v-if="!!delPrice.tip.imgUrl && !!delPrice.tip.label"
            :append-to-body="true"
            :prop-style="{
              width: '6.4rem'
            }"
            placemen="bottom"
            show-close-icon
            :content="delPrice.tip.desc"
          >
            <Icon
              slot="reference"
              :name="delPrice.tip.imgUrl"
              size="12px"
              color="#959595"
            />
            <div>
              {{ delPrice.tip.label }}
            </div>
          </s-popover>
        </div>
      </div>
      <div
        v-if="lowestPrice.txt"
        :class="[prefixCls('ps-lowest')]"
        :style="lowestPrice.style"
      >
        <span :style="{ textDecoration: lowestPrice.textDecoration }">
          {{ lowestPrice.txt }}
        </span>
        {{ lowestPrice.description }}
      </div>
    </div>
    <QuantityInput
      v-if="isQuantityEditable"
      :data="data || {}"
    />

    <div
      v-else
      :class="[
        prefixCls('ps-quantity'),
        { 'quantity-welfare': +(data?.quantity >= 2) }
      ]"
    >
      <span class="value">x{{ data?.quantity }}</span>
    </div>
  </div>
</template>
<script setup name="ProductPriceSection">
import { computed } from 'vue'
import { Popover as SPopover } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import usePrice from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/usePrice.js'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import QuantityInput from '../../common/QuantityInput.vue'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

const { GB_cssRight } = useMapState(['GB_cssRight'])

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  // 数量是否可编辑
  quantityEditable: {
    type: Boolean,
    default: true
  }
})

// --------- useMap_**** --------
const { price } = usePrice(props?.data)
const { isGift, isAdditionGood, isOutStock } = useAttribute(props?.data)

// ---------- computed ----------
const isQuantityEditable = computed(() => {
  if (!props.quantityEditable) {
    return false
  } else {
    return !(isGift.value || isAdditionGood.value || isOutStock.value)
  }
})

/**
 * @description 渲染数据
 * @returns {primeGoodImgUrl} 付费会员icon
 * */
const renderData = computed(() => {
  const {
    data: { product, aggregateProductBusiness }
  } = props
  return {
    primeGoodImgUrl: aggregateProductBusiness?.primeGoodImgUrl || '',
    goodsSn: product?.goods_sn || ''
  }
})

// 非付费会员icon信息
const otherIconInfo = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { type_id }
    }
  } = props
  let res = { icon: '', color: '' }
  switch (type_id) {
    case 10:
    case 11:
      res = {
        icon: 'sui_icon_flashsale_15px',
        color: 'iconfont-sui_color_flash'
      }
      break
    case 8:
      res = {
        icon: 'sui_icon_phone_15px',
        color: 'iconfont-sui_color_promo'
      }
      break
    case 3:
      res = {
        icon: 'sui_icon_time_15px',
        color: 'iconfont-sui_color_promo'
      }
      break
    default:
  }

  return res
})

const isBrandFlash = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { flash_type }
    }
  } = props
  return flash_type === 6
})

/**
 * @description 划线格信息
 * @returns {txt} 文本
 * @returns {color} 颜色
 * */
const delPrice = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { priceData }
    }
  } = props
  const {
    price,
    color = '',
    description = '',
    crossed
  } = priceData?.originalPrice || {}
  const { amountWithSymbol = '', tip } = price || {}
  let res = {
    txt: amountWithSymbol,
    description,
    tip: {
      desc: tip?.desc || '',
      imgUrl: tip?.imgUrl || '',
      label: description || ''
    },
    color,
    textDecoration: crossed === '1' ? 'line-through' : 'none'
  }
  return res
})

/**
 * @description 最低价信息
 * @returns {txt} 价格
 * @returns {description} 价格后的描述
 * @returns {style} 样式
 * */
const lowestPrice = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { priceData }
    }
  } = props
  const {
    price,
    color = '',
    description = '',
    crossed
  } = priceData?.lowestPrice || {}
  const { amountWithSymbol = '' } = price || {}
  let res = {
    txt: amountWithSymbol,
    description,
    style: { color },
    textDecoration: crossed === '1' ? 'line-through' : 'none'
  }
  return res
})

const isVip = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { type_id }
    }
  } = props
  return type_id === 12
})
</script>

<style lang="less">
@import '../../../variables.less';

@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';
.@{prefixCls}price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
  .font-dpr(24px);
  margin-top: 0.16rem;

  .@{prefixCls}price-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 1.974rem);
    flex: 1;
  }

  .@{prefixCls}ps-main {
    display: inline-flex;
    align-items: center;
    .margin-r(12/75rem);
    width: 100%;

    .@{prefixCls}ps {
      &__member-tag {
        width: 14px;
        height: 14px;
      }
    }

    .vip-exclusive-tag {
      background-image: url(@s3_icon);
    }

    .vip-exclusive-tag__ar {
      background-image: url(@s3_icon_ar);
      vertical-align: -0.026667rem;
    }
  }

  .@{prefixCls}ps-quantity {
    white-space: nowrap;
    .value {
      font-weight: 400;
      .font-dpr(24px);
      color: #333333;
    }

    &.quantity-welfare {
      .value {
        color: @sui_color_welfare;
      }
    }
  }

  .@{prefixCls}ps-detail {
    color: #222222;
    .font-dpr(28px);
    font-weight: bold;
    line-height: 0.453rem;
    width: 100%;
    min-width: 0;
    display: inline-flex;
    flex-shrink: 0;
    align-items: baseline;

    .@{prefixCls}ps-unit {
      word-break: break-all;
      flex-shrink: 0;
      max-width: 100%;
    }

    .@{prefixCls}ps-delete {
      color: @sui_color_gray_dark3;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      .padding-l(4/75rem);
    }
  }

  .@{prefixCls}ps-lowest {
    color: @sui_color_gray_dark3;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    .padding-l(4/75rem);
  }
}
</style>
