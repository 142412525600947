<template>
  <!-- 价格、税费相关的信息 -->
  <div :class="prefixCls('price-tax__container')">
    <!-- total字符 -->
    <span
      v-if="!abtState.orderConfirmDiscountSwitch"
      ref="totalTextRef"
      :class="prefixCls('pt__total-text')"
    >
      {{ language.SHEIN_KEY_PWA_15097 }}
    </span>
    <!-- 价格、税费、icon -->
    <div
      :class="[
        prefixCls('pt__price'),
        isShowVerticalLayout && prefixCls('pt__price-vertical')
      ]"
    >
      <!-- price -->
      <span
        v-if="price.visible"
        ref="totalPriceRef"
        :class="[
          prefixCls('pt__price-total'),
          { [prefixCls('pt__price-total_discount')]: isDiscountTotalColor }
        ]"
      >
        <!-- 文本大小自适应 -->
        <SAdapterText
          v-if="isShowVerticalLayout"
          min-size="14"
          :text="price.txt"
        />
        <span v-else>{{ price.txt }}</span>
      </span>
      <span :class="prefixCls('pt__price-tax-icon')">
        <!-- tax税费 -->
        <span
          v-if="!!tax.visible"
          ref="totalTaxRef"
          :class="prefixCls('pt__price-ti_tax')"
        >
          <!-- 文本大小自适应 -->
          <SAdapterText
            v-if="isShowVerticalLayout"
            min-size="10"
            :text="`+ ${tax.txt}`"
          />
          <span v-else>+ {{ tax.txt }}</span>
        </span>
        <!-- icon -->
        <Icon
          v-if="abtState.isShowSaveAmount && !isNotShowPriceDetail"
          :class="[
            prefixCls('pt__price-ti_icon'),
            isShow ? 'sui_icon_more_down' : 'sui_icon_more_up'
          ]"
          name="sui_icon_more_down_12px"
          size="12px"
        />
      </span>
    </div>
  </div>
</template>

<script setup name="PriceTax">
import { computed, watch, ref, nextTick } from 'vue'

import { Icon } from '@shein-aidc/icon-vue2'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  tax: {
    type: Object,
    default: () => ({})
  },
  // 不展示价格明细drawer
  isNotShowPriceDetail: {
    type: Boolean,
    default: false
  }
})

// 竖向布局
const isShowVerticalLayout = ref(false)
const totalTextRef = ref(null)
const totalPriceRef = ref(null)
const totalTaxRef = ref(null)

// -------- useMap_**** --------
const { abtState, checkout, footerState, language } = useMapState([
  'abtState',
  'checkout',
  'footerState',
  'language'
])

// ---------- computed ----------

// 展示折扣色
const isDiscountTotalColor = computed(() => {
  return !!checkout.value.saved_total_price_text
})

// 价格
const price = computed(() => {
  const installmentTotalPrice = footerState.value.installmentTotalPrice
  const amountWithSymbol =
    checkout.value?.total_price_info?.grandTotalPrice?.amountWithSymbol || ''
  // 新版本按钮布局样式
  const txt = installmentTotalPrice || amountWithSymbol
  return {
    visible: !!txt,
    txt
  }
})

// ----------- watch -----------
watch(
  () => price?.value || props.tax?.value,
  (newVal, oldVal) => {
    if (newVal?.txt !== oldVal?.txt) {
      computedTotalContentWidth()
    }
  }
)

// ----------- method -----------
const computedTotalContentWidth = () => {
  nextTick(() => {
    // 根元素的px大小
    const rootFontSize = +window
      ?.getComputedStyle(document.documentElement)
      .fontSize.replace('px', '')
    // 左侧total文案的宽度
    const leftTotalTextWidth = abtState.value.orderConfirmDiscountSwitch
      ? 0
      : totalTextRef.value?.offsetWidth || 30

    // 右侧价格+税费+箭头的最大宽度，设计稿的最大宽度170 间距4 170-4=166
    const totalContentMaxWidth =
      (166 / 37.5) * rootFontSize - leftTotalTextWidth
    // 原来价格的文案宽度
    const originTotalPriceWidth = totalPriceRef.value?.offsetWidth || 0
    // 原来税费的文案宽度
    const originTotalTaxWidth = totalTaxRef.value?.offsetWidth || 0
    // icon的宽度 + 左间距2
    const otherWidth = 12 + (2 / 37.5) * rootFontSize
    // 将价格、税费、icon横排起来，总共的宽度
    const sumTotalWidth =
      originTotalPriceWidth + originTotalTaxWidth + otherWidth

    // 是否需要换行展示并缩小字号的逻辑是：将价格、税费、icon横排起来计算总共宽度，将其值与右侧文案最大值totalContentMaxWidth进行比较
    if (sumTotalWidth > totalContentMaxWidth) {
      isShowVerticalLayout.value = true
    }
  })
}
</script>
<style lang="less">
@import '../../../variables.less';

.@{prefixCls}price-tax__container {
  display: flex;
  width: 100%;
  white-space: nowrap;
  word-break: keep-all;
  .font-dpr(28px);

  .@{prefixCls}pt {
    &__total-text {
      margin-right: 8/75rem;
    }

    &__price {
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      width: 100%;

      &-total {
        .font-dpr(34px);
        font-weight: bold;
        margin-right: 8/75rem;

        &_discount {
          color: @sui_color_discount;
        }
      }

      &-tax-icon {
        display: inline-flex;
        align-items: center;
      }

      &-ti_tax {
        .font-dpr(28px);
        font-weight: bold;
      }

      &-ti_icon {
        margin-left: 4/75rem;
        &.sui_icon_more_up {
          transform: rotate(180deg);
        }
      }
    }

    &__price-vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
