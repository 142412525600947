var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{directives:[{name:"enterkey",rawName:"v-enterkey"},{name:"expose",rawName:"v-expose",value:({
    id: 'expose_giftcard:simple',
    data: {
      is_choosed: _setup.isShowGifCardAmount ? 1 : 0,
      is_folded: _vm.isFolded,
    }
  }),expression:"{\n    id: 'expose_giftcard:simple',\n    data: {\n      is_choosed: isShowGifCardAmount ? 1 : 0,\n      is_folded: isFolded,\n    }\n  }"}],class:[_setup.prefixCls('gift-card')],attrs:{"tabindex":"0"},on:{"click":_setup.goGiftCardPage}},[_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"input-icon-cell",staticStyle:{"white-space":"nowrap","vertical-align":"middle"}},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_15846)+":\n    ")]),_vm._v(" "),_c('div',{class:[_setup.prefixCls('gift-card__discount'), 'input-icon-cell']},[(_setup.giftcard?.giftCardUsedPrice)?_c('code',[_c('span',{class:_setup.prefixCls('gift-card__discount__currency-used')},[_vm._v("-"+_vm._s(_setup.giftcard.giftCardUsedPrice.amountWithSymbol))]),_c('br'),_vm._v(" "),_c('span',{class:_setup.prefixCls('gift-card__discount__balance')},[_vm._v("\n          "+_vm._s(_setup.language.SHEIN_KEY_PWA_15717)+":"+_vm._s(_setup.giftcard.giftcardBalanceUSDPrice.amountWithSymbol))])]):_vm._e(),_vm._v(" "),_c(_setup.Icon,{staticClass:"icon-arrow",attrs:{"name":!_setup.GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px',"color":"#959595"}})],1)]),_vm._v(" "),_c(_setup.ClientOnly,[_c(_setup.GiftCardDrawer,{attrs:{"cardusedlist":_setup.giftCardUsedList},on:{"update:cardusedlist":function($event){_setup.giftCardUsedList=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }