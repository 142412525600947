import { useMapGetters } from 'public/src/pages/checkout_new/hooks/store'
import { CardType } from '../utils/constant.js'

export default function useQuickPayments() {
  
  const { 
    paymentInfo,
    selectedPaymentInfo
  } = useMapGetters([
    'paymentInfo',
    'selectedPaymentInfo'
  ])

  // 判断该支付方式是否支持快捷支付
  const isQuickPayment = ({ cardType, payment }) => {
    const supportKey = {
      [CardType.SAVE]: 'support_save_card_quick',
      [CardType.PRIME]: 'support_prime_quick',
    }
    const isSupport = +payment[supportKey[cardType]] === 1

    // 默认签约
    let isSignedUp = true
    if (payment.code === selectedPaymentInfo.value.code && payment.needToSign === '1') {
      isSignedUp = selectedPaymentInfo.value.signupFlag === '1'
    }

    // 非同质化支付方式 && 支持快捷支付 && 已签约
    return payment.code && isSupport && isSignedUp
  }

  // 过滤弹窗里的快捷支付方式
  const filterQuickPayments = ({ cardType, payments }) => {
    return payments?.filter(payment => isQuickPayment({ cardType, payment }))
  }

  // 过滤气泡上的快捷支付方式
  const filterBubbleQuickPayments = ({ cardType, payments }) => {
    return payments.filter(quickPayment => {
      let originPaymentInfo = paymentInfo.value.payments.find(payment => payment.code === quickPayment.code) || {}
      if (quickPayment.code === selectedPaymentInfo.value.code) {
        originPaymentInfo = {
          ...originPaymentInfo,
          ...(selectedPaymentInfo.value || {})
        }
      }
      return isQuickPayment({ cardType, payment: originPaymentInfo })
    })
  }

  return { 
    isQuickPayment, 
    filterQuickPayments,
    filterBubbleQuickPayments 
  }
}
