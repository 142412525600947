<template>
  <li
    v-enterkey
    v-expose="{
      id: 'expose_points:simple',
      data: {
        is_choosed: isShowPrice ? 1 : 0,
        is_folded: isFolded
      }
    }"
    class="checkout-virtual-assets__points-entry"
    tabindex="0"
    @click="handleOpenPointDrawer"
  >
    <div class="input-icon">
      <span
        style="white-space: nowrap; vertical-align: middle;"
        :class="{'font': false}"
        class="input-icon-cell"
      >
        {{ language.SHEIN_KEY_PWA_16455 }}:
      </span>
      <div :class="[prefixCls('dynamic-comp-item__coupon-right'), 'input-icon-cell']">
        <span v-if="point.used_point > 0">
          <span class="use-red">
            -{{ point.pointPrice.amountWithSymbol }}
          </span>
          <span class="use-points">
            -{{ template(point.used_point, language.SHEIN_KEY_PWA_22192) }}
          </span>
        </span>

        <span
          v-else
          class="use-total"
        >
          {{ language.SHEIN_KEY_PWA_14955 + ":" + point.total_point }}
        </span>

        <Icon
          :name="!GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px'"
          class="icon-arrow"
          color="#959595"
        />
      </div>
    </div>

    <div
      v-if="freeShippingTipsList.length"
      v-expose="{
        id: 'expose_popup_notfreeshipping:simple',
      }"
      class="price-coupon-diff"
    >
      <div
        v-for="item in freeShippingTipsList"
        :key="item.mall_code"
        class="price-diff-tip"
      >
        <strong v-if="isMultiMall">{{ item.mall_name }}: </strong>
        <span v-html="item.not_free_shipping_tip"></span>
      </div>
    </div>
  </li>
</template>

<script setup name="AssetsPoints">
import { computed } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { template } from '@shein/common-function'
import { useFreeShippingTipsList, AssetType } from '../hooks/index'
import { prefixCls }  from '../utils'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { Icon } from '@shein-aidc/icon-vue2'

const freeShippingTipsList = useFreeShippingTipsList(AssetType.POINT)

const props = defineProps({
  isFolded: {
    type: Number,
    default: 1,
  },
})

const { point, isMultiMall, language, GB_cssRight } = useMapState(['checkout.point', 'isMultiMall', 'language', 'GB_cssRight'])
const { updateVirtualAssetsPopupState } = useMapMutations([
  'updateVirtualAssetsPopupState'
])

const isShowPrice = computed(() => {
  return point.value?.pointPrice?.amount >= 0
})

const handleOpenPointDrawer = () => {
  updateVirtualAssetsPopupState({
    pointDrawer: {
      visible: true
    }
  })

  daEventCenter.triggerNotice({
    id: 'click_points:simple',
    data: {
      is_choosed: isShowPrice.value ? 1 : 0,
      is_folded: props.isFolded,
    },
  })
  daEventCenter.triggerNotice({
    id: 'expose_popup_edit_points:simple',
    data: {
      button_included: + point.value.pointPrice.amount > 0 && point.value.pointPrice?.amountWithSymbol ? 'remove' : 'apply'
    }
  })
}
</script>

<style lang="less">
.checkout-virtual-assets__points-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .use-points {
    color: @sui_color_gray_light1;
    .font-dpr(24px);
    font-weight: 400;
    display: block;
  }
}

</style>
