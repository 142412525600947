var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.SLabel,{staticClass:"single-product-voucher__label-tag",style:({
    color: _vm.tag?.textColor || 'rgb(25, 128, 85)',
    backgroundColor: _vm.tag?.bgColor || 'rgb(236, 252, 243)',
    ..._setup.themVarStyle.labelStyle
  }),attrs:{"type":"success"}},[_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: 'click_voucher_tag:simple',
      data: {
        goods_id: _vm.tag.goodsId
      }
    }),expression:"{\n      id: 'click_voucher_tag:simple',\n      data: {\n        goods_id: tag.goodsId\n      }\n    }"}],staticClass:"quick-ship-tag__title",on:{"click":_setup.handleShowPopover}},[(_vm.useIcon)?_c(_setup.Icon,{staticClass:"single-product-voucher__label-tag_icon",attrs:{"name":"sui_icon_qucikship_flat_24px","size":_setup.themVarStyle.IconSize,"color":_setup.themVarStyle.IconColor}}):_vm._e(),_vm._v(" "),(!_vm.useIcon && !!_vm.tag?.prefixIcon)?_c('img',{staticClass:"single-product-voucher__label-tag_img",attrs:{"src":_vm.tag?.prefixIcon,"alt":""}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"quick-ship-tag__text_new",style:(_setup.themVarStyle.themTextStyle),domProps:{"innerHTML":_vm._s(_vm.tag.text)}}),_vm._v(" "),_c(_setup.SPopover,{attrs:{"show-close-icon":"","append-to-body":false,"trigger-type":"user","prop-style":{
        maxWidth: '240px',
        width: 'max-content',
        fontWeight: 400
      }},on:{"close":_setup.handleClose},model:{value:(_setup.showPopover),callback:function ($$v) {_setup.showPopover=$$v},expression:"showPopover"}},[_c('div',{staticClass:"voucher-popover-content"},[_vm._v("\n        "+_vm._s(_vm.tag.popoverTips)+"\n      ")]),_vm._v(" "),(!_vm.useIcon && !!_vm.tag?.suffixIcon)?_c('img',{staticClass:"single-product-voucher__label-tag_img",attrs:{"slot":"reference","src":_vm.tag?.suffixIcon,"alt":""},on:{"click":function($event){return _setup.handleSuffixIcon(_vm.tag)}},slot:"reference"}):_vm._e()])],1),_vm._v(" "),(_vm.tag.text_des)?[_c('span',{staticClass:"divider",style:(_setup.themVarStyle.dividerStyle)}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.tag.text_des))])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }