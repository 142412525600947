<template>
  <div
    :class="prefixCls('all-shipping__wrapper')"
    @click="handleLayoutArea"
  >
    <!-- 单商品购物袋内容区域 -->
    <SingleProduct
      v-if="
        shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.isRenderSingleProduct
      "
      :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.quickShip?.[0]"
    />

    <template v-else>
      <!-- quickShip顶部标签，多件商品才有，单件商品不存在顶部标签 -->
      <ShippingLabel />
      <!-- 多商品购物袋内容区域 -->
      <MultiProduct
        :class="prefixCls('as__multi-product')"
        :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.quickShip"
      />
    </template>
  </div>
</template>

<script setup :name="QuickShipType.ALL">
import { inject, provide } from 'vue'
import SingleProduct from '../single_product/Index.vue'
import MultiProduct from '../multi_product/Index.vue'
import ShippingLabel from './components/ShippingLabel.vue'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

// 外页
provide('isOutsidePage', true)
const mallIndex = inject('mallIndex')
const emit = defineEmits(['emiClickLayoutArea'])

// --------- useMap_**** --------
const { shoppingBagsState } = useMapState(['shoppingBagsState'])

// ---------- method ----------
const handleLayoutArea = event => {
  emit('emiClickLayoutArea', { event })
}
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}all-shipping__wrapper {
  .@{prefixCls}as__multi-product {
    padding-top: 0.16rem;
  }
}
</style>
