var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.prefixCls('price-container')},[_c('div',{class:_setup.prefixCls('price-section')},[_c('div',{class:[_setup.prefixCls('ps-main')]},[(!!_setup.renderData.primeGoodImgUrl)?_c('img',{class:_setup.prefixCls('ps__member-tag'),attrs:{"src":_setup.renderData.primeGoodImgUrl,"alt":""}}):[(!!_setup.otherIconInfo.icon)?_c('i',{staticClass:"suiiconfont",class:[_setup.otherIconInfo.icon, _setup.otherIconInfo.color]}):(_setup.isBrandFlash)?_c(_setup.Icon,{attrs:{"name":"sui_icon_brandsale_15px","size":"16px","color":"#FFCD94"}}):_vm._e()],_vm._v(" "),(_setup.isVip)?_c('em',{staticClass:"vip-exclusive-tag_color-black",class:[
          _setup.GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag'
        ]}):_vm._e(),_vm._v(" "),_c('div',{class:[_setup.prefixCls('ps-detail')]},[_c('span',{class:_setup.prefixCls('ps-unit'),style:({ color: _setup.price.color })},[_vm._v("\n          "+_vm._s(_setup.price.txt)+"\n        ")]),_vm._v(" "),(_setup.delPrice.txt)?_c('span',{class:_setup.prefixCls('ps-delete'),style:({ color: _setup.delPrice.color })},[_c('del',{style:({ textDecoration: _setup.delPrice.textDecoration })},[_vm._v("\n            "+_vm._s(_setup.delPrice.txt)+"\n          ")]),_vm._v("\n          "+_vm._s(_setup.delPrice.description)+"\n        ")]):_vm._e(),_vm._v(" "),(!!_setup.delPrice.tip.imgUrl && !!_setup.delPrice.tip.label)?_c(_setup.SPopover,{attrs:{"append-to-body":true,"prop-style":{
            width: '6.4rem'
          },"placemen":"bottom","show-close-icon":"","content":_setup.delPrice.tip.desc}},[_c(_setup.Icon,{attrs:{"slot":"reference","name":_setup.delPrice.tip.imgUrl,"size":"12px","color":"#959595"},slot:"reference"}),_vm._v(" "),_c('div',[_vm._v("\n            "+_vm._s(_setup.delPrice.tip.label)+"\n          ")])],1):_vm._e()],1)],2),_vm._v(" "),(_setup.lowestPrice.txt)?_c('div',{class:[_setup.prefixCls('ps-lowest')],style:(_setup.lowestPrice.style)},[_c('span',{style:({ textDecoration: _setup.lowestPrice.textDecoration })},[_vm._v("\n        "+_vm._s(_setup.lowestPrice.txt)+"\n      ")]),_vm._v("\n      "+_vm._s(_setup.lowestPrice.description)+"\n    ")]):_vm._e()]),_vm._v(" "),(_setup.isQuantityEditable)?_c(_setup.QuantityInput,{attrs:{"data":_vm.data || {}}}):_c('div',{class:[
      _setup.prefixCls('ps-quantity'),
      { 'quantity-welfare': +(_vm.data?.quantity >= 2) }
    ]},[_c('span',{staticClass:"value"},[_vm._v("x"+_vm._s(_vm.data?.quantity))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }