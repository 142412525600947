<template>
  <!-- 免邮提示 -->
  <div :class="prefixCls('free-shipping__tips')">
    <CarouselSwiper
      v-if="isCarousel"
      :autoplayDelay="250"
      :isDelayPlay="false"
      :config="carouselData"
    />

    <template v-else>
      <Icon
        :name="quickShipFreeIcon"
        size="15px"
        color="#fff"
      />
      <span
        :class="[
          prefixCls('free-shipping__tips-txt'),
          isQuickShipBtnTips && 'italic-text'
        ]"
      >
        {{ freeShippingTips }}
      </span>
    </template>
  </div>
</template>

<script setup name="FreeShipping">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

import CarouselSwiper from '@/public/src/pages/checkout_new/components/animation/CarouselSwiper.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import useFreeShipping from '@/public/src/pages/checkout_new/pages/footer/hooks/useFreeShipping.js'

const { freeShippingTips, isCarousel, carouselData } = useFreeShipping()

// ---------- computed ----------
const quickShipFreeIcon = computed(() => {
  return isCarousel
    ? 'sui_icon_qucikship_flat_24px'
    : 'sui_icon_free_shipping_12px'
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}free-shipping__tips {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  right: -5px;
  max-width: calc(~'100% + 0.106rem'); // 0.016rem => 定位right的值，即8/75rem
  padding: 2/75rem 8/75rem;
  height: 17px;
  background: var(---sui_color_success, #198055);
  z-index: 100; // 这里主要是解决PayPal按钮的遮蔽问题
  .checkout-component__label-swiper,
  .swiper-slide-active {
    height: 100% !important;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 0;
    height: 0;
    border-top: 5px solid #16533a;
    border-right: 5px solid transparent;
  }
  &-txt {
    max-width: calc(
      ~'100% + 0.466rem'
    ); // 0.466rem => 定位right的值 + 所有的横向间距 + icon的宽度，获得文本的最大宽度
    height: 26/75rem;
    padding: 0 2/75rem;
    margin-left: 8/75rem;
    color: #fff;
    font-weight: 500;
    .font-dpr(20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.italic-text {
      font-style: italic;
    }
  }
}
</style>
