var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.prefixCls('price-tax__container')},[(!_setup.abtState.orderConfirmDiscountSwitch)?_c('span',{ref:"totalTextRef",class:_setup.prefixCls('pt__total-text')},[_vm._v("\n    "+_vm._s(_setup.language.SHEIN_KEY_PWA_15097)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
      _setup.prefixCls('pt__price'),
      _setup.isShowVerticalLayout && _setup.prefixCls('pt__price-vertical')
    ]},[(_setup.price.visible)?_c('span',{ref:"totalPriceRef",class:[
        _setup.prefixCls('pt__price-total'),
        { [_setup.prefixCls('pt__price-total_discount')]: _setup.isDiscountTotalColor }
      ]},[(_setup.isShowVerticalLayout)?_c(_setup.SAdapterText,{attrs:{"min-size":"14","text":_setup.price.txt}}):_c('span',[_vm._v(_vm._s(_setup.price.txt))])],1):_vm._e(),_vm._v(" "),_c('span',{class:_setup.prefixCls('pt__price-tax-icon')},[(!!_vm.tax.visible)?_c('span',{ref:"totalTaxRef",class:_setup.prefixCls('pt__price-ti_tax')},[(_setup.isShowVerticalLayout)?_c(_setup.SAdapterText,{attrs:{"min-size":"10","text":`+ ${_vm.tax.txt}`}}):_c('span',[_vm._v("+ "+_vm._s(_vm.tax.txt))])],1):_vm._e(),_vm._v(" "),(_setup.abtState.isShowSaveAmount && !_vm.isNotShowPriceDetail)?_c(_setup.Icon,{class:[
          _setup.prefixCls('pt__price-ti_icon'),
          _vm.isShow ? 'sui_icon_more_down' : 'sui_icon_more_up'
        ],attrs:{"name":"sui_icon_more_down_12px","size":"12px"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }