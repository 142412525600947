// 一站多合规信息
import {
  useMapState,
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'

export default () => {
  const {
    abtInfo,
    checkout,
  } = useMapState([
    'abtInfo',
    'checkout',
  ])


  const { updateCommonDialogState } = useMapMutations([
    'updateCommonDialogState',
  ])


  const handleMixPrivacy = async () => {
    const locationId = checkout.value?.address?.countryId || ''
    const loginPrivac = abtInfo.value?.SilentloginPrivacy?.param || 'off'
    const placeOrder = abtInfo.value?.PlaceOrderNationPrivacy?.param || 'no'
    if (!(loginPrivac == 'mix' && placeOrder == 'yes')) return
  
    // 查询当前选择的地址id是否需要同意协议
    let { newPrivacyCache } = await import('@/public/src/pages/login/util.js')
  
    if (newPrivacyCache.get(locationId) == 1) return
  
    let { checkLocationClauseSer, checkMemeberCluaseSer } = await import(
      '@/public/src/pages/login/service.js'
    )
    const queue = [
      checkLocationClauseSer({ locationId }),
      checkMemeberCluaseSer({ locationId })
    ]
    const [location, member] = await Promise.all(queue)
  
    const clauseList = member?.info?.clause_list || []
    const privacyType = location?.info?.clause_country_type
  
    // 用户隐私协议未全部同意并且当前地区为强合规
    if (clauseList.some(v => v.clause_opt == 0) && privacyType == 1) {
      updateCommonDialogState({
        mixPrivacy: {
          needPop: true
        }
      })
    }
  }

  return {
    handleMixPrivacy
  }
}
