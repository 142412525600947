<template>
  <div
    v-if="coupon"
    :class="[prefixCls('multi-wrap')]"
  >
    <div :class="prefixCls('multi-wrap__body')">
      <div
        class="title"
        v-html="titleTxt"
      >
      </div>
      <div>
        {{ coupon.promotion_info }}
      </div>
    </div>

    <div :class="prefixCls('multi-wrap__bg')">
      <img
        :src="multiImgSrc"
      />
      <div
        :class="prefixCls('multi-wrap__bg__val-wrap')"
      >
        <AdaptText
          :class="prefixCls('multi-wrap__bg__val')"
          :text="coupon.tip_on_picture"
          :maxrem="2.1"
          :source="[10,14]"
        />
      </div>
    </div>
  </div>
</template>

<script setup name="MultiReturnCoupon">
import AdaptText from 'public/src/pages/components/AdaptText.vue'
import { computed, ref, watch } from 'vue'
import { prefixCls } from './utils'
import { CountDown } from '@shein/common-function'

import {
  useMapState,
} from '@/public/src/pages/checkout_new/hooks/store'

const { GB_cssRight, PUBLIC_CDN } =
  useMapState([
    'GB_cssRight',
    'locals.PUBLIC_CDN',
  ])

const countTimerInst = new CountDown()
const isShowCountdown = ref(false)

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  coupon: {
    type: Object,
    default: () => ({})
  },
  isCouponEnd: {
    type: Boolean,
    default: false,
  }
})

const titleTxt = computed(() => {
  if(!isShowCountdown.value) {
    return `<i class="iconfont icon-couponbundle-16px"></i>` + props.coupon.promotion_tip
  }

  const { H, M, S } = countTimerInst.timeObj || {}
  let spanTxt = txt => `<span class="fill">${txt}</span>`
  const timeLeftStr =  [H, M, S].map(val => spanTxt(val)).join('<em>:</em>')
  return props.coupon.promotion_tip + timeLeftStr
})

const multiImgSrc = computed(() => {
  return GB_cssRight.value ? `${PUBLIC_CDN?.value}/pwa_dist/images/coupon/sui_img_couponbudle_ar-21cb81bbf1.png` : `${PUBLIC_CDN?.value}/pwa_dist/images/coupon/sui_img_couponbudle-bde0ea9ee3.png`
})

const emits = defineEmits(['update:isCouponEnd'])

watch(() => props.coupon, (val) =>{
  if (!val) {
    return false
  }

  const endDate = val?.end_time * 1000
  const stepTime = endDate - new Date().getTime()

  countTimerInst.clear()
  if(stepTime <= 0) {
    emits('update:isCouponEnd', true)
    return
  }
  if (stepTime < 12 * 60 * 60 * 1000) {
    // 初始化倒计时
    countTimerInst.start({
      seconds: Math.ceil(stepTime / 1000),
      endFunc: () => {
        emits('update:isCouponEnd', true)
      }
    })
    isShowCountdown.value = true
    return
  }
  isShowCountdown.value = false
}, {
  immediate: true,
})
</script>

<style lang="less">
@import "./variables.less";

.@{prefixCls} {
  &multi-wrap {
    position: relative;
    z-index: @zindex-hack;
    min-height: 90/75rem;

    &__bg {
      position: absolute;
      z-index: @zindex-hack;
      right: -20/75rem;
      bottom: -20/75rem;

      > img {
        width: 100px;
        height: auto;
      }

      &__val-wrap {
        position: absolute;
        z-index: @zindex-hack;
        right: 14/75rem;
        top: 4/75rem;
        width: 128/75rem;
        height: 126/75rem;
        .flexbox();
        justify-content: center;
        align-items: center;
      }

      &__val{
        color: #ED731B;
        transform: rotate(-10deg);
        text-shadow: 0 1/75rem #FFF, 1/75rem 0 #FFF, -1/75rem 0 #FFF, 0 -1/75rem #FFF;
        direction: ltr;
      }
    }

    &__body {
      position: relative;
      z-index: @zindex-hack + 1;
      padding-right: 128/75rem;

      &__icon-coupon {
        display: inline-block;
        background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyB0cmFuc2Zvcm06IHJvdGF0ZVkoMTgwZGVnKTsgdHJhbnNmb3JtLW9yaWdpbjogY2VudGVyIGNlbnRlcjsiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0LjUxMjUgNC4yODgzM0gxMi44MDZMMTEuOTk5NyAxLjMwMzQ3TDEuMDU1MSA0LjI4ODMzSDEuMDEyNDVWNC4yOTk5NkwwLjk5OTc1NiA0LjMwMzQyTDEuMDEyNDUgNC4zNTg0NFY3LjgzNzgyTDIuMDEyNDUgOS4yODgzTDEuMDEyNDUgMTAuNzM4OFYxMy43ODgzSDE0LjUxMjVWNC4yODgzM1pNMTEuMDg0NiAyLjkwMDU3TDUuOTk2MDYgNC4yODgzM0gxMS40NTk0TDExLjA4NDYgMi45MDA1N1pNMi4zMTI0NSA3LjQzMzEyTDMuNTkxNDYgOS4yODgzTDIuMzEyNDUgMTEuMTQzNVYxMi40ODgzSDEzLjIxMjVWNS41ODgzM0gyLjMxMjQ1VjcuNDMzMTJaTTYuOTkwNDIgNy4xNzc0NkM2Ljk5MDQyIDcuNjY4MzggNi41OTI0NSA4LjA2NjM1IDYuMTAxNTQgOC4wNjYzNUM1LjYxMDYyIDguMDY2MzUgNS4yMTI2NSA3LjY2ODM4IDUuMjEyNjUgNy4xNzc0NkM1LjIxMjY1IDYuNjg2NTQgNS42MTA2MiA2LjI4ODU3IDYuMTAxNTQgNi4yODg1N0M2LjU5MjQ1IDYuMjg4NTcgNi45OTA0MiA2LjY4NjU0IDYuOTkwNDIgNy4xNzc0NlpNMTAuNTQ2MSAxMC43MzMxQzEwLjU0NjEgMTEuMjI0IDEwLjE0ODEgMTEuNjIyIDkuNjU3MiAxMS42MjJDOS4xNjYyOCAxMS42MjIgOC43NjgzMSAxMS4yMjQgOC43NjgzMSAxMC43MzMxQzguNzY4MzEgMTAuMjQyMiA5LjE2NjI4IDkuODQ0MjQgOS42NTcyIDkuODQ0MjRDMTAuMTQ4MSA5Ljg0NDI0IDEwLjU0NjEgMTAuMjQyMiAxMC41NDYxIDEwLjczMzFaTTguOTI3MSA2LjI4ODU3TDUuMjEyNjUgMTEuNjIyMUg2LjgzMTU4TDEwLjU0NiA2LjI4ODU3SDguOTI3MVoiIGZpbGw9ImJsYWNrIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
        background-size: 100%;
        vertical-align: text-bottom;
        width: 32/75rem;
        height: 32/75rem;
        margin-right: 8/75rem;
      }

      > .title {
        display: inline-block;
        .font-dpr(28px);
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 8/75rem;
        color: @sui_color_main;
        span {
          color: @sui_color_discount
        }
        span.fill {
          color: @sui_color_white;
          padding: 3/75rem;
          .font-dpr(22px);
          background-color: @sui_color_discount;
        }
        em {
          padding: 0 2/75rem;
          font-weight: 400;
          color: @sui_color_discount;
        }
      }


      > :last-child {
        color: @sui_color_gray_dark3;
        .font-dpr(24px);
        line-height: 14/12;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .icon-couponbundle-16px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyB0cmFuc2Zvcm06IHJvdGF0ZVkoMTgwZGVnKTsgdHJhbnNmb3JtLW9yaWdpbjogY2VudGVyIGNlbnRlcjsiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0LjUxMjUgNC4yODgzM0gxMi44MDZMMTEuOTk5NyAxLjMwMzQ3TDEuMDU1MSA0LjI4ODMzSDEuMDEyNDVWNC4yOTk5NkwwLjk5OTc1NiA0LjMwMzQyTDEuMDEyNDUgNC4zNTg0NFY3LjgzNzgyTDIuMDEyNDUgOS4yODgzTDEuMDEyNDUgMTAuNzM4OFYxMy43ODgzSDE0LjUxMjVWNC4yODgzM1pNMTEuMDg0NiAyLjkwMDU3TDUuOTk2MDYgNC4yODgzM0gxMS40NTk0TDExLjA4NDYgMi45MDA1N1pNMi4zMTI0NSA3LjQzMzEyTDMuNTkxNDYgOS4yODgzTDIuMzEyNDUgMTEuMTQzNVYxMi40ODgzSDEzLjIxMjVWNS41ODgzM0gyLjMxMjQ1VjcuNDMzMTJaTTYuOTkwNDIgNy4xNzc0NkM2Ljk5MDQyIDcuNjY4MzggNi41OTI0NSA4LjA2NjM1IDYuMTAxNTQgOC4wNjYzNUM1LjYxMDYyIDguMDY2MzUgNS4yMTI2NSA3LjY2ODM4IDUuMjEyNjUgNy4xNzc0NkM1LjIxMjY1IDYuNjg2NTQgNS42MTA2MiA2LjI4ODU3IDYuMTAxNTQgNi4yODg1N0M2LjU5MjQ1IDYuMjg4NTcgNi45OTA0MiA2LjY4NjU0IDYuOTkwNDIgNy4xNzc0NlpNMTAuNTQ2MSAxMC43MzMxQzEwLjU0NjEgMTEuMjI0IDEwLjE0ODEgMTEuNjIyIDkuNjU3MiAxMS42MjJDOS4xNjYyOCAxMS42MjIgOC43NjgzMSAxMS4yMjQgOC43NjgzMSAxMC43MzMxQzguNzY4MzEgMTAuMjQyMiA5LjE2NjI4IDkuODQ0MjQgOS42NTcyIDkuODQ0MjRDMTAuMTQ4MSA5Ljg0NDI0IDEwLjU0NjEgMTAuMjQyMiAxMC41NDYxIDEwLjczMzFaTTguOTI3MSA2LjI4ODU3TDUuMjEyNjUgMTEuNjIyMUg2LjgzMTU4TDEwLjU0NiA2LjI4ODU3SDguOTI3MVoiIGZpbGw9ImJsYWNrIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
      vertical-align: text-bottom;
      width: 32/75rem;
      height: 32/75rem;
      margin-right: 8/75rem;
    }
  }
}
</style>
