<template>
  <s-dialog
    :visible="visible"
    show-close
    append-to-body
    lock-scroll
    @close="closeDialog"
  >
    <template #top>
      <img
        :src="`${PUBLIC_CDN}/pwa_dist/images/checkout/xtra_header_bg-843fa10ba4.png`"
        class="header-bg"
      />
      <div class="header-bg-container">
        {{ discountDetail.title }}
        <div class="text-xtra_discount pickColor_xtra">
          - {{ discountDetail.totalSavePrice }}
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="virXrt_container">
        <div
          v-for="(item, index) in discountDetail.discountList"
          :key="index"
          class="virXrt_line"
        >
          <div class="virXrt-line_left">
            <span class="virXrt-lineLeft_tipsXtra">{{ item.name }}</span> 
            <Icon
              v-if="item.tip"
              name="sui_icon_doubt_16px_2"
              size="16px"
              @click.stop.prevent="showTip(item.tip)"
            /> 
          </div>
          <div :class="['pickColor_xtra', +item.isDiscount ? '' : 'origin_xtra']">
            {{ +item.isDiscount ? '-' + item.value : item.value }}
          </div>
        </div>
      </div>
      <s-button
        :type="['primary']"
        width="100%"
        @click="closeDialog"
      >
        {{ language.SHEIN_KEY_PWA_15312 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
import { computed } from 'vue'
import { Dialog as SDialog } from '@shein/sui-mobile'

const { PUBLIC_CDN } = gbCommonInfo

const emit = defineEmits(['closeDialog', 'showTip'])

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  logo: {
    type: String,
    default: '',
  },
  dialogData: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
})

const discountDetail = computed(() => {
  return props.dialogData.discountDetail || {}
})

const showTip = (tip) => {
  emit('showTip', tip)
}

const closeDialog = () => {
  emit('closeDialog', 'discountDetail')
}

</script>
<style lang="less" scoped>
.header-bg-container{
  position: absolute;
  top: .85rem;
  // left: 19%;
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  .header-num{
    font-size: 24px;
    font-weight: 600;
  }
}
.pickColor_xtra{
  color: #F82854
}
.origin_xtra{
  color: #000;
}
.text-xtra_discount{
  line-height: 1.5;
  font-size: 24px;
}
.virXrt_container{
  margin-bottom: 0.7rem;
  margin-top: -0.8rem;
  font-size: 14px;
  .virXrt_line{
    display: flex;
    justify-content: space-between;
    margin-top: 10/70rem;
  }
  .virXrt-line_left{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.virXrt-lineLeft_tipsXtra{
  margin-right: 5/70rem;
}
</style>
