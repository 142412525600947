export default () => {
  const initCybersource = () => {
    if (window.GB_cybersource_uuid) return
    window.TPM?.run({
      marketing: 'Cybersource',
      method: '_loadCybersource'
    })
  }
  
  const forterLoad = () =>  {
    window.TPM?.run({
      marketing: 'Forter',
      method: '_loadFort',
    })
  }
  
  const getRiskifiedDeviceid = () => {
    const riskifiedInfo = window.TPM?.runImmediate({
      marketing: 'Riskified',
      method: '_getRiskSessionId',
    })
    if(riskifiedInfo) {
      window.riskifiedDeviceId = riskifiedInfo?.datas || ''
    }
  }

  const publishCartCheckout = () =>{
    let cacheCartInfoStr = sessionStorage.getItem('tpmCacheCart')
    if(cacheCartInfoStr) {
      let cacheCartInfo = null
      try{
        cacheCartInfo = JSON.parse(cacheCartInfoStr)
      } catch(e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      if(cacheCartInfo) {
        window.TPM?.publish('checkout', {
          carts: cacheCartInfo.carts, 
          currency: cacheCartInfo.currency,
          prices: { totalPrice: cacheCartInfo.totalPrice }
        })
      }
      sessionStorage.removeItem('tpmCacheCart')
    }
  }

  const bindEvents = () => {
    initCybersource()
    forterLoad()
    getRiskifiedDeviceid()
    publishCartCheckout()
  }

  return {
    bindEvents
  }
}
