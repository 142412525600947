<template>
  <!-- quickShip 标志 -->
  <div :class="prefixCls('common__shipping-label')">
    <!-- label标签 -->
    <LabelTag
      v-if="!!renderData.productTags.text && renderData.tagVisible"
      :tag="renderData.productTags"
      useIcon
    />

    <span :class="prefixCls('common__sl_txt')">{{
      renderData.itemsLabel
    }}</span>
  </div>
</template>

<script setup name="ShippingLabel">
import { computed, inject } from 'vue'

import { template } from '@shein/common-function'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import LabelTag from '@/public/src/pages/checkout_new/components/QuickShipTag.vue'

// --------- useMap_**** --------
const { checkout, shoppingBagsState } = useMapState([
  'checkout',
  'shoppingBagsState'
])
const mallIndex = inject('mallIndex')

// ---------- computed ----------

/**
 * @description 渲染数据
 * @returns {productTags} label标签
 * @returns {itemsLabel} all item文案
 * */
const renderData = computed(() => {
  const { quick_shipping } = checkout.value
  const {
    bagsInfo: { mall }
  } = shoppingBagsState.value
  const quantity = mall?.[mallIndex]?.totalQuantity?.quickShip
  return {
    productTags:
      {
        textColor: '',
        bgColor: '',
        prefixIcon: '',
        text: quick_shipping?.new_quick_shipping_tag_desc || '',
        labelStyle: {
          'font-style': 'italic',
          'font-weight': 590,
          'font-size': '10px',
          'box-shadow': 'inset 0 0 0 .5px rgba(25,128,85,.2)'
        }
      } || {},
    itemsLabel: template(quantity, quick_shipping?.quick_shipping_items_desc || ''),
    tagVisible: quick_shipping.current_quick_ship_status == 2 || (quick_shipping.current_quick_ship_status == 1 && checkout.value?.quick_shipping?.switch_qs?.switch_qs_selected), // 1. 全部qs，展示。 2. 部分qs，存在运费，展示。
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}common {
  &__shipping-label {
    .font-dpr(24px);
    color: @sui_color_gray_dark1;
    margin-bottom: 12/75rem;
    padding-top: 20/75rem;
    padding-right: 24/75rem;
    display: flex;
    align-items: center;

    .@{prefixCls}common_label-tag {
      border: 0.5px solid rgba(25, 128, 85, 0.2);

      span {
        font-style: italic;
        font-weight: 590;
      }
    }

    .@{prefixCls}common__sl_txt {
      margin-left: 8/75rem;
      font-family: 'SF Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
    }
  }
}
</style>
