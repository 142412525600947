var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _setup.prefixCls('ht__operation'),
  ]},[_vm._t("default"),_vm._v(" "),_c(_setup.ClientOnly,[_c(_setup.AnimationCarousel,{ref:"AnimationCarouselRef",class:[
        _setup.prefixCls('ht__operation-animation'),
        _vm.isShowTwoRowsTitle
          ? _setup.prefixCls('ht__operation-title_vice')
          : _setup.prefixCls('ht__operation-title_single')
      ],on:{"callBackAfterAnimation":_setup.callBackAfterAnimation}},[_vm._l(([0, 1]),function(_,index){return _c('template',{slot:`item${index}`},[_c('h2',{key:index,class:_setup.prefixCls('ht__operation-animation_item')},[_vm._l((_setup.allIcons),function(icon,iconIndex){return _c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.TitleConfig[_setup.modelData.titles[index]?.titleType]?.icon?.name === icon.name),expression:"TitleConfig[modelData.titles[index]?.titleType]?.icon?.name === icon.name"}],key:iconIndex,attrs:{"name":icon.name,"color":icon.color,"size":_vm.isShowTwoRowsTitle ? '12px' : icon.size}})}),_vm._v(" "),_c('div',{class:[
              _setup.prefixCls('ht__oai-txt'),
              _vm.isShowTwoRowsTitle
                ? _setup.prefixCls('ht__oai-txt_vice')
                : _setup.prefixCls('ht__oai-txt_single')
            ]},[(_setup.useGetTitleConfig(index,_setup.modelData).before)?_c('span',{class:[
                _setup.prefixCls('ht__oai-txt_before'),
                _vm.isShowTwoRowsTitle && _setup.prefixCls('ht__oai-txt_normal')
              ],domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index,_setup.modelData).before)}}):_vm._e(),_vm._v(" "),(_setup.useGetTitleConfig(index,_setup.modelData).special)?_c(_setup.AnimationCarousel,{ref:`textCarouselRef${index}`,refInFor:true,class:_setup.prefixCls('ht__oai-txt_special'),scopedSlots:_vm._u([_vm._l(([0, 1]),function(__,textIndex){return {key:`item${textIndex}`,fn:function(){return [_c('span',{key:textIndex,domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index, _setup.modelData).special)}})]},proxy:true}})],null,true)}):_vm._e(),_vm._v(" "),(_setup.useGetTitleConfig(index,_setup.modelData).after)?_c('span',{class:[
                _setup.prefixCls('ht__oai-txt_after'),
                _vm.isShowTwoRowsTitle && _setup.prefixCls('ht__oai-txt_normal')
              ],domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index,_setup.modelData).after)}}):_vm._e()],1)],2)])})],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }