<template>
  <div
    v-if="isShow"
    class="tAndc-wrapper"
  >
    <span
      class="tAndc-tips"
      @click="linkHandle"
    >T&C</span>
  </div>
</template>

<script>
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-25-1' })

const { langPath, SiteUID } = gbCommonInfo
export default {
  name: 'TAndc',
  data () {
    return {
      isShow: false,
      flag: false
    }
  },
  watch: {
    '$route' () {
      this.getIsShowPath()
    }
  },
  mounted () {
    this.getAbtInfo()
  },
  methods: {
    // 获取abt试验
    async getAbtInfo () {
      const key = 'couponarticalpage'
      // eslint-disable-next-line @shein-aidc/abt/abt
      const res = await abtservice.getUserAbtResult({ posKeys: key })
      if (res?.[key]?.param == 'show') {
        this.flag = true
        this.getIsShowPath()
      }
    },
    // 是否是需要展示的页面
    getIsShowPath () {
      if (!this.flag) return
      const showPath = [
        `${langPath}/user/coupon`, 
        `${langPath}/checkout`,
        `${langPath}/checkout/checkout_new`,
        `${langPath}/buyprime`,
        `${langPath}/xtra`
      ]
      if (showPath.some(path => location.pathname.indexOf(path) > -1)) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    // 跳转到文章详情
    linkHandle () {
      daEventCenter.triggerNotice({ daId: '1-25-1-4' })
      const articleId = ['pwus', 'rwmus'].includes(SiteUID) ? 1398 : 1302
      window.open(`${langPath}/coupon-policy-a-${articleId}.html`, '_blank')
    }
  }
}
</script>

<style lang="less">
.tAndc-wrapper {
  height: 1.17rem;
  font-size: 16px;
  padding: 0 0.32rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: @zindex-attrmenusize;
  transform: translate3d(0, 0, 10px);
  .tAndc-tips {
    cursor: pointer;
  }
}
</style>
