import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

export const AssetType = {
  COUPON: 'coupon',
  POINT: 'point',
}

export const useFreeShippingTipsList = (type) => {
  const { mall_price_list } = useMapState(['checkout.mall_price_list'])
  
  const isNotEmpty = item => item.not_free_shipping_tip?.trim()
  
  if(AssetType.COUPON === type) {
    return mall_price_list.value?.filter(item => +item.not_free_shipping_tips === 1 || +item.not_free_shipping_tips !== 2)
      .filter(isNotEmpty)
  }
  
  if(AssetType.POINT === type) {
    return mall_price_list.value?.filter(item => +item.not_free_shipping_tips === 2 || +item.not_free_shipping_tips !== 1)
      .filter(isNotEmpty)
  }
  
  return []
}
