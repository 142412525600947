<template>
  <CommonDrawer
    :visible="visible"
    @onClose="updateDrawerVisible(false)"
  >
    <template #top>
      {{ language.SHEIN_KEY_PWA_16493 }}
    </template>

    <template #intro-section>
      <div
        class="point-description-content"
        tabindex="0"
      >
        <p class="point-description-content__use-way">
          {{ language.SHEIN_KEY_PWA_28258 }}
        </p>
        <p class="point-description-content__point-number">
          <span>{{ language.SHEIN_KEY_PWA_14955 }}: </span>
          <span> {{ point.total_point || 0 }} </span>
        </p>
        <p class="point-description-content__point-number">
          <span class="max-use-point">{{ language.SHEIN_KEY_PWA_16457 }}:</span>
          <span>
            {{ point.maxAvailablePoint }}
          </span>
        </p>
      </div>
    </template>

    <template #input-section>
      <input
        v-model="model.input"
        type="text"
        class="mshe-input"
        :disabled="model.isApplied || model.loading"
        :placeholder="placeholderTextInit"
        @blur="inputBlur"
        @input="setMaxInput"
      />
      <span
        v-if="!model.isApplied && !model.loading"
        v-enterkey
        class="mshe-input-all"
        tabindex="0"
        role="button"
        @click="handleIconClick"
      >
        <Icon
          v-if="showClearIcon"
          name="sui_icon_close_34px"
          size="14px"
          color="#ccc"
        />
        <span v-else>{{ language.SHEIN_KEY_PWA_15623 }}</span>
      </span>
    </template>

    <template
      v-if="point.useRuleDetailTip"
      #input-desc
    >
      <p
        v-expose="{
          id: 'expose_view_details:simple'
        }"
        class="point-use-rule"
      >
        <span v-html="point.useRuleDetailTip"></span>
        <span
          v-if="point.useRuleDetail?.useRules?.length > 0"
          class="point-use-rule__view-more"
          @click.stop="handleFreezeTipsClick"
        >
          {{ language.SHEIN_KEY_PWA_28573 }}
          <Icon
            name="sui_icon_more_right_12px"
          />
        </span>
      </p>
    </template>

    <template #footer-region>
      <s-button
        v-enterkey
        width="100%"
        :disabled="!model.input || +model.input <= 0 || model.loading"
        :type="['primary', 'H80PX']"
        @click="onSubmit"
      >
        <template v-if="model.isApplied">
          {{ (language.SHEIN_KEY_PWA_15222 || '').toLocaleUpperCase() }}
        </template>
        <template v-else>
          {{ (language.SHEIN_KEY_PWA_15104 || '').toLocaleUpperCase() }}
        </template>
      </s-button>
    </template>
  </CommonDrawer>
</template>

<script setup>
import { watch } from 'vue'
import { Button as SButton } from '@shein/sui-mobile'
import CommonDrawer from './CommonDrawer.vue'
import { useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'
import { useVirtualAssetsDrawer } from '@/public/src/pages/checkout_new/pages/virtual_assets/hooks/commonDrawer'
import { Icon } from '@shein-aidc/icon-vue2'

const emits = defineEmits(['onSubmit'])

const {
  visible,
  model,
  placeholderTextInit,
  showClearIcon,
  inputBlur,
  handleIconClick,
  setMaxInput,
  onSubmit,
  updateDrawerVisible
} = useVirtualAssetsDrawer({ type: VirtualAssetsTypes.POINT, emits })

const { point, pointDrawer, language } = useMapState([
  'checkout.point',
  'virtualAssetsState.pointDrawer',
  'language'
])

const { updateVirtualAssetsPopupState } = useMapMutations([
  'updateVirtualAssetsPopupState'
])

const handleFreezeTipsClick = () => {
  // 积分详情入口点击
  daEventCenter.triggerNotice({
    id: 'click_view_details:simple'
  })

  updateVirtualAssetsPopupState({
    // 商品不可用弹窗
    freezeLimitGoods: {
      visible: true,
      type: VirtualAssetsTypes.POINT,
    }
  })
}

watch(() => point.value.used_point, (val) => {
  if(+val > 0) {
    model.input = val
    model.isApplied = true
  }
}, {
  immediate: true,
})

watch(pointDrawer, ({ visible, value }) => {
  if(visible && value) {
    model.input = String(value)
  }
})
</script>
