var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.autoUseCouponTip || _vm.primeFreeTip)?_c('div',{class:_setup.prefixCls('prime__wrapper')},[(_vm.autoUseCouponTip)?_c('div',{class:_setup.prefixCls('prime__free-ship')},[_c('span',[_vm._v("\n      "+_vm._s(_vm.autoUseCouponTip)+"\n    ")]),_vm._v(" "),_c('span',{on:{"click":_setup.goCouponPage}},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_22734)+"\n    ")])]):(_vm.primeFreeTip)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: 'expose_prime_entry:simple',
      data: _setup.saObj
    }),expression:"{\n      id: 'expose_prime_entry:simple',\n      data: saObj\n    }"}],class:_setup.prefixCls('prime__join-member')},[_c('span',{class:_setup.prefixCls('prime__join-member__label')},[_c('div',{class:_setup.prefixCls('prime__join-member__img-box')},[_c('img',{class:_setup.prefixCls('prime__join-member__img'),attrs:{"src":_vm.primeFreeTip.prime_icon_url,"alt":""}})]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.primeFreeTip.free_shipping_tip)}})]),_vm._v(" "),_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: 'click_prime_entry:simple',
        data: _setup.saObj
      }),expression:"{\n        id: 'click_prime_entry:simple',\n        data: saObj\n      }"}],class:_setup.prefixCls('prime__join-member__btn-group'),attrs:{"role":"button"},on:{"click":_setup.onClickJoinPrime}},[_c('span',[_vm._v("\n        "+_vm._s(_vm.primeFreeTip.button_text)+"\n      ")])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }