<template>
  <s-button
    width="100%"
    :class="[
      prefixCls('normal-btn'),
      benefitCarousel.visible && prefixCls('normal-btn_benefit')
    ]"
    :style="{ border: 'none', backgroundColor: btnContent.backgroundColor }"
    :type="['primary', 'H80PX']"
    :loading="createOrderLoading"
  >
    <!-- button主体 -->
    <div
      v-if="btnContent.visible"
      :class="[
        prefixCls('normal-btn_content'),
        btnContent.cls, // 横竖布局类型
        btnContent.horizontal?.reverse // 反向
      ]"
    >
      <!-- 按钮内容区 -->
      <div
        v-if="!!btnContent?.buttonText"
        :class="prefixCls('normal-btn_content-text')"
        v-html="containerText"
      ></div>

      <!-- icon图 -->
      <CommonImage
        v-if="!!btnContent.logo"
        :class="prefixCls('normal-btn_content-logo')"
        :imgUrl="btnContent.logo"
        :imgDesignWidth="45"
        isFirstPage
      />

      <!-- 内容区下行 -->
      <div
        v-if="!!btnContent.vertical?.text"
        :class="prefixCls('normal-btn_content-sub-text')"
      >
        {{ btnContent.vertical.text }}
      </div>
    </div>
    <!-- 默认 -->
    <div
      v-else
      :class="[prefixCls('normal-btn_content')]"
    >
      <SAdapterText
        min-size="10"
        :text="defaultText"
      />
    </div>
    <!-- 按钮的利益点信息 -->
    <CarouselSwiper
      v-if="benefitCarousel.visible"
      v-expose="{ id: 'expose_inner_benefits:simple' }"
      :config="benefitCarousel.data"
    />
  </s-button>
</template>

<script setup name="NormalBtn">
import { computed, watch } from 'vue'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import {
  useMapGetters,
  useMapState
} from '@/public/src/pages/checkout_new/hooks/store'
import CarouselSwiper from '@/public/src/pages/checkout_new/components/animation/CarouselSwiper.vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import { NormalBtnType } from '@/public/src/pages/checkout_new/pages/footer/utils/constant.js'
import { isEqual } from '@/public/src/pages/checkout_new/utils'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import CommonImage from '@/public/src/pages/checkout_new/components/CommonImage.vue'

// --------- useMap_**** --------
const {
  //
  createOrderLoading,
  language,
  checkout,
  cartsInfo,
} = useMapState([
  'createOrderLoading',
  'language',
  'checkout',
  'cartsInfo',
])
const { selectedPaymentInfo } = useMapGetters(['selectedPaymentInfo'])

// ---------- computed ----------
/**
 * @description 普通按钮展示逻辑
 * 梳理的逻辑：public/src/pages/checkout_new/pages/footer/components/btn_wrapper/btn_container/btn_group/README.md
 * */
const btnContent = computed(() => {
  const info = checkout.value?.placeOrderButton || {}
  const isVertical = [
    NormalBtnType.BnplExclusive,
    NormalBtnType.BnplExclusiveFallback
  ].includes(info?.type)
  const buttonText =
    info?.type === NormalBtnType.CardTokenPay
      ? info?.buttonText + info?.buttonExtendText
      : info?.buttonText

  const res = {
    visible: Object.values(NormalBtnType).includes(info?.type),
    isVertical: isVertical,
    ...info,
    buttonText: template(
      `<span style="color: ${info?.buttonPriceColor};">${info?.buttonPriceWithSymbol}</span>`,
      buttonText
    ),
    cls: isVertical
      ? prefixCls('normal-btn_content-vertical')
      : prefixCls('normal-btn_content-horizontal'),
    vertical: isVertical
      ? {
        text:
            info?.type === NormalBtnType.BnplExclusiveFallback
              ? info?.withPaymentText
              : ''
      }
      : null,
    horizontal: isVertical
      ? null
      : {
        reverse: [
          NormalBtnType.BnplNormal,
          NormalBtnType.CardTokenPay,
          NormalBtnType.CommonBnplPay
        ].includes(info?.type)
          ? prefixCls('normal-btn_content-horizontal-reverse')
          : ''
      },
    logo: info?.type === NormalBtnType.CardTokenPay
      ? ''
      : isVertical
        ? info?.type === NormalBtnType.BnplExclusive
          ? info?.paymentLogo
          : ''
        : [
          NormalBtnType.BnplNormal,
          NormalBtnType.CardTokenPay,
          NormalBtnType.CommonBnplPay,
          NormalBtnType.CommonNotBnplPay
        ].includes(info?.type)
          ? info?.paymentLogo
          : ''
  }
  return res
})

// 下单按钮默认文案
const defaultText = computed(() => {
  let txt =
    checkout.value?.placeOrderButton?.buttonDefaultText ||
    language?.value.SHEIN_KEY_PWA_15135

  if (selectedPaymentInfo.value?.code !== 'routepay-card') {
    txt = language?.value?.SHEIN_KEY_PWA_21233
  }
  return txt?.toLocaleUpperCase()
})

// 按钮的利诱点文案
const benefitCarousel = computed(() => {
  const checkoutPoints = checkout.value?.placeOrderScrollBarLurePoints || []
  const cartsInfoPoints = cartsInfo.value?.placeOrderScrollBarLurePoints || []
  const list = [...checkoutPoints, ...cartsInfoPoints].map(item => {
    return {
      ...item,
      text: {
        label: item.text,
        color: '#fff'
      },
      icon: {
        name: '',
        color: ''
      },
      img: {
        url: item.pre_img_url || '',
        style: { width: '12px' }
      }
    }
  })

  const renderList = list?.filter(item => isEqual(item.is_display, '1')) || []
  const allBenefit = list?.filter(i => i.type).join('、') || ''

  return {
    allBenefit,
    visible: !!renderList.length,
    data: renderList.sort((a, b) => +a.sort - +b.sort)
  }
})

const containerText = computed(() => {
  const { buttonText, type } = checkout.value?.placeOrderButton ?? {}
  const text = btnContent.value.buttonText
  if (!buttonText) return ''
  if (type === NormalBtnType.CardTokenPay && !!text) {
    const pwd = '****'
    const textAndCardNoList = text.split(pwd)
    if (textAndCardNoList.length === 2) {
      const dom = `<div class="card-token_wrapper">
        <div class="card-token_text">
          ${textAndCardNoList[0]}
          </div>
          <div class="card-token_card-no">
            ${pwd}${textAndCardNoList[1]}
          </div>
        </div>`
      return dom
    } else {
      return text
    }
  }
  return text
})

// ---------- watch ----------
watch(
  () => benefitCarousel.value?.allBenefit,
  val => {
    if (val) {
      daEventCenter.triggerNotice({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'innerPlaceorderBenefits',
          inner_benefits_type: val
        }
      })
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="less">
@import '../../../../../variables.less';

.@{prefixCls}normal-btn {
  padding: 0 0.32rem !important;

  &_content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    .checkout-component__image {
      height: auto;
    }

    .S-adapterText {
      color: white;
    }

    .card-token {
      &_wrapper {
        display: flex;
      }

      &_text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
      }

    }

    &-logo {
      width: 60/75rem;
      margin: 0 8/75rem;
      border-radius: 4/75rem;
    }

    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.2;
      color: white;

      * {
        color: white;
      }
    }

    &-sub-text {
      height: 24/75rem;
      margin-top: 4/75rem;
      font-size: 10px;
      font-weight: 700;
      line-height: normal;
      color: var(---sui_color_white, #fff);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-vertical {
      .@{prefixCls}normal-btn_content-logo {
        margin-top: 0.05333333rem;
        height: 0.26666667rem;
        width: auto;
      }
    }

    &-horizontal {
      flex-direction: row;

      .@{prefixCls}normal-btn_content-logo {
        background-color: #fff;
        border-radius: 4/75rem;
      }

      &-reverse {
        flex-direction: row-reverse;

        .@{prefixCls}normal-btn_content-logo {
          background-color: #fff;
          border-radius: 4/75rem;
        }
      }
    }
  }

  &_benefit {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .checkout-component__label-swiper {
      display: inline-block;
      height: 14px;
    }

    .@{prefixCls}normal-btn_content {
      height: auto;

      .S-adapterText {
        line-height: normal;
      }
    }
  }

  .@{prefixCls}benefit-btn-layout {
    height: 34/75rem;
    padding: 0;
    line-height: normal;
  }
}
</style>
