import { ref, onMounted, onUnmounted } from 'vue'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'

export function useNetworkStatus() {
  const { isClient } = useIsClient()
  let isOnline = true
  if (!isClient.value) return { isOnline }
  // 创建一个响应式的网络状态变量
  isOnline = ref(navigator.onLine)

  // 网络状态变化时更新isOnline的值
  const updateNetworkStatus = () => {
    isOnline.value = navigator.onLine
  }

  onMounted(() => {
    window.addEventListener('online', updateNetworkStatus)
    window.addEventListener('offline', updateNetworkStatus)
  })

  onUnmounted(() => {
    window.removeEventListener('online', updateNetworkStatus)
    window.removeEventListener('offline', updateNetworkStatus)
  })

  return { isOnline }
}
