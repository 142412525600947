import { ref, onMounted } from 'vue'

/**
 * @description 客户端环境
 * */
export default function useIsClient() {
  const isClient = ref(false)

  onMounted(() => {
    isClient.value = true
  })

  return { isClient }
}
