import bsUtils from 'public/src/pages/common/handlePay/bsLib.js'
import { debuggerLog } from '@/public/src/pages/checkout_new/utils/index.js'
import { PaymentProcessor, CHECKOUT_TYPE, PRODUCT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'

let paymentInstance

export const useBsPayInstance = () => {
  if (typeof window === 'undefined') return { paymentInstance }

  if (!paymentInstance) {
    paymentInstance = new PaymentProcessor({
      checkoutType: CHECKOUT_TYPE.NORMAL,
      productType: PRODUCT_TYPE.NORMAL,
      useInline: false,
      environment: ['debug', 'localhost'].includes(gbCommonInfo?.NODE_SERVER_ENV) ? 'TEST' : 'PRODUCITON',
      bsUtils
    })
  }

  debuggerLog('instance=====', paymentInstance)

  const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
  if (enableDebugLog) {
    window.__PAY_INSTANCE__ = paymentInstance
  }

  return {
    paymentInstance
  }
}
