import { convertColor } from 'public/src/pages/common/product-package/shared/utils.js'

const transformProudctPackageStyle = (style = {} )=>{
  
  return {
    cardBgImg: style.cardBgImg,
    titleColor: convertColor(style.titleColor),
    titlePlaceColor: convertColor(style.titlePlaceColor),
    showSubTitle: style.showSubTitle,
    subTitleColor: convertColor(style.subTitleColor),
    subTitlePlaceColor: convertColor(style.subTitlePlaceColor),
    priceColor: convertColor(style.priceColor),
    linePriceColor: convertColor(style.linePriceColor),
    showLinePrice: style.showLinePrice,
    showLabel: style.showLabel,
    labelBorderColor: convertColor(style.labelBorderColor),
    labelBgColor: convertColor(style.labelBgColor),
    labelColor: convertColor(style.labelColor),
    protocolColor: convertColor(style.protocolColor),
    showCouponLimit: style.showCouponLimit,
    showCouponNumber: style.showCouponNumber,
    couponCircleBgColor: convertColor(style.couponCircleBgColor),
    buttonColor: convertColor(style.buttonColor),
  }
}

export {
  transformProudctPackageStyle,
}
