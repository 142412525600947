<template>
  <!-- 如果是爆破黑名单用户，不管钱包是否有钱都展示入口 riskStr == 2 -->
  <!-- 当walletFreez.showReason为true时，只要钱包有其它货币或者有钱包冻结记录，都展示钱包入口 -->
  <li
    v-expose="{
      id: 'expose_wallet:simple',
      data: {
        is_choosed: +wallet_balance?.walletPrice?.amount > 0 ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    v-enterkey
    class="checkout-virtual-assets__wallet-content"
    tabindex="0"
    @click="handleOpenDrawerIntercept"
  >
    <div class="input-icon">
      <span
        style="white-space: nowrap;"
        class="input-icon-cell"
      >
        {{ language.SHEIN_KEY_PWA_15109 }}:
      </span>

      <div :class="[prefixCls('dynamic-comp-item__coupon-right'), 'input-icon-cell']">
        <template v-if="+riskType !== 2">
          <span
            v-if="+wallet_balance?.walletPrice?.amount > 0"
            class="use-red"
          >
            -{{ wallet_balance?.walletPrice.amountWithSymbol }}
          </span>
          <span
            v-else-if="+wallet_balance?.subtractPrice?.amount > 0"
            class="use-total wallet-tips"
          >
            {{ language.SHEIN_KEY_PWA_24801 }}
            <em
              v-if="+wallet_balance.walletPrice.amount <= 0 && !isDrawerClicked"
              class="wallet-tips-dot"
            ></em>
          </span>
        </template>
        <Icon
          :name="!GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px'"
          class="icon-arrow"
          color="#959595"
        />
      </div>
    </div>
  </li>
</template>

<script setup name="AssetWallet">
import { ref } from 'vue'
import { prefixCls } from '../utils'
import { useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Icon } from '@shein-aidc/icon-vue2'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'

const { isClient } = useIsClient()

// 抽屉是否打开过，为了红点状态
const isDrawerClicked = ref(false)

const props = defineProps({
  isFolded: {
    type: Number,
    default: 1,
  },
  riskType: {
    type: Number,
    default: 0,
  }
})

const { wallet_balance, walletRiskInfo, language, GB_cssRight } = useMapState(['checkout.walletRiskInfo', 'checkout.wallet_balance', 'language', 'GB_cssRight'])
const { updateVirtualAssetsPopupState } = useMapMutations([
  'updateVirtualAssetsPopupState', 'updateCommonDialogState'
])

const openDrawer = () => {
  updateVirtualAssetsPopupState({
    walletDrawer: {
      visible: true
    }
  })

  isDrawerClicked.value = true

  daEventCenter.triggerNotice({
    id: 'click_wallet:simple',
    data: {
      is_choosed: +wallet_balance.value?.walletPrice?.amount > 0 ? 1 : 0,
      is_folded: props.isFolded,
    },
  })

  daEventCenter.triggerNotice({
    id: 'expose_popup_edit_wallet:simple',
    data: {
      button_included: +wallet_balance.value.walletPrice?.amount > 0 && wallet_balance.value.walletPrice?.amountWithSymbol ? 'remove' : 'apply'
    }
  })
}

const handleOpenDrawerIntercept = () => {
  if(!isClient.value) {
    return
  }

  if(!walletRiskInfo.value) {
    openDrawer()
    return
  }

  // 风控弹窗
  window.appEventCenter.$emit('virtual-assets:update-wallet-risk_confirm', ({
    open: openRiskConfirmDialog,
    riskConfirmInst
  }) => {
    const riskType = +walletRiskInfo.value?.risk_decision === 0 && +riskConfirmInst.updateRiskStr(walletRiskInfo.value) || 1

    if(riskType === 2) {
      riskConfirmInst?.dialogUpdateInfo({
        scene: 'view_wallet_balance',
        info: walletRiskInfo.value
      })

      // 打开风控弹窗
      openRiskConfirmDialog()

      return
    }

    openDrawer()
  })
}
</script>

<style lang="less">
.checkout-virtual-assets__wallet-content {
  display: flex;
  justify-content: space-between;

  .wallet-tips {
    display: inline-flex;
    align-items: center;
  }
  .wallet-tips-dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: @sui_color_guide;
    .margin-l(8/75rem);
  }
}
</style>
