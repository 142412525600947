import last from 'lodash/last'
import { convertColor } from '../../shared/utils'

export const transformProudctPackageStyle = (style = {}, type = 'base') => {
  const { GB_cssRight } = gbCommonInfo

  if (type === 'base') {
    return {
      bgImg: style.bgImg,
      logoImg: GB_cssRight ? style.rightLogoImg : style.leftLogoImg,
      protocolColor: getFirstColor(style.protocolColor),
      protocolHightlightColor: getFirstColor(style.protocolHighlightColor),
      selectedRightColor: getFirstColor(style.selectedRightColor),
      selectedRightPlaceColor: getFirstColor(style.selectedRightPlaceColor),
      subTitleColor: getFirstColor(style.subTitleColor)
    }
  }

  return {
    cardBgImg: style.cardBgImg,
    rightStrongIconImg: style.rightStrongIconImg,
    sellingPointBgImg: style.sellingPointBgImg,
    productNameBgColor: convertColor(style.productNameBgColor),
    productNameCornerBgColor: getLastColor(style.productNameBgColor),
    historySavedLabelBgColor: getHistorySavedLabelBgColor(
      style.historySavedLabelBgColor
    ),
    productNameColor: getFirstColor(style.productNameColor),
    rightColor: getFirstColor(style.rightColor),
    rightIconColor: getFirstColor(style.rightIconColor),
    sellingPointColor: getFirstColor(style.sellingPointColor),
    countDownColor: getFirstColor(style.countDownColor),
    countDownBgColor: convertColor(style.countDownBgColor),
    checkboxColor: getFirstColor(style.checkboxColor),
    cardCheckedBorderColor: getFirstColor(style.cardCheckedBorderColor),
    priceColor: getFirstColor(style.priceColor),
    linePriceColor: getFirstColor(style.linePriceColor),
    discountLabelBgColor: convertColor(style.discountLabelBgColor),
    discountLabelColor: getLastColor(style.priceColor),
    rightLabelBgColor: convertColor(style.rightLabelBgColor),
    rightLabelTextColor: convertColor(style.rightLabelTextColor)
  }
}

export const transformPopupStyle = (style = {}, type = 'base') => {
  if (type === 'base') {
    return {
      bgImg: style.bgImg,
      titleColor: convertColor(style.titleColor),
      protocolColor: getFirstColor(style.protocolColor),
      protocolHightlightColor: getFirstColor(style.protocolHighlightColor)
    }
  }

  return {
    autoRenewalTipColor: convertColor(style.autoRenewalTipColor),
    sellingPointBgImg: style.sellingPointBgImg,
    sellingPointColor: convertColor(style.sellingPointColor),
    countDownColor: convertColor(style.countDownColor),
    countDownBgColor: convertColor(style.countDownBgColor),
    rightBgImg: style.rightBgImg,
    productNameColor: convertColor(style.productNameColor),
    productDescColor: convertColor(style.productDescColor),
    rightColor: getFirstColor(style.rightColor),
    rightIconColor: getFirstColor(style.rightIconColor),
    rightDescColor: getFirstColor(style.rightDescColor),
    productCardBorderColor: convertColor(style.productCardBorderColor),
    productCardCheckedBorderColor: convertColor(
      style.productCardCheckedBorderColor
    ),
    productCardBgColor: convertColor(style.productCardBgColor),
    productCardCheckedBgColor: convertColor(style.productCardCheckedBgColor),
    productCardNameColor: convertColor(style.productCardNameColor),
    priceColor: convertColor(style.priceColor),
    linePriceColor: convertColor(style.linePriceColor),
    discountLabelBgColor: convertColor(style.discountLabelBgColor),
    discountLabelCornerColor: getLastColor(style.discountLabelBgColor),
    normalBtnBgImg: style.normalBtnBgImg,
    normalBtnColor: convertColor(style.normalBtnColor),
    activityBtnBgImg: style.activityBtnBgImg,
    activityBtnColor: convertColor(style.activityBtnColor),
    rightLabelBgColor: convertColor(style.rightLabelBgColor),
    rightLabelTextColor: convertColor(style.rightLabelTextColor)
  }
}

const getHistorySavedLabelBgColor = el => {
  const color = getFirstColor(el)

  return color
    ? `linear-gradient(to right,${color},${color.replace('#', '#00')})`
    : color
}

function getFirstColor(el) {
  const colors = el?.colors
  return colors?.[0]
}

function getLastColor(el) {
  const colors = el?.colors
  return last(colors)
}
