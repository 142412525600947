<template>
  <!-- 敏感品 icon -->
  <img
    v-if="isSensitive"
    :class="prefixCls('common_shipping-icon')"
    :src="`${locals.PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
  />
</template>

<script setup name="Sensitive">
import { computed } from 'vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'


const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

// --------- useMap_**** --------
const { checkout, locals } = useMapState(['checkout', 'locals'])

/**
 * @description 是否展示敏感信息
 * @returns1 true
 * @returns2 false
 * */
const isSensitive = computed(() => {
  const cartIdList = checkout.value?.sensitive_info?.sensitive_goods?.map(_ => _.cart_id) || []
  return cartIdList.includes(props.id)
})
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}common_shipping-icon {
  width: 32/75rem;
  height: 32/75rem;
  position: absolute;
  top: 8/75rem;
  right: 8/75rem;
  z-index: 1;
}
</style>
