import { parseQueryString, getLocalStorage } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import {
  useMapState,
  useMapGetters,
} from '@/public/src/pages/checkout_new/hooks/store'
import { judgeActivityIsExist } from '@/public/src/pages/checkout_new/utils/index.js'
import { shippingCenter } from '@/public/src/pages/common/shipping'



export default () => {
  const {
    abtInfo,
    cartsInfo,
    shoppingBagsState,
    checkout,
  } = useMapState([
    'abtInfo',
    'cartsInfo',
    'shoppingBagsState',
    'checkout',
  ])

  const { 
    paymentInfo,
  } = useMapGetters([
    'paymentInfo',
  ])


  const reportPvAnalysis = async(from = {}) => {
    window.PageGroup = '下单页'
    window.PageGroupOverview = '购物流程'
  
  
    let page_from = ''
    if( document.referrer ){
      if( document.referrer.indexOf('/user/login') > -1 ){
        page_from = 'login'
      }
      if (document.referrer.indexOf('/cart') > -1) {
        page_from = 'page_cart'
      }
    }
    if (from.path?.indexOf('/cart') > -1) {
      page_from = 'page_cart'
    }
    const { page_from: pageName } = parseQueryString(window.location.search)
    if(!!pageName) {
      page_from = pageName
    }
  
    // 路由进入手动刷新数据
    let SaPageInfo = {
      page_id: 11,
      page_name: 'page_checkout',
      page_param: {
        page_from,
        is_combine: getLocalStorage('is_combine') == '0' ? 0 : 1,
        mall_code: cartsInfo.value?.good_by_mall?.map(_ => _.mall_code)?.join(','),
        activity_method: judgeActivityIsExist(shoppingBagsState.value?.bagsInfo?.all || [], [3, 10]),
      },
      start_time: new Date().getTime()
    }
  
    let posKeys = []
    const paymentMethods = paymentInfo.value?.payments || []
    const PaymentABT = abtInfo.value?.['PaymentABT'] || {}
    if (PaymentABT && paymentMethods?.length) {
      const paymentOffAbtValue =
        PaymentABT?.p?.indexOf?.('paymentoff=') > -1
          ? PaymentABT.p.split('=')[1]
          : ''
      if (paymentOffAbtValue) {
        posKeys.push('SMPaymentABT')
      }
    }
  
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtestVal = abtservice.getUserAbtResultForAnalysis({
      posKeys: posKeys.join(','),
      abtInfo: abtInfo.value
    }).sa
    if (SaPageInfo.page_param.abtest) {
      SaPageInfo.page_param.abtest += ',' + abtestVal
    } else {
      SaPageInfo.page_param.abtest = abtestVal
    }

    if(!!shoppingBagsState.value?.bagsInfo?.all?.length) {
      SaPageInfo.page_param.is_shop = shoppingBagsState.value.isShopType
    }
  
    SaPageInfo.page_param.buy_tp = checkout.value?.prime_info?.prime_products?.length
      ? '2'
      : '0'


    let mallShipParam = {}
    try {
      const shippingContentInfo = await shippingCenter.getMallShippingContent({
        fromCheckout: true,
        mallShippingInfo: !!checkout.value?.free_mall_info ? {
          code: 0,
          info: checkout.value?.free_mall_info
        } : null
      })

      const mallShippingAnalysisParam = handleMallShippingAnalysisParam({
        cartsInfo: cartsInfo.value,
        mallShippingInfo: shippingContentInfo
      })
      mallShipParam = handleMallShippingAnalysis(mallShippingAnalysisParam)

      if (!shippingContentInfo) {
        // eslint-disable-next-line require-atomic-updates
        window.SaPageInfo = SaPageInfo
        appEventCenter?.$emit('pageOnload')
        window.onbeforeunload = function() {
          sa('send', 'pageLeave', { end_time: new Date().getTime() })
        }
        return
      }
    } catch(e) {
      // eslint-disable-next-line no-console
      console.log(e)
      // eslint-disable-next-line require-atomic-updates
      window.SaPageInfo = SaPageInfo
      appEventCenter?.$emit('pageOnload')
      window.onbeforeunload = function() {
        sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
      return
    }

    //活动id和是否满足
    let promotion_method = ''
    let page_fromcart = ''
    let checkout_from = ''
    let checkout_total = ''
    if (window.sessionStorage?.getItem) {
      try {
        let cartPoints = window.sessionStorage.getItem('cartPoints')
        if (cartPoints) {
          cartPoints = JSON.parse(cartPoints)
          let cartSa = cartPoints.sa
          if (cartSa) {
            promotion_method = cartSa.promotion
            page_fromcart = cartSa.page_from

            checkout_from = cartSa.click_from
              ? `${cartSa.click_from}\`${cartSa.button_name}`
              : ''
            checkout_total = cartSa.checkout_total
          }
        }
        if(!!cartPoints?.checkout_from) checkout_from = cartPoints.checkout_from
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    let pageParam = {
      promotion: promotion_method,
      //取购物车的page_from
      page_fromcart,
      checkout_total,
      checkout_from,
      mall_shipping_method: mallShipParam?.mall_shipping_method || '',
      mall_origin_price: mallShipParam?.mall_origin_price || '',
      mall_is_fullshippingactivity:
        mallShipParam?.mall_is_fullshippingactivity || '',
      mall_step_fee: mallShipParam?.mall_step_fee || '',
      mall_charging_type: mallShipParam?.mall_charging_type || '',
      mall_num_price: mallShipParam?.mall_num_price || ''
    }

    Object.assign(SaPageInfo.page_param, pageParam)

    window.SaPageInfo = SaPageInfo
    appEventCenter?.$emit('pageOnload')
    window.onbeforeunload = function() {
      sa('send', 'pageLeave', { end_time: new Date().getTime() })
    }
  }

  const handleShippingAnalysisParam = (shippingInfoAvailable = {}, totalPriceAmount = 0) => {
    const { transport_type, id, next_shipping_price, charging_type, shipping_detail_obj, max_shipping_price, originPrice, is_free_shipping, diff } = shippingInfoAvailable
    const analysisParam = {}
    const amount = totalPriceAmount
  
    //免邮运费
    if (originPrice && originPrice.amount) {
      analysisParam.origin_price = originPrice && originPrice.amount
      analysisParam.is_fullshippingactivity = is_free_shipping == 1 ? 1 : 0
    }
  
    //阶梯运费
    if (shipping_detail_obj) {
      let nowPrice = 0
      for (let i in shipping_detail_obj) {
        let info = shipping_detail_obj[i]
        if (+amount > +info.num && info.num_price) {
          nowPrice = info.num_price.amount
        }
      }
      analysisParam.step_fee = max_shipping_price.amount
      analysisParam.charging_type = charging_type
      analysisParam.num_price = nowPrice
      analysisParam.is_fullshippingactivity = diff <= 0 && !next_shipping_price ? 1 : 0
    }
    analysisParam.shipping_method = transport_type || id || ''
  
    return analysisParam
  }

  const handleMallShippingAnalysisParam = ({ cartsInfo, mallShippingInfo }) => {
    let mallShippingAnalysisParam = {
      platform: null, // 平台级免邮
    }
    for(let key in mallShippingInfo){
      let amount = 0
      let shipping_free_type = 1
      if(key == 'platform') {
        amount = cartsInfo.totalPrice?.amount
        shipping_free_type = 0
      } else {
        amount = cartsInfo.good_by_mall.find(item => item.mall_code == key)?.total_price?.amount
        shipping_free_type = 3
      }
      if(!mallShippingInfo[key].isFirstFreeShipping && mallShippingInfo[key].shippingInfoAvailable) {
        mallShippingAnalysisParam[key] = handleShippingAnalysisParam(mallShippingInfo[key].shippingInfoAvailable, amount)
        mallShippingAnalysisParam[key].shipping_free_type = shipping_free_type
      } else {
        mallShippingAnalysisParam[key] = null
      }
    }
  
    return mallShippingAnalysisParam
  }

  const handleMallShippingAnalysis = (mallShippingAnalysis = {}) => {
    let result = {}
    for(let type in mallShippingAnalysis){
      if(type != 'platform') {
        for(let key in mallShippingAnalysis[type]){
          if(key != 'shipping_free_type'){
            if(!result[`mall_${key}`]){
              result[`mall_${key}`] = []
            }
            result[`mall_${key}`].push(`{mall:${type},${key}:${mallShippingAnalysis[type][key]}}`)
          }
        }
      }
    }
    Object.keys(result).forEach(key => {
      result[key] = `{${result[key].join('')}}`
    })
    return result
  }


  return {
    reportPvAnalysis
  }
}
