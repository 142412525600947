<template>
  <div :class="prefixCls('header-wrapper')">
    <!-- header头部字体颜色受接口控制，内部不要进行覆盖 -->
    <STitleNav
      :class="prefixCls('hw__nav')"
      :style="style"
    >
      <!-- 标题前置内容 -->
      <template slot="preblock">
        <STitleNavItem
          icon-class="iconfont-s icons-nav_back"
          @click.native="handleClickGoBack"
        />
      </template>

      <!-- 标题正文 -->
      <template slot="default">
        <Title />
      </template>

      <!-- 标题后置内容 -->
      <template slot="endblock">
        <div
          :class="prefixCls('hw__order-robot')"
          @click="HandleGoRobot"
        >
          <Icon
            name="sui_icon_nav_support_24px"
            size="24px"
          />
        </div>
      </template>
    </STitleNav>

    <ClientOnly>
      <!-- 挽留弹窗 -->
      <RetainDialog
        ref="retainDialogRef"
      />
    </ClientOnly>
  </div>
</template>
<script>
export default {
  name: 'CheckoutHeader'
}
</script>
<script setup>
import { ref, defineAsyncComponent, computed } from 'vue'
import ClientOnly from 'vue-client-only'

import {
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useRouter } from '@/public/src/pages/checkout_new/hooks/useRouter.js'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import {
  useMapState,
  useMapGetters,
} from '@/public/src/pages/checkout_new/hooks/store'
import Title from './components/Title.vue'

const RetainDialog = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-retain-dialog" */ 'public/src/pages/checkout_new/pages/retain_dialog/Index.vue')
)

const router = useRouter()

// --------- useMap_**** --------
const { cartsInfo, shoppingBagsState } = useMapState([
  'cartsInfo',
  'shoppingBagsState',
])
const { headerInfo } = useMapGetters(['headerInfo'])

// --------- state ---------
const retainDialogRef = ref(null)

// --------- computed ---------
const style = computed(() => {
  const src =  headerInfo.value?.bgImage?.src || ''
  return {
    ['--backgroundImage']:src? `url('${src}')` : '',
    ['--fontColor']: headerInfo.value?.fontColor || '',
  }
})
// ---------- method ----------
// go back
const handleClickGoBack = () => {
  if (retainDialogRef.value) {
    retainDialogRef.value.show()
    return
  }

  daEventCenter.triggerNotice({
    id: 'click_close:simple',
    data: {
      is_shop: shoppingBagsState.value.isShopType
    }
  })

  if (router) {
    history.back()
  } else {
    location.href = gbCommonInfo.langPath + '/cart'
  }
}

// 打开机器人
const HandleGoRobot = async () => {
  daEventCenter.triggerNotice({
    id: 'click_support:simple',
  })

  await setRobotStoreDataToGBCommon()
  await jumpToRobot()
}

// 页面跳转
const jumpToRobot = async () => {
  const { jumpToRobot } = await import('@customerService/botLogic')
  jumpToRobot('checkoutPage', data => {
    if (router) {
      router.push(data)
    } else {
      location.href = data
    }
  })
}

// 设定 robotStoreData
const setRobotStoreDataToGBCommon = async () => {
  const storeList = cartsInfo?.value?.good_by_mall
    ?.map(item => item.storeList)
    ?.flat()
  if (storeList && storeList.length > 0) {
    let orderLogicIndex = await import('public/src/pages/common/orderLogic')
    let orderLogic = orderLogicIndex.default
    orderLogic.setToRobotPage({
      mall_list: storeList,
      pageName: 'checkoutPage'
    })
  }
}
</script>

<style lang="less">
@import './variables.less';

.@{prefixCls}header-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
  width: 10rem;
  margin: 0 auto;
  transition: top 0.3s ease-out, transform 0.3s ease-out;

  .@{prefixCls}hw {
    &__nav {
      background-image: var(--backgroundImage, @sui_color_white);
      background-size: 100% auto;
      background-repeat: no-repeat;

      color: var(--fontColor, @sui_color_gray_dark1);

      // 左侧的back箭头
      a {
        text-decoration: none;
        color: var(--fontColor, @sui_color_gray_dark1);
      }

      .@{prefixCls}hw__order-robot {
        position: relative;
        > i {
          .font-dpr(48px);
        }
      }
    }
  }
}
</style>
