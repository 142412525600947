<template>
  <ul
    :class="prefixCls('ship-wrap--vertical')"
  >
    <ShippingListItem
      v-for="(item, index) in filterShippingList"
      :key="index"
      :item="item"
      :index="index"
      :isFold="!isFullShow"
      :mallCode="mallCode"
      :class="[{'mshe-ship-disabled': !item.is_available}, {'cls_last-item': (index + 1) === shippingMethods.length}]"
    />
    <div
      v-if="!isFullShow"
      v-expose="{
        id: 'expose_shipping_list:simple',
        data: saViewMoreObj
      }"
      v-tap="{
        id: 'click_shipping_list:simple',
        data: saViewMoreObj
      }"
      :class="prefixCls('ship-wrap--vertical__btn-more')"
      @click="clickMore"
    >
      {{ language.SHEIN_KEY_PWA_21044 }}
      <Icon
        name="sui_icon_more_down_12px_2"
      />
    </div>
  </ul>
</template>
<script setup name="ShippingMethodList">
import { computed, reactive } from 'vue'
import { prefixCls } from './../utils/index'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

import ShippingListItem from '@/public/src/pages/checkout_new/pages/shipping_method/shipping_method_list/shipping_method_item/index.vue'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  shippingMethods: {
    type: Array,
    default: () => []
  },
  foldPosition: {
    type: Number,
    default: Number.MAX_VALUE
  }
})

const {
  language,
} = useMapState([
  'language',
])

const { fullShippingMethodsSelectedInfo } = useMapGetters(['fullShippingMethodsSelectedInfo'])

const model = reactive({
  isFold: true,
})

const currMallSelectTransportType = computed(() => fullShippingMethodsSelectedInfo.value[props.mallCode])

const isFullShow = computed(() => {
  if(props.foldPosition >= props.shippingMethods.length - 1) {
    return true
  }

  if(!currMallSelectTransportType.value) {
    return true
  }

  let selectIdx = currMallSelectTransportType.value.index

  if(selectIdx === -1) {
    selectIdx = props.foldPosition
  }

  if(selectIdx > props.foldPosition) {
    return true
  }

  return !model.isFold
})

const saViewMoreObj = computed(() => ({
  is_default: '-',
  shipping_method: 'view_more',
  shipping_time: '-',
  shipping_fee: '-',
  mall_code: props.mallCode,
  shipping_time_desc: '-',
  scenes: '-',
  is_folded: 0,
  sequence: 2,
  style: 'list',
  default_type: '-'
}))

const clickMore = () => {
  model.isFold = false

  daEventCenter.triggerNotice({
    id: 'click_all_shippingmethod:simple',
  })
}

const filterShippingList = computed(() => {
  return !isFullShow.value ? props.shippingMethods.slice(0, +props.foldPosition) : props.shippingMethods
})
</script>

<style lang="less">
@import "../variables";

.@{prefixCls}ship-wrap--vertical {
  padding-bottom: 20/75rem;
  .padding-l(24/75rem);
  .padding-r(24/75rem);

  .cls_last-item {
    padding-bottom: 0;
  }

  &__btn-more {
    text-align: center;
    color: @sui_color_gray_dark3;
    padding: 20/75rem 0;
    padding-bottom: 0;
  }
}
</style>
