<template>
  <div :class="prefixCls('flex-nav')">
    <div :class="prefixCls('fn-main')">
      <img
        :src="iconStar"
        :class="prefixCls('fn-main_star')"
      />
      <div
        :class="prefixCls('fn-main_total')"
        v-html="pointsTotal"
      ></div>
      <br />
      <Icon
        v-if="!!pointsDialogText"
        v-tap="{
          id: 'click_how_to_use_points:simple'
        }"
        name="sui_icon_doubt_16px_2"
        size="16px"
        color="#959595"
        @click="handleShowTips"
      />
      <slot name="mainRest"></slot>
    </div>
    <div :class="prefixCls('fn-icon')">
      <slot name="icon"></slot>
    </div>
    <ClientOnly>
      <!-- 积分文案弹窗-->
      <s-dialog
        v-if="isShowPointsTxtDialog"
        :type="'long'"
        :visible.sync="isShowPointsTxtDialog"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body
      >
        <div v-html="pointsDialogText"></div>
        <template slot="footer">
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click="handleCloseTips"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script setup name="HasPoints">
import { computed, ref } from 'vue'
import ClientOnly from 'vue-client-only'
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'

import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/reward_points/utils'

const isShowPointsTxtDialog = ref(false)
// -------- useMap_**** --------

// -------- useMapState --------
const { language, checkout } = useMapState(['language', 'checkout'])

// ---------- computed ----------
const iconStar = computed(() => {
  const { regular_reward_point_info = {} } =
    checkout.value?.reward_point_info ?? {}
  return regular_reward_point_info?.point_icon
})
//

const pointsTotal = computed(() => {
  const { regular_reward_point_info = {} } =
    checkout.value?.reward_point_info ?? {}

  return template(
    `<span style="color: #fa6338">${regular_reward_point_info?.point}</span>`,
    regular_reward_point_info?.point_tip
  )
})

const pointsDialogText = computed(() => {
  const { reward_point_info = {} } = checkout.value ?? {}

  const tip1 =
    reward_point_info?.other_reward_point_info?.point_question_tips || ''
  const tip2 =
    reward_point_info?.other_reward_point_info?.point_question_tips2 || ''

  return tip2 ? `${tip1} <br />  ${tip2}` : tip1
})

// ----------- method -----------
const handleShowTips = () => {
  isShowPointsTxtDialog.value = true
}
const handleCloseTips = () => {
  isShowPointsTxtDialog.value = false
}
</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}flex-nav {
  display: flex;
  position: relative;
  align-items: center;
  .@{prefixCls}fn {
    &-main {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 14px;
      font-weight: 700;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.6;
      flex: 1;
      flex-wrap: wrap;

      span {
        margin-right: 4/37.5rem;
      }

      &_star {
        margin-right: 4/37.5rem;
        height: 20/37.5rem;
      }

      &_total {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        margin-right: 4/37.5rem;
        max-width: 7.8rem;
        line-height: 1.6;
      }
    }
  }
}
</style>
