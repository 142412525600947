<template>
  <div :class="prefixCls('header-title')">
    <!-- 手动触发滚动标题，方案show、show1 -->
    <OperationScrollUp
      v-if="isOperationScrollUpShow"
      :isShowTwoRowsTitle="isShowTwoRowsTitle"
    >
      <DefaultComponent v-if="isShowTwoRowsTitle" />
    </OperationScrollUp>

    <!-- 自动循环滚动标题，方案show2 -->
    <AutoScrollUp
      v-else-if="isAutoScrollUpShow"
    >
      <DefaultComponent />
    </AutoScrollUp>

    <DefaultComponent v-else />
  </div>
</template>
<script setup name="HeaderTitle">
import { defineComponent, h, computed } from 'vue'

import AutoScrollUp from './Auto.vue' // 滚动
import OperationScrollUp from './Operation.vue' // 手动触发

import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'


// 使用 defineComponent 定义内部组件作为默认title
const DefaultComponent = defineComponent({
  setup() {
    // 内部组件逻辑
    return () =>
      h(
        'div',
        // 设置 class
        { class: prefixCls('ht__default-title') },
        // 设置显示的内容
        language.value.SHEIN_KEY_PWA_15245 // Order Confirmation
      )
  }
})

// -------- useMapState --------
const { language, abtInfo } = useMapState(['language', 'abtInfo'])

const CheckoutHeadlineShow = abtInfo?.value?.UserBehaviorTips?.param?.CheckoutHeadlineShow

// --------- computed ---------
const isShowTwoRowsTitle = computed(() => {
  return CheckoutHeadlineShow === 'Show1'
})

// 手动滚动
const isOperationScrollUpShow = computed(() => {
  return ['Show', 'Show1'].includes(CheckoutHeadlineShow)
})

// 自动滚动
const isAutoScrollUpShow = computed(() => {
  return ['Show2'].includes(CheckoutHeadlineShow)
})

</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}header-title {
  .@{prefixCls}ht {
    &__default-title {
      .font-dpr(32px);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      font-weight: 700;
      text-align: center;
    }
  }
}
</style>
