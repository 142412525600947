<template>
  <CommonDrawer
    :visible="visible"
    @onClose="updateDrawerVisible(false)"
  >
    <template #top>
      {{ language.SHEIN_KEY_PWA_16494 }}
    </template>

    <template #intro-section>
      <div
        class="wallet-input-content"
      >
        <p class="wallet-input__total">
          {{ language.SHEIN_KEY_PWA_14955 }}:
          {{ walletInfo?.price?.amountWithSymbol || 0 }}
        </p>
        <p class="wallet-input__tips">
          {{ language.SHEIN_KEY_PWA_16457 }}:
          <span class="header-tips-price">
            {{ subtractPrice.amountWithSymbol || 0 }}
          </span>
          <Icon
            v-if="showWalletIcon"
            name="sui_icon_doubt_16px_2"
            @click.stop="handleFreezeTipsClick"
          />
        </p>
      </div>
    </template>

    <template #input-section>
      <span
        v-if="orderCurrency"
        class="mshe-input-symbol"
        tabindex="0"
      >
        {{ orderCurrency.symbol_left + orderCurrency.symbol_right }}
      </span>

      <input
        v-model="model.input"
        type="text"
        class="mshe-input mshe-input-wallet"
        :disabled="model.isApplied || model.loading"
        :placeholder="placeholderTextInit"
        @blur="inputBlur"
        @input="setMaxInput"
      />
      <span
        v-if="!model.isApplied && !model.loading"
        v-enterkey
        class="mshe-input-all"
        tabindex="0"
        role="button"
        @click="handleIconClick"
      >
        <Icon
          v-if="showClearIcon"
          name="sui_icon_close_34px"
          size="14px"
          color="#ccc"
        />
        <span v-else>{{ language.SHEIN_KEY_PWA_15623 }}</span>
      </span>
    </template>

    <template #footer-region>
      <s-button
        width="100%"
        :disabled="subtractPrice.amount <= 0 || !model.input || +model.input<=0 || model.loading"
        :type="['primary', 'H80PX']"
        @click="handleWrapSubmit"
      >
        <template v-if="model.isApplied">
          {{ (language.SHEIN_KEY_PWA_15222 || '').toLocaleUpperCase() }}
        </template>
        <template v-else>
          {{ (language.SHEIN_KEY_PWA_15104 || '').toLocaleUpperCase() }}
        </template>
      </s-button>
    </template>
  </CommonDrawer>
</template>

<script setup>
import { computed, watch } from 'vue'
import CommonDrawer from './CommonDrawer.vue'
import {
  useMapState,
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'
import { useVirtualAssetsDrawer } from '@/public/src/pages/checkout_new/pages/virtual_assets/hooks/commonDrawer'
import { Icon } from '@shein-aidc/icon-vue2'
import { Button as SButton } from '@shein/sui-mobile'

const emits = defineEmits(['onSubmit'])

const {
  visible,
  model,
  placeholderTextInit,
  showClearIcon,
  inputBlur,
  handleIconClick,
  setMaxInput,
  onSubmit,
  updateDrawerVisible
} = useVirtualAssetsDrawer({ type: VirtualAssetsTypes.WALLET, emits })

const { wallet_balance: wallet, orderCurrency, nowCurrency, language } = useMapState([
  'checkout.wallet_balance',
  'checkout.orderCurrency',
  'nowCurrency',
  'language'
])

const { updateVirtualAssetsPopupState } = useMapMutations([
  'updateVirtualAssetsPopupState'
])

const subtractPrice = computed(() => {
  return wallet.value?.subtractPrice || ''
})

const walletInfo = computed(() => {
  return wallet.value?.totalPrice?.find(item => item.currencyCode === nowCurrency.value) || {}
})

const showWalletIcon = computed(() => {
  return +wallet.value?.showQuestionPopup === 1
})

const handleFreezeTipsClick = () => {
  updateVirtualAssetsPopupState({
    // 商品不可用弹窗
    freezeLimitGoods: {
      visible: true,
      type: VirtualAssetsTypes.WALLET,
    }
  })
}

const handleWrapSubmit = () => {
  if(subtractPrice.value.amount <= 0) return false
  onSubmit()
}

watch(() => wallet.value.walletPrice.amount, (val) => {
  if(+val > 0) {
    model.input = val
    model.isApplied = true
  }
}, {
  immediate: true
})
</script>
