const XtraCouponType = {
  // 商品券
  Product: 'Product',
  // 运费券
  Shipping: 'Shipping',
}

const NewUserCouponType = {
  // 单次券
  Single: 'single',
  // 轮次券
  Round: 'round'
}

const couponConfig =  {
  [XtraCouponType.Product]: {
    // 券面额文字颜色
    '--discountColor': '#F82854',
    // 券门槛文字颜色
    '--ruleColor': '#F82854B3',
    // 渐变背景颜色 - 起点
    '--bgStartColor': '#FFEEF3',
    // 渐变背景颜色 - 终点
    '--bgEndColor': '#FDD2DB',
    // 边框颜色
    '--borderColor': '#FDD2DB',
    // 新人券主题颜色，包括文字颜色、icon颜色、标签背景颜色
    '--newUserThemeColor': '#ED1D49'
  },
  [XtraCouponType.Shipping]: {
    // 券面额文字颜色
    '--discountColor': '#169E00',
    // 券门槛文字颜色
    '--ruleColor': '#58BA4A',
    // 渐变背景颜色 - 起点
    '--bgStartColor': '#F3FAF6',
    // 渐变背景颜色 - 终点
    '--bgEndColor': '#D1FFE5',
    // 边框颜色
    '--borderColor': '#CBE2C7',
    // 新人券主题颜色，包括文字颜色、icon颜色、标签背景颜色
    '--newUserThemeColor': '#169E00'
  }
}

const saverDialogMap = {
  discountDetail: 'virtunalDiscountVisible', // 费用明细弹窗
  couponList: '', // 跳转到订单券列表
  textDialog: 'discountTipsVisible', // 纯文本弹窗 - 合规
}

export {
  XtraCouponType,
  NewUserCouponType,
  couponConfig,
  saverDialogMap
}
